import React from "react";
import PropTypes from "prop-types";

const Gitlab = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      d="M27.9561 17.6796L26.6117 13.5354L23.9514 5.31529C23.9208 5.22358 23.8622 5.14385 23.7839 5.08736C23.7057 5.03087 23.6117 5.00047 23.5153 5.00047C23.4188 5.00047 23.3248 5.03087 23.2466 5.08736C23.1683 5.14385 23.1097 5.22358 23.0791 5.31529L20.4188 13.5304H11.5797L8.91937 5.31529C8.88894 5.22349 8.83047 5.14364 8.75224 5.08705C8.67402 5.03046 8.58002 5 8.48357 5C8.38712 5 8.29312 5.03046 8.21489 5.08705C8.13667 5.14364 8.0782 5.22349 8.04776 5.31529L5.3917 13.5304L4.04732 17.6796C3.9876 17.8638 3.98748 18.0623 4.04698 18.2466C4.10648 18.4309 4.22256 18.5916 4.37862 18.7057L16.0003 27.1753L27.6198 18.7057C27.7766 18.5921 27.8936 18.4317 27.9539 18.2474C28.0143 18.0631 28.0151 17.8644 27.9561 17.6796Z"
      fill="#FC6D26"
    />
    <path
      d="M16.0001 27.1686L20.4192 13.5293H11.583L16.0001 27.1686Z"
      fill="#E24329"
    />
    <path
      d="M15.9998 27.1686L11.5792 13.5293H5.39404L15.9998 27.1686Z"
      fill="#FC6D26"
    />
    <path
      d="M5.38926 13.5342L4.04488 17.6784C3.98516 17.8627 3.98504 18.0611 4.04454 18.2454C4.10404 18.4297 4.22012 18.5904 4.37618 18.7045L16 27.1756L5.38926 13.5342Z"
      fill="#FCA326"
    />
    <path
      d="M5.39111 13.5331H11.5841L8.91877 5.31873C8.88815 5.22702 8.82956 5.14728 8.75129 5.09079C8.67303 5.0343 8.57904 5.00391 8.48261 5.00391C8.38619 5.00391 8.2922 5.0343 8.21393 5.09079C8.13567 5.14728 8.07708 5.22702 8.04646 5.31873L5.39111 13.5331Z"
      fill="#E24329"
    />
    <path
      d="M15.9995 27.1686L20.4187 13.5293H26.6138L15.9995 27.1686Z"
      fill="#FC6D26"
    />
    <path
      d="M26.6096 13.5342L27.9539 17.6784C28.014 17.8626 28.0143 18.0612 27.9548 18.2455C27.8952 18.4299 27.779 18.5906 27.6226 18.7045L15.9995 27.1692L26.6067 13.5342H26.6096Z"
      fill="#FCA326"
    />
    <path
      d="M26.6127 13.5326H20.4204L23.0807 5.31822C23.1112 5.22642 23.1696 5.14657 23.2479 5.08998C23.3261 5.03339 23.4201 5.00293 23.5165 5.00293C23.613 5.00293 23.707 5.03339 23.7852 5.08998C23.8634 5.14657 23.9219 5.22642 23.9523 5.31822L26.6127 13.5326Z"
      fill="#E24329"
    />
  </svg>
);

Gitlab.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Gitlab;
