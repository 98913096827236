import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const ShareIcon = ({ color = "#000", ...props }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-share"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`share-icon-${uid}`}
      {...props}
    >
      <title id={`share-icon-${uid}`}>Share icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5C17 4.44772 17.4477 4 18 4C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6C17.4477 6 17 5.55228 17 5ZM18 2C16.3431 2 15 3.34315 15 5C15 5.20611 15.0208 5.40737 15.0604 5.6018C15.0455 5.60908 15.0308 5.61675 15.0162 5.62483L7.84657 9.58848C7.83478 9.595 7.82317 9.60173 7.81174 9.60865C7.30835 9.22667 6.68065 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15C6.69282 15 7.33078 14.7652 7.83862 14.3707C7.84014 14.3716 7.84166 14.3724 7.84319 14.3733L15.0128 18.3733C15.0285 18.3821 15.0444 18.3904 15.0604 18.3982C15.0208 18.5926 15 18.7939 15 19C15 20.6569 16.3431 22 18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C17.2725 16 16.6055 16.2589 16.0861 16.6897C16.0548 16.6671 16.0218 16.646 15.9872 16.6267L8.92151 12.6847C8.97285 12.4648 9 12.2356 9 12C9 11.7531 8.97018 11.5132 8.91395 11.2837L15.9838 7.37516C16.0196 7.35536 16.0538 7.33369 16.0861 7.31033C16.6055 7.74106 17.2725 8 18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2ZM17 19C17 18.4477 17.4477 18 18 18C18.5523 18 19 18.4477 19 19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11Z"
        fill={color}
      />
    </svg>
  );
};

ShareIcon.propTypes = {
  color: PropTypes.string
};

export default ShareIcon;
