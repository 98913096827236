import React from "react";

import useUniqueId from "Libs/useUniqueId";

const Chevron = () => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-chevron"
      width="13px"
      height="8px"
      viewBox="0 0 13 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`chevron-icon-${uid}`}
    >
      <title id={`chevron-icon-${uid}`}>Chevron icon</title>
      <g
        className="chevron-wrapper"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          className="chevron"
          transform="translate(-978.000000, -308.000000)"
          fillRule="nonzero"
          fill="#5f5e70"
        >
          <g transform="translate(50.000000, 275.000000)">
            <g transform="translate(871.000000, 20.000000)">
              <g transform="translate(63.300000, 17.300000) rotate(90.000000) translate(-63.300000, -17.300000) translate(59.800000, 10.800000)">
                <polygon points="7 6.30000618 6.51934176 5.76588732 1.32010686 0 0 1.06823359 4.71856584 6.30000618 0 11.5317664 1.32010686 12.6 6.51934176 6.83411268 7 6.30000618" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Chevron;
