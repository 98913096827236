import styled, { css } from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
  text-decoration: none;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${props => (props.isOversized ? "#FB3728" : props.theme.links)};

  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 8px;
  ${({ isTransparent }) =>
    !isTransparent &&
    css`
      background: #f5f7fa;
    `};
  border-radius: 2px;

  .type-dark + span {
  }
`;

export const Name = styled.span`
  ${props =>
    props.isOversized &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1;
    `};
`;

export const RemoveAttachment = styled.button.attrs({ type: "button" })`
  margin-left: 12px;
  margin-top: 1px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background-color: transparent;

  svg {
    height: 10px;
    width: 10px;
  }

  g {
    fill: ${props => props.theme.subtitleText};
  }
`;

export const LeftIcon = styled.span`
  display: flex;
  margin-right: 8px;

  svg {
    width: 30px;
    fill: ${props => props.theme.links};
  }
`;

export const OversizedIcon = styled(LeftIcon)`
  transform: rotate(180deg);

  path {
    fill: ${props => props.theme.error};
  }
`;
