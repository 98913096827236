import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";

const SectionHeader = ({ showing, onTabChange, children }) => {
  const headerText = "activity";

  return (
    <Header>
      <Heading id="project-activity-heading">
        <FormattedMessage id={headerText} />
      </Heading>
      <Tablist role="tablist" aria-label="Change activity view">
        <Tab
          role="tab"
          active={showing === "recent"}
          onClick={() => onTabChange("recent")}
          onKeyDown={e => {
            e.keyCode === 13 && onTabChange("recent");
          }}
        >
          <FormattedMessage id="activities.tabs.recent" />
        </Tab>
        <Tab
          role="tab"
          active={showing === "all"}
          onClick={() => onTabChange("all")}
          onKeyDown={e => {
            e.keyCode === 13 && onTabChange("all");
          }}
        >
          <FormattedMessage id="activities.tabs.all" />
        </Tab>
      </Tablist>
      {children}
    </Header>
  );
};

SectionHeader.propTypes = {
  children: PropTypes.node,
  showing: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired
};

const Header = styled.header`
  display: flex;
`;

const Heading = styled(Heading2)`
  margin-bottom: 24px;
`;

const Tablist = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 1.8;
  flex-grow: 1;
`;

const Tab = styled.a`
  display: inline-block;
  margin-left: 32px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.6px;
  cursor: pointer;
  color: ${props =>
    props.active ? props.theme.subNavActive : props.theme.descriptionText};

  ${props =>
    props.active &&
    `
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 2px;
      background: ${props.theme.subNavActive};
    }
  `};

  &:hover,
  &:focus {
    position: relative;
    outline: none;
    color: ${props => props.theme.subNavActive};

    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 2px;
      background: ${props => props.theme.subNavActive};
    }
  }

  &:focus-visible {
    outline: 1px solid blue;
  }
`;

export default SectionHeader;
