import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const Tooltip = ({ metadata = {}, transform }) => {
  return (
    <S.Wrapper transform={transform} role="tooltip">
      {metadata.appName || "router"}
    </S.Wrapper>
  );
};

Tooltip.propTypes = {
  metadata: PropTypes.object,
  transform: PropTypes.string
};

export default Tooltip;
