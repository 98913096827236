import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import * as S from "./ActivityTitle.styles";

const ActivityTitle = ({
  className = "",
  description,
  link = false,
  name,
  primaryText,
  primaryTitle,
  primaryTitleCustom,
  secondaryText,
  secondaryTitle
}) => {
  const intl = useIntl();

  return (
    <S.Wrapper className={className}>
      {description ? (
        <S.Text
          className="custom-activity-message"
          dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line
        />
      ) : (
        <>
          <strong>{name}</strong>
          &nbsp;
          {intl.formatMessage({
            id: primaryText ? primaryText : "activity.no_description"
          })}
          &nbsp;
          {(primaryTitle || primaryTitleCustom) &&
            (primaryTitle ? (
              <strong title={primaryTitle.length > 20 ? primaryTitle : ""}>
                {primaryTitle}
              </strong>
            ) : (
              primaryTitleCustom
            ))}
          {secondaryText && (
            <>
              &nbsp;
              {intl.formatMessage({ id: secondaryText })}&nbsp;
              <strong>{secondaryTitle}</strong>
            </>
          )}
          {link}
        </>
      )}
    </S.Wrapper>
  );
};

ActivityTitle.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.node,
  primaryText: PropTypes.string,
  name: PropTypes.string,
  primaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryTitleCustom: PropTypes.node,
  secondaryText: PropTypes.string,
  secondaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ActivityTitle;
