import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ReactTooltip from "react-tooltip";

import InfoIcon from "Icons/InfoIcon";
import CloseIcon from "Icons/CloseIcon";
import PaperclipIcon from "Icons/PaperclipIcon";

import {
  RemoveAttachment,
  LeftIcon,
  OversizedIcon,
  Layout,
  Name
} from "./styles";

const Attachment = ({
  name,
  isOversized,
  onRemove,
  isBundleOversized,
  hasPaperclip,
  isTransparent,
  ...props
}) => (
  <Layout
    data-tip
    isOversized={isOversized}
    data-disabled={!isOversized}
    data-for={`oversized-attachment-${name}`}
    isTransparent={isTransparent}
    {...props}
  >
    {hasPaperclip && (
      <LeftIcon>
        <PaperclipIcon />
      </LeftIcon>
    )}

    {isOversized && (
      <>
        <OversizedIcon>
          <InfoIcon />
        </OversizedIcon>

        <ReactTooltip
          id={`oversized-attachment-${name}`}
          type="dark"
          place="bottom"
          effect="solid"
          wrapper="span"
        >
          {isBundleOversized ? (
            <FormattedMessage id="tickets.open.attach.fieldLimit" />
          ) : (
            <FormattedMessage id="tickets.open.attach.fileLimit" />
          )}
        </ReactTooltip>
      </>
    )}
    <Name isOversized={isOversized}>{name}</Name>
    {onRemove && (
      <RemoveAttachment onClick={() => onRemove(name)}>
        <CloseIcon />
      </RemoveAttachment>
    )}
  </Layout>
);

Attachment.propTypes = {
  name: PropTypes.string,
  isOversized: PropTypes.bool,
  isBundleOversized: PropTypes.bool,
  hasPaperclip: PropTypes.bool,
  onRemove: PropTypes.func,
  isTransparent: PropTypes.bool
};

export default Attachment;
