import React from "react";
import PropTypes from "prop-types";

import Pane from "../Pane";
import Header from "../Header";
import Paragraph from "../Paragraph";

export const TicketPane = ({ header, paragraph, children, ...props }) => (
  <Pane {...props}>
    <Header>{header}</Header>
    <Paragraph>{paragraph}</Paragraph>
    {children}
  </Pane>
);

TicketPane.propTypes = {
  header: PropTypes.node,
  paragraph: PropTypes.node,
  children: PropTypes.node
};

export default TicketPane;
