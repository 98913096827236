import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const ExpandIcon = ({ color = "#5F5E70" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`expand-icon-${uid}`}
    >
      <title id={`expand-icon-${uid}`}>Expand icon</title>
      <path
        d="M10.3002 14.3C9.90024 13.9 9.30024 13.9 8.90024 14.3L6.50024 16.7L4.90024 15.1C4.60024 14.8 4.00024 15 4.00024 15.5L4.00024 20.2C4.00024 20.5 4.20024 20.7 4.50024 20.7H9.20024C9.70024 20.7 9.90024 20.2 9.60024 19.8L8.00024 18.2L10.4002 15.8C10.8002 15.4 10.8002 14.8 10.4002 14.4L10.3002 14.3Z"
        fill={color}
      />
      <path
        d="M10.3997 10.3004C10.7997 9.90043 10.7997 9.30043 10.3997 8.90043L7.99971 6.50043L9.59971 4.90043C9.89971 4.60043 9.69971 4.00043 9.19971 4.00043L4.49971 4.00043C4.19971 4.00043 3.99971 4.20043 3.99971 4.50043L3.99971 9.20043C3.99971 9.70043 4.49971 9.90043 4.89971 9.60043L6.49971 8.00043L8.89971 10.4004C9.29971 10.8004 9.89971 10.8004 10.2997 10.4004L10.3997 10.3004Z"
        fill={color}
      />
      <path
        d="M14.4 10.3998C14.8 10.7998 15.4 10.7998 15.8 10.3998L18.2 7.99983L19.8 9.59983C20.1 9.89983 20.7 9.69983 20.7 9.19983L20.7 4.49983C20.7 4.19983 20.5 3.99983 20.2 3.99983L15.5 3.99983C15 3.99983 14.8 4.49983 15.1 4.89983L16.7 6.49983L14.3 8.89983C13.9 9.29983 13.9 9.89983 14.3 10.2998L14.4 10.3998Z"
        fill={color}
      />
      <path
        d="M14.3 14.3998C13.9 14.7998 13.9 15.3998 14.3 15.7998L16.7 18.1998L15.1 19.7998C14.8 20.0998 15 20.6998 15.5 20.6998L20.2 20.6998C20.5 20.6998 20.7 20.4998 20.7 20.1998L20.7 15.4998C20.7 14.9998 20.2 14.7998 19.8 15.0998L18.2 16.6998L15.8 14.2998C15.4 13.8998 14.8 13.8998 14.4 14.2998L14.3 14.3998Z"
        fill={color}
      />
    </svg>
  );
};

ExpandIcon.propTypes = {
  color: PropTypes.string
};

export default ExpandIcon;
