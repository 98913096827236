import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import Loading from "Components/Loading";
import { DetailDialog, Tooltip } from "./";

import graphs from "Libs/servicegraph";

import * as S from "./styles";

const d3 = require("d3");

const MIN_NODE_WIDTH = 24;
const MAX_NODE_WIDTH = 57;

let scale, transform, graphHeight, graphWidth;

const TreeService = ({
  currentDeployment,
  goToService,
  hasInfoTooltip,
  height,
  id = "service-tree",
  leftOffset = 0,
  maxHeight,
  minHeight,
  onClick,
  onNeedScroll,
  strokeColor,
  theme,
  treePositionY,
  width,
  onLoadEnd
}) => {
  const innerRef = useRef(null);

  const [isLoading, setLoading] = useState(true);

  const [lastNode, setLastNode] = useState();
  const [metadata, setMetadata] = useState({});

  const [isDetailDialogOpen, setDetailDialogOpen] = useState(false);
  const [detailDialogPosition, setDetailDialogPosition] = useState();

  const [tooltipMetadata, setTooltipMetadata] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState();
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => updateServices(currentDeployment));
  }, []);

  useEffect(
    () => {
      updateServices(currentDeployment);
    },
    [currentDeployment]
  );

  useEffect(
    () => {
      const svg = innerRef.current;
      // subscribe event
      svg.addEventListener("treeSvgClick", onNodeClick, true);
      svg.addEventListener("keydown", onKeydown, false);
      svg.addEventListener("treeSvgOver", onNodeOver, true);
      svg.addEventListener("treeSvgOut", onNodeOut, true);

      return () => {
        // unsubscribe event
        svg.removeEventListener("treeSvgClick", onNodeClick, true);
        svg.removeEventListener("keydown", onKeydown, false);
        svg.removeEventListener("treeSvgOver", onNodeOver, true);
        svg.removeEventListener("treeSvgOut", onNodeOut, true);
      };
    },
    [innerRef]
  );

  const onNodeClick = e => {
    if (hasInfoTooltip) {
      insertDetailDialog(e);
    }
    setTooltipVisible(false);
    onClick(e.detail);
  };

  const onKeydown = e => {
    onEsc(e);
    onTab(e);
  };

  const onNodeOver = e => {
    if (isDetailDialogOpen) {
      return false;
    }

    const leftGap = 16; // Space between the start of the div and the arrow
    const tX = e.detail.x * scale + transform.x - leftGap - leftOffset;
    const tY =
      -graphHeight +
      transform.y +
      e.detail.y * scale +
      (e.detail.size * scale) / 2;
    setTooltipMetadata(e.detail);
    setTooltipPosition(`translate(${tX}px, ${tY}px)`);
    setTooltipVisible(true);
  };

  const onNodeOut = e => {
    setMetadata(e.detail);
    setTooltipVisible(false);
  };

  const onEsc = e => {
    if (e.which !== 27) {
      return;
    }
    setTooltipVisible(false);
  };

  const onTab = e => {
    if (e.which !== 9) return;

    const isTab = e.which === 9 && !e.shiftKey;
    const isSTab = e.which === 9 && e.shiftKey;
    const isNode = document.activeElement.nodeName === "g";

    if (isDetailDialogOpen && isTab && isNode) {
      setLastNode(document.activeElement);
      e.preventDefault();
      document.querySelector("#service-tree+div .close").focus();
    }

    if (isDetailDialogOpen && !isNode) {
      const focusableNodes = document.querySelectorAll(
        "#service-tree+div [tabindex]"
      );

      if (
        (isSTab && document.activeElement.isSameNode(focusableNodes[0])) ||
        (isTab &&
          document.activeElement.isSameNode(
            focusableNodes[focusableNodes.length - 1]
          ))
      ) {
        e.preventDefault();
        closeDetailDialog();
      }
    }
  };

  const updateServices = currentDeploymentImmutable => {
    const currentDeployment = currentDeploymentImmutable.toJS();
    if (Object.keys(currentDeployment).length === 0) {
      return false;
    }

    let g = generateGraph(currentDeployment);

    let graph = d3.select(`#${id}`),
      svg = graph.select("svg"),
      inner = svg.select("g");

    graphs.render(inner, g, true);
    const node = inner.select(".node").node();

    // Center the graph
    let maxX = graph.node().offsetWidth,
      maxY = graph.node().offsetHeight;

    let padding = 5; // We need a minimum of padding to avoid clipping drop shadows.
    let nodeWidth = d3
      .select(".label-container")
      .node()
      .getBoundingClientRect().width;
    let initialScale = Math.min(
      (maxX - 2 * padding) / g.graph().width,
      (maxHeight - 2 * padding) / g.graph().height,
      MAX_NODE_WIDTH / nodeWidth
    );

    // Modify scale if needed to maintain minimum node width
    initialScale = Math.max(initialScale, MIN_NODE_WIDTH / nodeWidth);

    // Since SVG translation is calculated without taking into account node padding on
    // edges of tree, calculate and add to final translation for centering
    const nodePaddingX =
      (node?.getBBox()?.width - nodeWidth * initialScale) / 2 || 0;

    let transformD3 = d3.zoomIdentity
      .translate(
        (nodePaddingX + maxX - g.graph().width * initialScale) / 2,
        treePositionY !== undefined
          ? treePositionY
          : (maxY - g.graph().height * initialScale) / 2
      )
      .scale(initialScale);

    scale = transformD3.k;
    if (transformD3.x < 0) {
      transformD3.x = 0;
    }

    inner.style(
      "transform",
      `translate(${transformD3.x}px, ${transformD3.y}px) scale(${
        transformD3.k
      })`
    );

    graphWidth = g.graph().width * transformD3.k + transformD3.x + 10;
    graphHeight = g.graph().height * transformD3.k + transformD3.y + 14;

    onNeedScroll(graphWidth > maxX);

    svg
      .attr("width", graphWidth)
      .attr("height", graphHeight)
      .attr("xmlns", "http://www.w3.org/2000/svg");

    transform = transformD3;
    onLoadEnd && onLoadEnd();
    setLoading(false);
  };

  const isTweenService = service => {
    let [type] = service.type.split(":", 1);
    return type === "varnish";
  };

  const insertDetailDialog = e => {
    const leftGap = 15; // Space between the start of the div and the arrow 5% * ~277

    Array.from(e.target.parentElement.children).forEach(node =>
      node.setAttribute("aria-expanded", false)
    );
    e.target.setAttribute("aria-expanded", true);

    const tX = e.detail.x * scale + transform.x - leftGap - leftOffset;
    const tY =
      -graphHeight +
      transform.y +
      e.detail.y * scale +
      (e.detail.size * scale) / 2;
    setMetadata(e.detail);
    setDetailDialogPosition(`translate(${tX}px, ${tY}px)`);
    setDetailDialogOpen(true);

    // Check if we need to resize window to display correctly the dialog
    const rect = innerRef.current.getBoundingClientRect();
    if (rect.bottom + 150 > window.innerHeight) sendFooterEvent(150);
  };

  const closeDetailDialog = () => {
    sendFooterEvent(0);

    Array.from(document.querySelectorAll("svg .nodes .node")).forEach(node =>
      node.setAttribute("aria-expanded", false)
    );

    lastNode?.focus();
    setDetailDialogOpen(false);
  };

  const sendFooterEvent = marginTop => {
    const footerUpdate = new CustomEvent("footerUpdate", {
      detail: {
        marginTop
      }
    });
    document.dispatchEvent(footerUpdate);
  };

  const generateGraph = deployment => {
    const graphlib = require("graphlib");

    const g = new graphlib.Graph();
    g.setGraph({});
    g.setDefaultNodeLabel(() => ({}));
    g.setDefaultEdgeLabel(() => ({ connectedNodes: [] }));

    const opts = g.graph();
    opts.ranker = "none";

    let hasTweenService = false;

    const serviceKeys = Object.keys(deployment.services);
    Object.entries({ ...deployment.services, ...deployment.workers }).forEach(
      ([name, service]) => {
        let [type] = service.type.split(":", 1);

        let rank = 0;

        if (isTweenService(service)) {
          // Varnish is a special case of services that sit between
          // routes and the app.
          rank = -2;
          hasTweenService = true;
        }

        g.setNode(name, {
          icon: type,
          rank: rank,
          iconColor: theme.serviceTreeNodeIconColor,
          class: serviceKeys.includes(name) ? "service" : "worker",
          children: [],
          metadata: {
            name: type,
            appName: name,
            ...service
          }
        });

        Object.entries(service.relationships).forEach(([, relationship]) => {
          let [endpointName] = relationship.split(":", 1);
          g.setEdge(name, endpointName);
          g.node(name).children.push(endpointName);
        });
      }
    );

    g.setNode("router", {
      icon: "router",
      rank: hasTweenService ? -3 : -2,
      iconColor: theme.serviceTreeNodeIconColor,
      class: "router",
      metadata: deployment.routes,
      children: []
    });

    let appEndpoints = new Set();
    g.hasAppEdges = false;
    g.hasServiceOverlap = false;

    Object.entries(deployment.webapps).forEach(([name, app]) => {
      let [type] = app.type.split(":", 1);

      g.setNode(name, {
        icon: type,
        rank: -1,
        class: "app",
        iconColor: theme.serviceTreeNodeIconColor,
        children: [],
        metadata: { appName: name, ...app }
      });

      Object.entries({ ...app.relationships, ...app.mounts }).forEach(
        ([, endpoint]) => {
          let targetName;
          if (endpoint.source) {
            if (endpoint.source !== "service") {
              return false;
            }

            targetName = endpoint.service;
          } else {
            targetName = endpoint.split(":", 1)[0];
          }

          // Check if any services are shared between apps
          if (
            !g.hasServiceOverlap &&
            g.node(targetName) &&
            g.node(targetName).class &&
            g.node(targetName).class === "service" &&
            appEndpoints.has(targetName)
          ) {
            // true if target node exists and is a service we've seen
            g.hasServiceOverlap = true;
          }
          appEndpoints.add(targetName);

          const existingLabels = Object.keys(g._edgeLabels).map(item => {
            return item.replace(/\W/g, "");
          });
          const endpointRelationship = `${targetName}${name}`;

          // This prevents a label from being generated for dual relationships.
          // The causes undesired results otherwise.
          // See https://platformsh.atlassian.net/browse/PF-5573 for more info.
          // An example of this relationship would be: appOne->appTwo, appTwo->appOne.
          const relationshipHasLabel = existingLabels.indexOf(
            endpointRelationship
          );
          if (relationshipHasLabel > -1) {
            return;
          }
          g.setEdge(name, targetName);
          g.node(name).children.push(targetName);
        }
      );
    });

    Array.from(appEndpoints).some(function(name) {
      if (g.node(name).class === "app") {
        g.hasAppEdges = true;
        return true;
      }
      return false;
    });

    Object.entries(deployment.routes).forEach(([, route]) => {
      if (route.type != "upstream") {
        return;
      }

      let [targetName] = route.upstream.split(":", 1);
      g.setEdge("router", targetName);
      g.node("router").children.push(targetName);
    });

    g.nodes().forEach(n => {
      let node = g.node(n);
      node.width = 40;
      node.height = 40;
    });

    setHighlightPaths(g, "router", [], new Set([]));

    return g;
  };

  const setHighlightPaths = (g, v, edges, visited) => {
    let node = g.node(v);
    if (edges.length > 0) {
      edges.forEach(e => {
        e.connectedNodes.push(v);
      });
    }
    if (node.children.length > 0) {
      node.children.forEach(w => {
        if (!visited.has(w)) {
          let edge = g.edge({ v: v, w: w });
          // make recursive call with only current visited set
          let visitedCopy = new Set(visited);
          visitedCopy.add(v);
          setHighlightPaths(g, w, edges.concat(edge), visitedCopy);
        }
      });
    }
  };

  return (
    <S.TreeLayout
      id="treeSvg"
      minHeight={minHeight}
      height={height}
      width={width}
      strokeColor={strokeColor}
      ref={innerRef}
    >
      {isLoading && <Loading iconOnly={true} />}

      <div id={id}>
        <svg>
          <defs>
            <filter id="shadow" width="200%" height="200%" x="-50%" y="-50%">
              <feDropShadow
                dx="0"
                dy="0.5"
                stdDeviation="2"
                floodColor="rgba(152, 160, 171, 0.4)"
                floodOpacity="1"
              />
            </filter>
            <filter
              id="shadow-hover"
              width="200%"
              height="200%"
              x="-50%"
              y="-50%"
            >
              <feDropShadow
                dx="0"
                dy="3"
                stdDeviation="6"
                floodColor="rgba(75, 97, 128, 0.32)"
                floodOpacity="2"
              />
            </filter>
          </defs>
          <g id="svg-g" />
        </svg>
      </div>

      {isDetailDialogOpen && (
        <DetailDialog
          kind={metadata.class}
          metadata={metadata.metadata}
          workers={currentDeployment.get("workers")?.size}
          transform={detailDialogPosition}
          onClick={goToService}
          onClose={() => closeDetailDialog()}
        />
      )}
      {isTooltipVisible && (
        <Tooltip
          metadata={tooltipMetadata.metadata}
          transform={tooltipPosition}
        />
      )}
    </S.TreeLayout>
  );
};

TreeService.propTypes = {
  currentDeployment: PropTypes.object,
  goToService: PropTypes.func,
  hasInfoTooltip: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  leftOffset: PropTypes.number,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.string,
  onClick: PropTypes.func,
  onNeedScroll: PropTypes.func,
  strokeColor: PropTypes.string,
  theme: PropTypes.object,
  treePositionY: PropTypes.number,
  width: PropTypes.string,
  onLoadEnd: PropTypes.func
};

export default withTheme(TreeService);
