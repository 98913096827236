import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyActivitiesSvg = ({
  maincolor,
  bgcolor1,
  bgcolor2,
  uid,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="140"
      height="108"
      viewBox="0 0 140 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-activities-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-activities-illustration-${uid}`}>
        Empty Activities
      </title>
      <path
        d="M69.9863 69.04L99.9863 96.04L117.986 83.04L108.986 79.04L117.986 46.04L69.9863 69.04Z"
        fill={bgcolor1}
      />
      <path
        d="M79.9863 33.54C79.7078 40.7376 76.7264 47.5658 71.6369 52.6628C66.5473 57.7598 59.7235 60.751 52.5263 61.04C50.9296 61.0981 49.3308 61.0246 47.7463 60.82C46.1943 60.6106 44.615 60.8665 43.2087 61.5555C41.8023 62.2444 40.6321 63.3354 39.8463 64.69C38.5687 66.9386 36.7805 68.8555 34.6258 70.2859C32.4711 71.7163 30.0104 72.6201 27.4422 72.9245C24.8739 73.229 22.2701 72.9254 19.8408 72.0384C17.4114 71.1513 15.2246 69.7057 13.4568 67.818C11.6889 65.9303 10.3896 63.6535 9.66353 61.1713C8.93749 58.6891 8.80511 56.071 9.27707 53.5282C9.74903 50.9854 10.8121 48.5891 12.3805 46.5328C13.9489 44.4764 15.9788 42.8176 18.3063 41.69C19.7659 41.0011 20.9891 39.8957 21.8219 38.5131C22.6546 37.1305 23.0598 35.5324 22.9863 33.92C22.9863 33.78 22.9863 33.64 22.9863 33.5C22.9863 29.7573 23.7234 26.0513 25.1557 22.5935C26.588 19.1357 28.6873 15.9939 31.3337 13.3475C33.9802 10.701 37.122 8.60169 40.5798 7.16943C44.0376 5.73717 47.7436 5 51.4863 5C55.2289 5 58.935 5.73717 62.3927 7.16943C65.8505 8.60169 68.9923 10.701 71.6388 13.3475C74.2853 15.9939 76.3846 19.1357 77.8168 22.5935C79.2491 26.0513 79.9863 29.7573 79.9863 33.5V33.54Z"
        fill={bgcolor2}
      />
      <path
        d="M46.1563 19.7701C43.9663 12.7701 53.7363 9.34008 56.5963 16.0901C57.3563 17.8601 59.9363 16.3301 59.1863 14.5701C58.3906 12.4576 56.7884 10.7478 54.732 9.81666C52.6757 8.88554 50.3337 8.80943 48.2213 9.60508C46.1088 10.4007 44.399 12.003 43.4678 14.0593C42.5367 16.1156 42.4606 18.4576 43.2563 20.5701C43.8263 22.4101 46.7263 21.6301 46.1563 19.7801V19.7701Z"
        fill={maincolor}
      />
      <path
        d="M81.0363 82.77C81.4402 83.8909 81.4747 85.1117 81.1348 86.2536C80.7949 87.3955 80.0984 88.3987 79.1472 89.1162C78.1961 89.8338 77.0403 90.228 75.8489 90.2413C74.6575 90.2546 73.4932 89.8862 72.5263 89.19C70.9063 88.13 69.3963 90.73 71.0163 91.78C72.5017 92.762 74.2557 93.2581 76.0354 93.1997C77.8151 93.1412 79.5327 92.5311 80.9505 91.4537C82.3683 90.3764 83.4163 88.885 83.9494 87.186C84.4825 85.487 84.4744 83.6643 83.9263 81.97C83.3363 80.14 80.4463 80.97 81.0363 82.77Z"
        fill={maincolor}
      />
      <path
        d="M88.2262 50.5301C92.5741 59.3459 98.0464 67.561 104.506 74.9701L105.166 72.46L45.8163 94.22L47.7162 95.67C47.2637 80.1056 44.1976 64.7279 38.6462 50.18C33.2262 35.6901 41.7262 19.0401 58.0762 17.8101C62.0279 17.5205 65.9876 18.2505 69.5762 19.93C73.1762 21.62 75.3062 24.75 78.4862 26.82C83.1862 29.82 88.2262 24.5 93.4862 26.16C102.356 29.04 103.526 41.6501 95.2163 46.0401C85.8963 50.93 77.0662 41.2401 80.3162 32.1001C80.9663 30.2801 78.0662 29.49 77.4263 31.3C73.4263 42.68 84.7062 54.49 96.1763 48.89C106.676 43.7701 106.246 28.2501 95.4263 23.7401C92.5582 22.6128 89.4051 22.4306 86.4263 23.22C84.8662 23.5701 82.7663 25.13 81.3162 24.82C79.4463 24.42 77.8062 21.92 76.3762 20.75C72.702 17.7157 68.2661 15.7469 63.5509 15.0577C58.8358 14.3685 54.022 14.9854 49.633 16.8412C45.2439 18.697 41.4478 21.7207 38.6574 25.5835C35.867 29.4463 34.1891 34.0002 33.8062 38.75C33.0562 47.2001 37.4762 54.8601 39.7562 62.75C42.8299 73.4904 44.5104 84.5813 44.7562 95.75C44.7595 95.9802 44.815 96.2066 44.9186 96.4121C45.0221 96.6177 45.171 96.797 45.354 96.9367C45.5369 97.0763 45.7492 97.1726 45.9748 97.2182C46.2004 97.2638 46.4334 97.2576 46.6562 97.2001L105.986 75.3601C106.234 75.283 106.458 75.1442 106.638 74.9567C106.817 74.7692 106.946 74.539 107.012 74.288C107.078 74.037 107.079 73.7733 107.015 73.5217C106.951 73.2702 106.824 73.039 106.646 72.85C100.368 65.6267 95.0477 57.6241 90.8162 49.0401C89.9663 47.3101 87.3762 48.8301 88.2262 50.5601V50.5301Z"
        fill={maincolor}
      />
      <path
        d="M94.6062 36.4599C94.6062 40.3799 92.8762 42.2599 90.2362 42.2599C87.5962 42.2599 85.7762 40.3599 85.7762 36.4499C85.7762 32.5399 87.5862 30.6499 90.2162 30.6499C92.8462 30.6499 94.6062 32.5699 94.6062 36.4599ZM88.1662 36.4599C88.1677 36.918 88.1977 37.3756 88.2562 37.8299L91.6262 33.4099C91.4936 33.1569 91.2935 32.9455 91.0482 32.799C90.8029 32.6525 90.5219 32.5767 90.2362 32.5799C89.0962 32.5799 88.1662 33.6399 88.1662 36.4599ZM92.2362 36.4599C92.2381 35.992 92.2113 35.5245 92.1562 35.0599L88.7962 39.4799C88.9414 39.7333 89.1511 39.9436 89.4041 40.0895C89.6571 40.2353 89.9442 40.3113 90.2362 40.3099C91.4162 40.3299 92.2362 39.2599 92.2362 36.4799V36.4599Z"
        fill={maincolor}
      />
    </svg>
  );
};

EmptyActivitiesSvg.propTypes = {
  bgcolor1: PropTypes.string,
  bgcolor2: PropTypes.string,
  maincolor: PropTypes.string,
  uid: PropTypes.number,
  highlightClass: PropTypes.string
};

const EmptyActivities = props => (
  <Illustration {...props}>
    <EmptyActivitiesSvg />
  </Illustration>
);

export default EmptyActivities;
