import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  background-color: ${props => props.theme.dark};
  color: ${props => props.theme.serviceTreeNode};
  padding: 6px 16px 7px 16px;
  height: auto !important;
  width: auto !important;
  font-size: 13px;
  overflow: visible !important;
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? props.theme.menuDropShadow
      : "0px 6px 24px rgba(152, 160, 171, 0.4)"};
  z-index: 8;
  margin-top: 4px;
  border-radius: 2px;

  transform: ${props => props.transform};

  &:after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: ${props => props.theme.dark};
    top: -14px;
    left: 13px;
    margin-left: -5px;
    height: 0;
    width: 0;
  }
`;
