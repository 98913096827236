import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
  border-radius: 2px;
  padding: 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const TextContainer = styled.div`
  text-align: left;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #4a495e;
`;
