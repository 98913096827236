import moment from "moment";

export const STATUSES = {
  pending: {
    id: "pending",
    inDropdown: true,
    weight: 0
  },
  open: {
    id: "open",
    inDropdown: true,
    weight: 2
  },
  hold: {
    id: "hold",
    inDropdown: true,
    weight: 3
  },
  solved: {
    id: "solved",
    weight: 4
  },
  closed: {
    id: "closed",
    inDropdown: true,
    weight: 5
  },
  deleted: {
    id: "deleted",
    inDropdown: true,
    weight: 6
  },
  new: {
    id: "new",
    inDropdown: true,
    weight: 1
  }
};

export const PRIORITIES = {
  urgent: {
    id: "urgent",
    weight: 0
  },
  high: {
    id: "high",
    weight: 1
  },
  normal: {
    id: "normal",
    weight: 3
  },
  low: {
    id: "low",
    weight: 4
  }
};

export const compareString = (a = "", b = "") => a.localeCompare(b, "en");
export const compareDate = (a, b) => moment(a).diff(moment(b));
export const compareNumber = (a = 0, b = 0) => Number(a) - Number(b);

export const SORT_BY = {
  status: {
    id: "status",
    inDropdown: true,
    sort: (a, b) =>
      compareNumber(STATUSES[a.status].weight, STATUSES[b.status].weight)
  },
  updated: {
    id: "updated",
    inDropdown: true,
    sort: (a, b) => compareDate(a.updated, b.updated)
  },
  created: {
    id: "created",
    sort: (a, b) => compareDate(a.created, b.created)
  },
  subscription: {
    id: "project",
    sort: (a, b) => compareString(b.project, a.project)
  },
  priority: {
    id: "priority",
    inDropdown: true,
    sort: (a, b) =>
      compareNumber(
        PRIORITIES[a.priority].weight,
        PRIORITIES[b.priority].weight
      )
  },
  ticket_id: {
    id: "ticket_id",
    sort: (a, b) => compareNumber(b.ticket_id, a.ticket_id)
  },
  subject: {
    id: "subject",
    inDropdown: true,
    sort: (a, b) => compareString(b.subject, a.subject)
  }
};
