import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { EmptyActivities } from "Components/illustrations/";

import * as S from "./ActivityEmpty.styles";

const ActivityEmpty = ({ activityType }) => {
  const intl = useIntl();

  if (!activityType || activityType === "all") {
    return (
      <S.AllEmpty>
        <S.ImageWrapper>
          <EmptyActivities id="empty-activities" />
        </S.ImageWrapper>
        {intl.formatMessage({
          id: "activities.empty.all",
          defaultMessage: "You have no recent activity"
        })}
      </S.AllEmpty>
    );
  }

  return (
    <S.CategoryEmpty className="empty-text">
      {intl.formatMessage({
        id: `activities.empty.${activityType}`,
        defaultMessage: `You have no ${activityType} activity`
      })}
    </S.CategoryEmpty>
  );
};

ActivityEmpty.propTypes = {
  activityType: PropTypes.string
};

export default ActivityEmpty;
