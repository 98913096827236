import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AccessibleTooltip from "Components/AccessibleTooltip";
import SettingLine from "Components/SettingLine";
import UserIcon from "Components/icons/UserIcon";
import InvitationDetails from "../InvitationDetails";

import * as S from "./styles";

const InvitationLine = ({
  invitation,
  isEdited,
  onEdit,
  onResendInvitation,
  onRevoke
}) => {
  const intl = useIntl();

  return (
    <SettingLine
      icon={
        <UserIcon
          size={30}
          backgroundColor="black"
          color="white"
          padding={10}
        />
      }
      info={
        <S.InfoLayout>
          <span className="user">
            <AccessibleTooltip
              tooltipProps={{
                id: `invitation-${invitation.id}`,
                place: "right",
                children: intl.formatMessage({
                  id: "invitation.display_name_will_display"
                })
              }}
            >
              <S.DisplayName>-</S.DisplayName>
            </AccessibleTooltip>
            <span className="display-name" />
            <S.Email>{invitation.email}</S.Email>
          </span>
          <S.SettingLineLayout>
            <S.Role>
              {intl.formatMessage({ id: `invitation.role.${invitation.role}` })}
            </S.Role>
            <S.StateLabel>
              {intl.formatMessage({
                id: "invitation.pending"
              })}
            </S.StateLabel>
          </S.SettingLineLayout>
        </S.InfoLayout>
      }
      isOpen={isEdited}
      onClick={onEdit}
    >
      {isEdited && (
        <InvitationDetails
          invitation={invitation}
          onResendInvitation={onResendInvitation}
          onRevoke={onRevoke}
        />
      )}
    </SettingLine>
  );
};

InvitationLine.propTypes = {
  invitation: PropTypes.object.isRequired,
  isEdited: PropTypes.bool,
  onEdit: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onRevoke: PropTypes.func
};

export default InvitationLine;
