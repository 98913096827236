import styled from "styled-components";

const Paragraph = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${props => props.theme.sectionText};
  margin-bottom: 32px;
`;

export default Paragraph;
