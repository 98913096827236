import React from "react";
import PropTypes from "prop-types";

import WinkyLoader from "Images/winky_loader.gif";
import GenericLoader from "Images/loading.svg";

const Spinner = props => {
  let src = WinkyLoader;

  if (process.env.ENABLE_UNBRANDED_ILLUSTRATIONS) {
    src = GenericLoader;
  }

  return <img src={src} alt={props.alt || "Loading..."} {...props} />;
};

Spinner.propTypes = {
  alt: PropTypes.string
};

export default Spinner;
