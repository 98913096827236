import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const ArrowWrapper = styled.span`
  display: ${props => (props.inline ? "inline-block" : "flex")};
  .left {
    transform: rotate(180deg);
  }
  .up {
    transform: rotate(-90deg);
  }
  .down {
    transform: rotate(90deg);
  }
  ${props => props.inline && "vertical-align: middle;"};
`;

const ArrowIcon = ({
  color = "#4786ff",
  direction = "right",
  inline = false
}) => {
  const uid = useUniqueId();

  return (
    <ArrowWrapper inline={inline}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={direction}
        role="img"
        aria-labelledby={`arrow-icon-${uid}`}
      >
        <title id={`arrow-icon-${uid}`}>Arrow icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3004 12.6665L12.0129 16.954C11.6747 17.2923 11.6747 17.8408 12.0129 18.179C12.3512 18.5173 12.8997 18.5173 13.2379 18.179L18.9183 12.4987C19.3088 12.1081 19.3088 11.475 18.9183 11.0844L13.2379 5.40405C12.8997 5.06577 12.3512 5.06578 12.0129 5.40405C11.6747 5.74232 11.6747 6.29077 12.0129 6.62905L16.3004 10.9165L6.50043 10.9165C6.01718 10.9165 5.62543 11.3083 5.62543 11.7915C5.62543 12.2748 6.01718 12.6665 6.50043 12.6665L16.3004 12.6665Z"
          fill={color}
        />
      </svg>
    </ArrowWrapper>
  );
};

ArrowIcon.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.string,
  inline: PropTypes.bool
};

export default withTheme(ArrowIcon);
