import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const CheckboxInput = styled.input`
  height: 18px;
  width: 18px;
  cursor: pointer;
  /* checked mark aspect */
  &:checked:after {
    content: " ";
    background: ${props => props.theme.inputBorderHover};
    background-size: contain;
    height: 18px;
    width: 18px;
    border-radius: 1px;
  }

  /* disabled checkbox */
  &:disabled:not(:checked),
  &:disabled:checked {
    box-shadow: none;
    border-color: ${props => props.theme.outlinedBtnBorder};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* accessibility */
  &:checked:focus,
  &:not(:checked):focus {
    border-color: ${props => themeHelpers(props.theme, "primaryShade")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "primaryShade")};
  }
  &.changed:checked:focus &.changed:not(:checked):focus {
    box-shadow: none;
    border-color: ${props => props.theme.primary};
  }
`;

export const CheckedCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.theme.inputBorder};
  background: transparent;
  border-radius: 3px;
  margin: 3px 3px 3px 4px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.inputBorderHover};
  }
`;

export const CheckedCheckboxTick = styled.div`
  background: ${props => props.theme.inputBorderHover};
  background-size: contain;
  height: 10px;
  width: 10px;
  border-radius: 1px;
`;
