import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();

  const query = useMemo(
    () => {
      const query = {};

      const entries = new URLSearchParams(search).entries();

      for (const [key, value] of entries) {
        query[key] = value;
      }

      return query;
    },
    [search]
  );

  return query;
};

export default useQuery;
