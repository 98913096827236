import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ArrowIcon from "Icons/ArrowIcon";

import { Layout } from "./styles";

const Back = ({ to, ...props }) => (
  <Layout as={Link} to={to} {...props}>
    <ArrowIcon size="20" direction="left" />
    <FormattedMessage id="back" />
  </Layout>
);

Back.propTypes = {
  to: PropTypes.string.isRequired
};

export default Back;
