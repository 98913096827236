import React, { useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import PaperclipIcon from "Icons/PaperclipIcon";

import Label from "Components/fields/Label";

import Attachment from "../Attachment";

import {
  Icon,
  Layout,
  FileInput,
  CustomFileInput,
  AttachmentList,
  BundleSize,
  ErrorMessage
} from "./styles";

const BUNDLE_LIMIT = 50000000;
const FILE_LIMIT = 20000000;

const Attachments = ({
  bundleLimit = BUNDLE_LIMIT,
  fileLimit = FILE_LIMIT,
  onChange,
  value,
  append,
  error,
  ...props
}) => {
  const file = useRef(null);

  const bundleSize = value?.reduce((bundle, { size }) => bundle + size, 0);

  const removeFile = fileName =>
    onChange(value.filter(({ name }) => name !== fileName));

  const onFileChange = async event => {
    const attachments = Array.from(event.target.files);
    onChange(append ? [...(value || []), ...(attachments || [])] : attachments);
  };

  return (
    <Layout {...props}>
      <FileInput
        type="file"
        id="file-input"
        name="attachments"
        onChange={onFileChange}
        ref={file}
        multiple
      />

      <CustomFileInput hasError={!!error} onClick={() => file.current?.click()}>
        <Icon hasError={!!error}>
          <PaperclipIcon />
        </Icon>
        <FormattedMessage id="tickets.open.attach" />
      </CustomFileInput>

      {value?.length > 0 && (
        <Fragment>
          <Label htmlFor="file-input">
            <FormattedMessage id="tickets.open.attachments" />
          </Label>

          <AttachmentList>
            {value.map((attachment, index, attachments) => {
              const isBundleOversized =
                bundleSize >= bundleLimit && index === attachments.length - 1;
              const isOversized =
                attachment.size >= fileLimit || isBundleOversized;

              return (
                <Attachment
                  key={attachment.name}
                  name={attachment.name}
                  isOversized={isOversized}
                  isBundleOversized={isBundleOversized}
                  onRemove={removeFile}
                />
              );
            })}
          </AttachmentList>

          <BundleSize>
            {parseInt(bundleSize / 1000000)}MB/{bundleLimit / 1000000}MB total
          </BundleSize>
        </Fragment>
      )}
      {error?.title && (
        <ErrorMessage>
          {error.title} {error?.detail}
        </ErrorMessage>
      )}
    </Layout>
  );
};

Attachments.propTypes = {
  fileLimit: PropTypes.number,
  bundleLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  append: PropTypes.bool,
  error: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.number,
    detail: PropTypes.string
  })
};

export default Attachments;
