import styled from "styled-components";

import { breakpoints } from "Libs/theme";

const getWidth = props => {
  // If it is the first cell, we want to support the checkbox
  if (props.index === 0 && props.showCheckbox) {
    return `calc(${props.width} - 32px)`;
  }

  return props.width;
};

export const TableLayout = styled.div`
  overflow-x: auto;
  position: relative;
  padding-bottom: 16px;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    left: 0px;
    height: 1px;
    box-shadow: inset 0px -1px 0px ${props => props.theme.environmentTreeLine};
    z-index: 1;
  }

  @media (min-width: ${breakpoints[1]}) {
    &:after,
    &:before {
      right: -32px;
      left: -32px;
    }
  }

  &:after {
    top: 27px;
  }

  &:before {
    bottom: 16px;
  }

  @media screen and (min-width: ${breakpoints[1]}) {
    overflow-x: visible;
  }
`;

export const Table = styled.table`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  &,
  tr,
  tbody,
  thead {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    background-image: none;
  }
`;

export const TableHead = styled.thead`
  position: relative;
`;

export const TableBody = styled.tbody`
  flex: 1 1 auto;
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: 30vh;

  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
`;

export const Checkbox = styled.div`
  width: 20px;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 16px 0px 24px;

  color: ${props => props.theme.subNavTextRev};

  ${props =>
    props.multiple &&
    `& > div:first-child {
    padding-left: 30px;
    width: calc(${props.firstCellWidth} - 30px) !important;
  }`};
`;

export const CheckBoxWrapper = styled.div`
  position: absolute;
`;

export const BodyRow = styled.div`
  display: flex;
  background: ${props => props.theme.sectionBg};
  margin-bottom: 5px;
  height: 60px;
  align-items: center;
  border-radius: 4px;
  padding: 0px 16px 0px 24px;
  cursor: pointer;

  & > div:first-child {
    display: none;
  }

  & > div:nth-last-child(2) {
    width: calc(${props => props.lastCellWidth} - 32px);
  }

  ${props =>
    props.multiple
      ? `&.selected,
     &:hover {
    & > div:first-child,
    & > div:last-child {
      display: block;
    }

    & > div:nth-child(2) {
      width: calc(${props.firstCellWidth} - 17px);
      padding-left: 8px;
    }

    .text {
      display: none;
    }
  }`
      : ""};
`;

export const BodyCell = styled.div`
  width: ${getWidth};
`;

export const MenuList = styled.ul`
  &,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li:first-letter {
    text-transform: uppercase;
  }
`;

export const MenuItem = styled.span`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;

  color: ${props => props.theme.textLight};

  &:hover {
    color: rgb(71, 134, 255);
    cursor: pointer;
  }
`;

export const ActionDropdownPlaceholder = styled.div`
  width: 32px;
`;
