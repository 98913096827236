import React from "react";
import PropTypes from "prop-types";

const Pagerduty = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path d="M11.5178 21.5583H8V27.9543H11.5178V21.5583Z" fill="#06AC38" />
    <path
      d="M21.2487 5.17259C19.3604 4.16751 18.0508 4 14.9594 4H8V18.5584H11.5178H11.9746H14.9289C17.6853 18.5584 19.7411 18.3909 21.5533 17.1878C23.533 15.8782 24.5533 13.7005 24.5533 11.1878C24.5533 8.46193 23.2893 6.28426 21.2487 5.17259ZM15.736 15.5127H11.5178V7.12183L15.4924 7.09137C19.1168 7.04569 20.9289 8.32487 20.9289 11.2183C20.9289 14.3249 18.6904 15.5127 15.736 15.5127Z"
      fill="#06AC38"
    />
  </svg>
);

Pagerduty.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Pagerduty;
