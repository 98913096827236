import styled from "styled-components";

// Uses border-box as box-sizing for a component and its descendants
const saneBoxSizing = `
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
`;

export const CommitListLayout = styled.div`
  ${saneBoxSizing};
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  font-size: 14px;
`;

export const HideLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */

  /* Creates the top border */
  &::before {
    content: "";
    height: 1px;
    background: ${props => props.theme.background};
    position: absolute;
    bottom: 46px;
    left: 0;
    right: 0;
  }
`;

export const ToggleCommits = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.links};

  &:hover {
    text-decoration: underline;
  }
`;
