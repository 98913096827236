import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const DownloadIcon = ({ color = "#000", ...props }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-download"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby={`download-icon-${uid}`}
      {...props}
    >
      <title id={`download-icon-${uid}`}>Download icon</title>
      <g>
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8375 11.3875L11.125 13.675L11.125 3.875C11.125 3.39175 11.5168 3 12 3C12.4832 3 12.875 3.39175 12.875 3.875L12.875 13.675L15.1625 11.3875C15.5008 11.0492 16.0492 11.0492 16.3875 11.3875C16.7258 11.7258 16.7258 12.2742 16.3875 12.6125L12.7071 16.2929C12.3166 16.6834 11.6834 16.6834 11.2929 16.2929L7.6125 12.6125C7.27423 12.2742 7.27423 11.7258 7.6125 11.3875C7.95077 11.0492 8.49923 11.0492 8.8375 11.3875ZM4 13C3.44772 13 3 13.4477 3 14V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14V19H5V14C5 13.4477 4.55228 13 4 13Z"
        />
      </g>
    </svg>
  );
};

DownloadIcon.propTypes = {
  color: PropTypes.string
};

export default DownloadIcon;
