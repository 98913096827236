import React from "react";

import useUniqueId from "Libs/useUniqueId";

const Refresh = props => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`refresh-icon-${uid}`}
      {...props}
    >
      <title id={`refresh-icon-${uid}`}>Refresh icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.79382V6.00093C8.69 6.00093 6 8.69093 6 12.0009C6 12.7372 6.13285 13.4469 6.37917 14.099C6.54107 14.5276 6.47781 15.0231 6.15384 15.3471C5.68515 15.8158 4.89897 15.7282 4.63828 15.1188C4.22917 14.1624 4 13.1091 4 12.0009C4 7.58093 7.58 4.00093 12 4.00093V2.20803C12 1.76258 12.5386 1.5395 12.8536 1.85448L15.6464 4.64737C15.8417 4.84264 15.8417 5.15922 15.6464 5.35448L12.8536 8.14737C12.5386 8.46236 12 8.23927 12 7.79382ZM17.8411 8.65985C18.3124 8.18855 19.103 8.27665 19.3646 8.88969C19.7719 9.84428 20 10.8953 20 12.0009C20 16.4209 16.42 20.0009 12 20.0009V21.7938C12 22.2393 11.4614 22.4623 11.1464 22.1474L8.35355 19.3545C8.15829 19.1592 8.15829 18.8426 8.35355 18.6474L11.1464 15.8545C11.4614 15.5395 12 15.7626 12 16.208V18.0009C15.31 18.0009 18 15.3109 18 12.0009C18 11.2614 17.8606 10.5541 17.6132 9.89852C17.4525 9.4726 17.5192 8.98175 17.8411 8.65985Z"
      />
    </svg>
  );
};

export default Refresh;
