import { useSelector } from "react-redux";

const SUPPORT_ROLES = ["administrator", "sales team", "technical support"];

export const isSupport = roles =>
  !!roles?.some(role => SUPPORT_ROLES.includes(role));

const useUserIsSupport = id => {
  const user = useSelector(({ user }) => user.getIn(["data", id]));

  return isSupport(user?.roles);
};

export default useUserIsSupport;
