export default (technologyName, x, y, width, height, color) => {
  let getIcon;
  try {
    if (technologyName === "mysql") {
      technologyName = "mariadb";
    }
    getIcon = require(`./${technologyName}`);
  } catch (e) {
    getIcon = require(`./placeholder`);
  }
  return getIcon.default(x, y, width, height, color);
};
