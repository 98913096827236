import React from "react";
import PropTypes from "prop-types";

const Bitbucket = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      d="M4.77888 5.00006C4.66666 4.99864 4.55548 5.02144 4.45315 5.06686C4.35082 5.11228 4.25982 5.17923 4.18654 5.263C4.11326 5.34677 4.05948 5.44533 4.02897 5.55176C3.99845 5.6582 3.99195 5.76992 4.00992 5.8791L7.27417 25.4073C7.31484 25.6463 7.43966 25.8635 7.62669 26.0208C7.81373 26.1781 8.05102 26.2654 8.2969 26.2674H23.9569C24.1412 26.2697 24.3202 26.2067 24.4614 26.0899C24.6025 25.9731 24.6964 25.8102 24.7258 25.6308L27.9901 5.88289C28.008 5.77371 28.0015 5.66199 27.971 5.55555C27.9405 5.44912 27.8867 5.35056 27.8135 5.26679C27.7402 5.18302 27.6492 5.11607 27.5468 5.07065C27.4445 5.02522 27.3333 5.00243 27.2211 5.00385L4.77888 5.00006ZM18.5241 19.1139H13.5259L12.1725 12.146H19.7352L18.5241 19.1139Z"
      fill="#2684FF"
    />
    <path
      d="M26.952 12.1455H19.7352L18.5241 19.1134H13.5258L7.62402 26.0168C7.81108 26.1762 8.04958 26.2649 8.29687 26.2669H23.9607C24.145 26.2692 24.324 26.2062 24.4652 26.0894C24.6063 25.9726 24.7002 25.8097 24.7296 25.6303L26.952 12.1455Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="28.6206"
        y1="14.0968"
        x2="16.8266"
        y2="23.4374"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

Bitbucket.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Bitbucket;
