import React from "react";
import PropTypes from "prop-types";

const Slack = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      d="M9.04233 19.1657C9.04233 20.5533 7.90878 21.6869 6.52117 21.6869C5.13355 21.6869 4 20.5533 4 19.1657C4 17.7781 5.13355 16.6445 6.52117 16.6445H9.04233V19.1657Z"
      fill="#E01E5A"
    />
    <path
      d="M10.3125 19.1657C10.3125 17.7781 11.446 16.6445 12.8337 16.6445C14.2213 16.6445 15.3548 17.7781 15.3548 19.1657V25.4784C15.3548 26.866 14.2213 27.9996 12.8337 27.9996C11.446 27.9996 10.3125 26.866 10.3125 25.4784V19.1657Z"
      fill="#E01E5A"
    />
    <path
      d="M12.8337 9.04233C11.446 9.04233 10.3125 7.90878 10.3125 6.52117C10.3125 5.13355 11.446 4 12.8337 4C14.2213 4 15.3548 5.13355 15.3548 6.52117V9.04233H12.8337Z"
      fill="#36C5F0"
    />
    <path
      d="M12.8339 10.3125C14.2215 10.3125 15.3551 11.446 15.3551 12.8337C15.3551 14.2213 14.2215 15.3548 12.8339 15.3548H6.52118C5.13355 15.3548 4 14.2213 4 12.8337C4 11.446 5.13355 10.3125 6.52118 10.3125H12.8339Z"
      fill="#36C5F0"
    />
    <path
      d="M22.9575 12.8337C22.9575 11.446 24.0911 10.3125 25.4787 10.3125C26.8663 10.3125 27.9998 11.446 27.9998 12.8337C27.9998 14.2213 26.8663 15.3548 25.4787 15.3548H22.9575V12.8337Z"
      fill="#2EB67D"
    />
    <path
      d="M21.6869 12.8339C21.6869 14.2215 20.5533 15.3551 19.1657 15.3551C17.7781 15.3551 16.6445 14.2215 16.6445 12.8339V6.52118C16.6445 5.13355 17.7781 4 19.1657 4C20.5533 4 21.6869 5.13355 21.6869 6.52118V12.8339Z"
      fill="#2EB67D"
    />
    <path
      d="M19.1657 22.9575C20.5533 22.9575 21.6869 24.0911 21.6869 25.4787C21.6869 26.8663 20.5533 27.9998 19.1657 27.9998C17.7781 27.9998 16.6445 26.8663 16.6445 25.4787V22.9575H19.1657Z"
      fill="#ECB22E"
    />
    <path
      d="M19.1659 21.6869C17.7783 21.6869 16.6448 20.5533 16.6448 19.1657C16.6448 17.7781 17.7783 16.6445 19.1659 16.6445H25.4787C26.8663 16.6445 27.9998 17.7781 27.9998 19.1657C27.9998 20.5533 26.8663 21.6869 25.4787 21.6869H19.1659Z"
      fill="#ECB22E"
    />
  </svg>
);

Slack.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Slack;
