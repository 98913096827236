import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { loadEnvironments } from "Reducers/environment";

const useEnvironments = projectId => {
  const organizationId = useSelector(state =>
    state.app?.getIn(["me", "data", "username"])
  );
  const environments = useSelector(state =>
    state.environment?.getIn(["data", organizationId, projectId])
  );

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!environments || !environments?.size) {
        dispatch(loadEnvironments(projectId, organizationId));
      }
    },
    [projectId, organizationId]
  );

  return environments && !!environments.size ? environments.toJS() : undefined;
};

export default useEnvironments;
