import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const StateIconLayout = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  padding: 2px;
  box-sizing: border-box;
  svg {
    width: 100%;
  }
`;

const PendingStateIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <StateIconLayout>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style={{ enableBackground: "new 0 0 20 20" }}
        xmlSpace="preserve"
        role="img"
        aria-labelledby={`pending-state-icon-${uid}`}
      >
        <title id={`pending-state-icon-${uid}`}>Pending state icon</title>
        <path
          className="icon-pending"
          d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M14.9,13.8c-0.3,0.4-0.8,0.6-1.2,0.3L9,11.3
          V5.9C9,5.4,9.4,5,9.9,5s0.9,0.4,0.9,0.9v4.4l3.7,2.2C15,12.8,15.1,13.4,14.9,13.8z"
          fill={color}
        />
      </svg>
    </StateIconLayout>
  );
};

PendingStateIcon.propTypes = {
  color: PropTypes.string
};

export default PendingStateIcon;
