import React from "react";
import PropTypes from "prop-types";

const Sumologic = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      d="m 12.5234,12.63552 c 0.6355,0.14953 1.757,0.44859 3.3271,0.78504 4.5981,1.04674 7.1028,2.16824 8.1495,4.29904 v 5.7197 C 22.7664,26.243 19.514,28 14.9159,28 10.61682,28 7.58879,26.9159 4,23.7383 l 3.3271,-3.7009 c 1.64486,1.3458 2.95327,2.2056 4.07477,2.6542 1.15883,0.486 2.39253,0.7477 3.66353,0.7103 1.757,0 3.1028,-0.486 3.6636,-1.3832 V 20.0374 C 18.3178,19.4393 17.5701,19.1028 16.7477,18.8411 16.1122,18.729 15.028,18.4673 13.4206,18.0935 8.93458,17.1215 6.69159,16.0374 5.71963,14.1308 V 8.4486002 c 1.23364,-2.61682 4.33645,-4.4486 8.82247,-4.4486 3.8878,0 6.5794,0.934579 9.7196,3.5514 L 21.1589,11.28972 C 18.5047,9.1962602 16.8224,8.6355102 14.3925,8.6355102 c -1.757,0 -3.02801,0.52337 -3.55138,1.3458 V 11.70094 c 0.29907,0.41121 0.8972,0.71028 1.68228,0.93458 z"
      fill="#000099"
    />
  </svg>
);

Sumologic.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Sumologic;
