import React from "react";
import PropTypes from "prop-types";

const Newrelic = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      d="M27.824 13.1471C26.6977 7.97237 20.4924 4.92934 13.9618 6.34854C7.43114 7.76773 3.05137 13.1147 4.17612 18.288C5.30088 23.4613 11.5076 26.5073 18.0368 25.0866C24.566 23.6659 28.9487 18.3263 27.824 13.1471ZM16.0008 20.4875C15.0574 20.4875 14.1352 20.2077 13.3508 19.6836C12.5664 19.1595 11.955 18.4145 11.594 17.5429C11.2329 16.6714 11.1385 15.7123 11.3225 14.787C11.5066 13.8617 11.9609 13.0118 12.6279 12.3447C13.295 11.6777 14.1449 11.2234 15.0702 11.0393C15.9955 10.8553 16.9546 10.9497 17.8261 11.3108C18.6977 11.6718 19.4427 12.2832 19.9668 13.0676C20.4909 13.852 20.7707 14.7742 20.7707 15.7176C20.7707 16.344 20.6473 16.9642 20.4076 17.5429C20.1679 18.1217 19.8165 18.6475 19.3736 19.0904C18.9307 19.5333 18.4049 19.8847 17.8261 20.1244C17.2474 20.3641 16.6272 20.4875 16.0008 20.4875Z"
      fill="#008C99"
    />
    <path
      d="M16.8472 9C15.4927 9.00029 14.1687 9.4022 13.0427 10.1549C11.9166 10.9076 11.039 11.9773 10.5209 13.2288C10.0028 14.4803 9.86735 15.8572 10.1318 17.1857C10.3962 18.5141 11.0486 19.7342 12.0064 20.6919C12.9643 21.6495 14.1846 22.3017 15.5131 22.5658C16.8416 22.8299 18.2185 22.6942 19.4698 22.1758C20.7212 21.6574 21.7907 20.7796 22.5432 19.6534C23.2957 18.5272 23.6973 17.2031 23.6973 15.8486C23.6973 14.9491 23.5201 14.0585 23.1758 13.2274C22.8316 12.3964 22.327 11.6414 21.6908 11.0054C21.0547 10.3694 20.2996 9.86499 19.4685 9.5209C18.6374 9.17681 17.7467 8.99981 16.8472 9ZM15.7916 19.8442C14.9676 19.8442 14.1621 19.5998 13.4769 19.142C12.7918 18.6842 12.2578 18.0335 11.9424 17.2722C11.6271 16.511 11.5446 15.6732 11.7054 14.8651C11.8661 14.0569 12.2629 13.3145 12.8456 12.7318C13.4283 12.1492 14.1706 11.7524 14.9788 11.5916C15.787 11.4309 16.6247 11.5134 17.386 11.8287C18.1473 12.144 18.798 12.678 19.2558 13.3632C19.7136 14.0483 19.9579 14.8539 19.9579 15.6779C19.9579 16.7826 19.5192 17.8421 18.7382 18.6234C17.9571 19.4047 16.8978 19.8438 15.7931 19.8442H15.7916Z"
      fill="#70CCD3"
    />
  </svg>
);

Newrelic.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Newrelic;
