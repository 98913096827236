import styled from "styled-components";

const TableRow = styled.div`
  height: 56px;
  width: calc(100% - 64px);
  margin: 0 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px solid ${props => props.theme.tableBorder};
  &:last-of-type {
    border-bottom: none;
  }
`;

export default TableRow;
