import styled from "styled-components";

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  box-shadow: 0px 1px 4px ${props => props.theme.settingShadow};
  border-radius: 2px;
  padding: 4px 4px 4px 8px;
`;

export const CloseTagButton = styled.button`
  display: flex;

  background-color: white;
  border: none;
  cursor: pointer;

  svg {
    height: 10px;
    width: 10px;
    > g > g {
      fill: ${props => props.theme.envTreeInactiveIcon};
    }
  }

  &:hover {
    svg {
      > g > g {
        fill: ${props => props.theme.sectionTextLight};
      }
    }
  }
`;
