import PropTypes from "prop-types";

const Redirect = ({ to, method = "assign" }) => {
  const url = to.startsWith("http") ? to : window.location.origin + to;
  window.location[method](url);
  return null;
};

Redirect.propTypes = {
  to: PropTypes.string
};

export default Redirect;
