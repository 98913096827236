import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";

import { capitalize } from "Libs/utils";
import useInput from "Hooks/useInput";
import Button from "UI/Button";

import AlertIcon from "Icons/AlertIcon";
import ButtonWrapper from "Components/ButtonWrapper";
import CheckboxField from "Components/fields/CheckboxField";
import Dropdown from "Components/Dropdown";
import Error from "Components/Error";
import Heading6 from "Components/styleguide/Heading6";
import InputField from "Components/fields/InputField";
import InvitationConfirmationModal from "./InvitationConfirmationModal";
import InvitationResendModal from "./InvitationResendModal";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";

import * as S from "./styles";

const DEFAULT_ACCESS = "viewer";
const NB_BRANCHES = 5;

const AccessForm = ({
  access,
  accessesAreLoading,
  enabled,
  environmentAccesses,
  environments,
  isLoading,
  onCancel,
  onDelete,
  onSave,

  errors = {},
  invitation,
  invitationState,
  resendInvitationFct
}) => {
  const intl = useIntl();
  const { environmentId } = useDecodedParams();

  const [accessesList, setAccesses] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const { inputs, handleInputChange } = useInput(
    {
      superUser: access?.role === "admin"
    },
    () => setIsChanged(true)
  );
  const [showAll, setShowAll] = useState(false);

  // use only active environments or master environment
  const activeEnviroments = environments.filter(
    env => env.parent === null || env.status !== "inactive"
  );

  useEffect(
    () => {
      let envAccesses = [];
      if (environmentAccesses.size) {
        envAccesses = environmentAccesses
          .entrySeq()
          .reduce((list, [key, accesses]) => {
            const acc = accesses.get(access?.user);
            list[key] = {
              id: acc?.id,
              userId: acc?.user,
              envId: key,
              role: acc?.role
                ? acc.role
                : environmentId && !access
                  ? DEFAULT_ACCESS
                  : "no-access",
              ref: React.createRef()
            };
            return list;
          }, {});
      }
      if (environmentId) {
        envAccesses[environmentId] = {
          id: access?.id,
          userId: access?.user,
          envId: environmentId,
          role: access?.role || DEFAULT_ACCESS
        };
      }
      setAccesses(envAccesses);
    },
    [accessesAreLoading]
  );

  // On Super User Changes
  useEffect(
    () => {
      activeEnviroments.forEach(environment => {
        // If this isn't a new access populate the form with the
        // previous role from the state.
        const role = inputs.superUser
          ? "admin"
          : environmentAccesses[environment.id.role];
        onPermissionChange(environment.id, role);
      });
    },
    [inputs?.superUser]
  );

  const onPermissionChange = (envId, role) => {
    let list = { ...accessesList };
    if (!list[envId]) return;
    list[envId].role = role;
    setAccesses(list);
    setIsChanged(true);
  };

  const handleApplyAll = (e, role) => {
    e?.preventDefault();
    for (const acc of Object.values(accessesList)) {
      if (acc.role !== role && acc.ref?.current) {
        acc.ref.current?.setValue(getAccessOption(role));
      }
    }
  };

  const handleSave = () => {
    if (access || (inputs.agreement && inputs.email)) {
      let accesses = [];
      for (const acc of Object.values(accessesList)) {
        accesses.push({
          id: acc.id,
          environmentId: acc.envId,
          role: acc.role
        });
      }
      onSave({
        accesses,
        email: access?.getUser().email || inputs.email.trim(),
        superUser: inputs.superUser,
        userId: access?.user
      });
      setIsChanged(false);
    }
  };

  const handleResendInvitation = () => {
    resendInvitationFct(inputs.email, {
      accesses: accessesList,
      superUser: inputs.superUser,
      id: access.user
    });
  };

  const getAccessOption = value => {
    return getAccessOptions().find(elt => elt.value === value);
  };

  const getAccessOptions = () => {
    return [
      {
        value: "no_access",
        label: intl.formatMessage({ id: "access.options.no_access" })
      },
      {
        value: "admin",
        label: intl.formatMessage({ id: "access.options.admin" })
      },
      {
        value: "viewer",
        label: intl.formatMessage({ id: "access.options.viewer" })
      },
      {
        value: "contributor",
        label: intl.formatMessage({ id: "access.options.contributor" })
      }
    ].filter(option => {
      if (environmentId && option.value === "no_access") {
        return false;
      }
      return true;
    });
  };

  const canSubmit = () => {
    if (access) return true;
    return inputs.email && inputs.agreement;
  };

  const shouldRenderSaveCancelButtons = enabled && (isChanged || !access);
  const shouldRenderDeleteButton =
    enabled && access?.hasPermission && access.hasPermission("#delete");

  return (
    <S.Wrapper className={access ? "" : "add-form"}>
      <LiveMessage
        message={intl.formatMessage({
          id: access ? "edit_user" : "add_user"
        })}
        aria-live="polite"
      />

      <S.Form
        id={`${environmentId ? "environment" : "project"}-access-${
          access ? "edit" : "add"
        }-form`}
        aria-labelledby="add-user-heading"
        onSubmit={e => e.preventDefault()}
      >
        {errors?.message && !errors?.detail && <Error>{errors.message}</Error>}
        {!errors?.message &&
          !errors?.detail &&
          errors?.isError && (
            <Error>
              {intl.formatMessage({ id: "invitation.something_went_wrong" })}
            </Error>
          )}

        {!access && (
          <>
            <p className="description">
              {intl.formatMessage({
                id: `access.${
                  environmentId ? "environment" : "project"
                }.description`
              })}
            </p>
            <S.InputWrapper>
              <InputField
                id="email"
                label={intl.formatMessage({ id: "email" })}
                placeholder={intl.formatMessage({ id: "email" })}
                value={inputs.email}
                onChange={handleInputChange}
                error={errors?.detail?.email}
                disabled={!enabled}
                aria-disabled={!enabled}
                ariaLabel={intl.formatMessage({ id: "user_email" })}
              />
            </S.InputWrapper>
          </>
        )}

        {!environmentId && (
          <S.InputWrapper className="field">
            <CheckboxField
              id="superUser"
              label={`${intl.formatMessage({ id: "project_admin" })}`}
              value={inputs.superUser}
              onChange={handleInputChange}
              error={errors?.detail?.superUser}
              disabled={!enabled}
              aria-disabled={!enabled}
              forId="superUser"
            />
          </S.InputWrapper>
        )}

        {(!inputs.superUser || environmentId) &&
          (!accessesAreLoading ? (
            <S.LoadingWrapper>
              <Loading loadType="environments" />
            </S.LoadingWrapper>
          ) : (
            <S.EnvironmentPermissions>
              {environmentId && (
                <S.PermissionWrapper className={"environment-permission"}>
                  <S.InputWrapper className="field">
                    <Dropdown
                      label={intl.formatMessage({
                        id: "access.environment.permissions.type"
                      })}
                      options={getAccessOptions()}
                      onChange={({ value }) =>
                        onPermissionChange(environmentId, value)
                      }
                      defaultValue={{
                        value: access?.role || DEFAULT_ACCESS,
                        label: access?.role || DEFAULT_ACCESS
                      }}
                      clearable={false}
                      multi={false}
                      error={errors?.detail?.role}
                      disabled={!enabled}
                      required={true}
                      searchable={false}
                      className="inline"
                    />
                  </S.InputWrapper>
                </S.PermissionWrapper>
              )}

              {!!activeEnviroments.size && (
                <>
                  <Heading6>
                    {intl.formatMessage({
                      id: "access.environment.permissions"
                    })}
                  </Heading6>
                  <S.EnvironmentHeaders className="environment-headers">
                    <Label>
                      {intl.formatMessage({
                        id: "access.environment.permissions.env"
                      })}
                    </Label>
                    <Label>
                      {intl.formatMessage({
                        id: "access.environment.permissions.type"
                      })}
                    </Label>
                  </S.EnvironmentHeaders>
                  <hr className="full-width" />
                </>
              )}
              {activeEnviroments.entrySeq().map(([id, env], i) => {
                const envAccess = accessesList[id];
                if (!envAccess) return;
                return (
                  <div key={id} hidden={!showAll && i >= NB_BRANCHES}>
                    <S.PermissionWrapper className={"line-permission"}>
                      <S.InputWrapper className="field" key={id}>
                        <Dropdown
                          label={env.name}
                          options={getAccessOptions()}
                          onChange={({ value }) =>
                            onPermissionChange(id, value)
                          }
                          defaultValue={{
                            value: envAccess.role,
                            label: envAccess.role
                          }}
                          clearable={false}
                          multi={false}
                          error={errors?.detail?.role}
                          disabled={!enabled}
                          searchable={false}
                          className="inline"
                          ref={envAccess.ref}
                        />
                        {activeEnviroments.size > 1 && (
                          <S.Btn
                            onClick={e => handleApplyAll(e, envAccess.role)}
                          >
                            {intl.formatMessage({
                              id: "access.apply_all",
                              defaultMessage: "Apply to all"
                            })}
                          </S.Btn>
                        )}
                      </S.InputWrapper>
                      <hr />
                    </S.PermissionWrapper>
                  </div>
                );
              })}
              {activeEnviroments.size > NB_BRANCHES && (
                <S.More>
                  <S.BtnMore onClick={() => setShowAll(!showAll)}>
                    {intl.formatMessage(
                      {
                        id: `access.environment.show.${
                          showAll ? "less" : "more"
                        }`
                      },
                      { numbers: activeEnviroments.size - NB_BRANCHES }
                    )}
                  </S.BtnMore>
                </S.More>
              )}
            </S.EnvironmentPermissions>
          ))}

        {!access ? (
          <S.InputWrapper className="field">
            <CheckboxField
              id="agreement"
              label={intl.formatMessage({ id: "add_user_agreement" })}
              value={inputs.agreement}
              onChange={handleInputChange}
              error={errors?.detail?.agreement}
              forId="agreement"
              required={true}
            />
          </S.InputWrapper>
        ) : (
          enabled &&
          !environmentId && (
            <>
              <div className="warning">
                <AlertIcon /> {intl.formatMessage({ id: "access.alert" })}
              </div>
              <hr />
            </>
          )
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <S.Flex
            justifyContent={
              shouldRenderSaveCancelButtons ? "space-between" : "flex-end"
            }
          >
            {shouldRenderSaveCancelButtons && (
              <ButtonWrapper>
                <Button
                  variant="primary"
                  id="access-save"
                  type="submit"
                  aria-label={intl.formatMessage({ id: "save" })}
                  onClick={handleSave}
                  disabled={!canSubmit()}
                >
                  {capitalize(intl.formatMessage({ id: "save" }))}
                </Button>
                <Button
                  variant="secondary"
                  id="access-cancel"
                  type="button"
                  aria-label={intl.formatMessage({ id: "cancel" })}
                  onClick={onCancel}
                >
                  {capitalize(intl.formatMessage({ id: "cancel" }))}
                </Button>
              </ButtonWrapper>
            )}

            {shouldRenderDeleteButton && (
              <Button
                variant="tertiary"
                style={{ justifySelf: "flex-end" }}
                id="access-delete"
                type="button"
                aria-label={intl.formatMessage({
                  id: "delete"
                })}
                onClick={onDelete}
              >
                {capitalize(intl.formatMessage({ id: "delete" }))}
              </Button>
            )}
          </S.Flex>
        )}
      </S.Form>

      <InvitationConfirmationModal
        isOpen={!!invitation || invitationState === "success"}
        email={invitation?.email}
        closeModal={onCancel}
      />

      <InvitationResendModal
        isOpen={errors?.resendInvitation}
        resendInvitation={handleResendInvitation}
        email={inputs.email}
        closeModal={onCancel}
      />
    </S.Wrapper>
  );
};

AccessForm.propTypes = {
  access: PropTypes.object,
  accesses: PropTypes.object,
  accessesAreLoading: PropTypes.bool,
  enabled: PropTypes.bool,
  environmentAccesses: PropTypes.object,
  environments: PropTypes.object,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,

  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  invitation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  invitationState: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resendInvitationFct: PropTypes.func
};

export default AccessForm;
