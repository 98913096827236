import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  &.settings-wrapper {
    padding-top: 0;
  }
  &.setting-return {
    padding-top: 0;
    margin-top: -16px;
  }
  code {
    color: ${props =>
      props.theme.name === "dark"
        ? props.theme.codeText
        : themeHelpers(props.theme, "primaryShade")};
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkTint")
        : themeHelpers(props.theme, "primaryTint")};
  }
`;
