import styled, { css } from "styled-components";

import { themeHelpers } from "Libs/theme";

export const Wrapper = styled.div`
  margin-bottom: 8px;
  border-radius: 4px;
  background: ${props => props.theme.lineBackground};
  &:not(.open):hover {
    background: ${props => props.theme.lineHoverBackground};
  }

  &.open {
    margin: 16px 0;
    background: ${props => props.theme.lineHoverBackground};
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0px 10px 20px rgba(75, 97, 128, 0.08)"};

    .setting-line {
      background: ${props => themeHelpers(props.theme, "settingBarColor")};
      border-radius: 4px 4px 0 0;
      &:hover {
        background: ${props => themeHelpers(props.theme, "settingBarColor")};
        box-shadow: none;
        .open-text {
          opacity: 0;
        }
      }
    }
    .action svg {
      transform: rotate(180deg);
    }
  }

  form {
    padding: 24px 32px;
    .toggle-field {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .toggle-field + .toggle-field,
    .toggle-field + .actions {
      border-top: 1px solid ${props => props.theme.sectionBorder};
      padding-top: 24px;
    }
    .select-box__control {
      min-height: 38px;
      height: 40px;
      .select-box__value-container {
        height: 40px;
        .select-box__single-value {
          height: 40px;
          line-height: 38px;
        }
      }
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  min-height: 60px;
  box-sizing: border-box;
  font-size: 16px;
  color: ${props => props.theme.sectionText};
  border-radius: 4px;
  text-decoration: none;

  ${props =>
    props.variant === "mobile" &&
    css`
      padding: 0 24px;

      @media (min-width: 768px) {
        padding: 0 32px;
      }
    `};

  .open-text {
    opacity: 0;
    color: ${props => props.theme.links};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  button {
    &:not([data-uikit]) {
      cursor: pointer;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      .chevron svg path {
        fill: ${props => props.theme.chevron};
      }
      &:hover,
      &:focus {
        background: transparent;
        padding: 0;
        margin: 0;
        outline: none;
        box-shadow: none;
        border-color: transparent;
        .open-text {
          opacity: 1;
        }
        .chevron svg path {
          fill: ${props => props.theme.links};
        }
      }
    }
  }
  hr {
    background: ${props => props.theme.sectionBorder};
  }
  &:hover {
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0px 10px 20px rgba(75, 97, 128, 0.08)"};
    .open-text {
      opacity: 1;
    }
    .chevron svg path {
      fill: ${props => props.theme.links};
    }
  }
`;

export const Info = styled.div`
  width: ${props => props.width || "100%"};
`;
