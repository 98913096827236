import React from "react";
import styled from "styled-components";

import { Skeleton, Card, Square } from "Components/Skeleton";

const Row = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Layout = styled(Skeleton)`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ActivitySectionSkeleton = () => (
  <Layout synchronize>
    <Row padding="20px 16px">
      <Square margin="0 16px 0 0" height="32px" width="32px" />
      <Square width="140px" height="24px" />
      <Square width="84px" height="24px" margin="0 0 0 auto" />
    </Row>
  </Layout>
);

export default ActivitySectionSkeleton;
