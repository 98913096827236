import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ImmutablePropTypes from "react-immutable-proptypes";

import useDecodedParams from "Hooks/useDecodedParams";

import Loading from "Components/Loading";
import ActivityEmpty from "./ActivityEmpty";
import Activity from "../activities";

import * as S from "./ActivityList.styles";

const sortByCreationDate = (item1, item2) =>
  new Date(item2.created_at).getTime() - new Date(item1.created_at).getTime();

const ActivityList = ({
  activities,
  activityContext,
  activityType,
  activityIsLoading,
  canEditProject,
  organizationId
}) => {
  const history = useHistory();
  const { environmentId, projectId, integrationId } = useDecodedParams();

  const activityList = useMemo(
    () => activities.valueSeq().sort(sortByCreationDate),
    [activities]
  );
  const shouldShowEmptySection = useMemo(
    () => activityList?.size === 0 && !activityIsLoading,
    [activityList, activityIsLoading]
  );

  const getLogUrl = id => {
    let url = `/${organizationId}/${projectId}`;
    if (environmentId) {
      return `${url}/${encodeURIComponent(environmentId)}/log/${id}`;
    }
    if (integrationId) {
      return `${url}/-/integrations/${integrationId}/log/${id}`;
    }
    return `${url}/-/log/${id}`;
  };

  const openLog = id => {
    history.push(getLogUrl(id));
  };

  return (
    <S.ActivityListLayout>
      <S.ActivityListScroll>
        {shouldShowEmptySection ? (
          <ActivityEmpty activityType={activityType} />
        ) : (
          <div>
            {activityList.map(activity => {
              return (
                <Activity
                  key={activity.id}
                  openModal={() => openLog(activity.id)}
                  activity={activity}
                  canEditProject={canEditProject}
                  activityContext={activityContext}
                  organizationId={organizationId}
                  hideActionLink={true}
                />
              );
            })}
          </div>
        )}
        {activityIsLoading && <Loading iconOnly={true} />}
      </S.ActivityListScroll>
    </S.ActivityListLayout>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  activityType: PropTypes.string,
  canEditProject: PropTypes.bool,
  loadActivitiesOfType: PropTypes.func,
  activityContext: PropTypes.string,
  organizationId: PropTypes.string,
  activityIsLoading: PropTypes.bool,
  hideActionLink: PropTypes.bool
};

export default ActivityList;
