import React from "react";
import proptypes from "prop-types";

import { CheckboxInput, CheckedCheckbox, CheckedCheckboxTick } from "./style";

const Checkbox = ({ onChange, checked }) => {
  return (
    <>
      {checked ? (
        <CheckedCheckbox onClick={() => onChange(false)}>
          <CheckedCheckboxTick />
        </CheckedCheckbox>
      ) : (
        <CheckboxInput
          type="checkbox"
          onChange={() => onChange(true)}
          checked={checked}
        />
      )}
    </>
  );
};

Checkbox.propTypes = {
  onChange: proptypes.func,
  checked: proptypes.bool
};

export default Checkbox;
