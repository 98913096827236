import styled from "styled-components";

export const Layout = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: capitalize;

  color: ${({ priority, theme }) => {
    switch (priority) {
      case "low":
        return "#98A0AB";
      case "normal":
        return "#5F5E70";
      case "high":
        return "#FF8359";
      case "urgent":
        return "#FB3728";
      default:
        return theme.sectionText;
    }
  }};
`;
