import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "react-intl";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color: ${props => props.theme.sectionText};
`;

const InvitationRevokeModal = ({
  isOpen,
  closeModal,
  email,
  revokeInvitation
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-small"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.revokeModalTitle" })}
      id={"invitation-revoke-modal"}
    >
      <ModalBody className="modal-body">
        {intl.formatMessage(
          { id: "invitation.revokeModalBody" },
          {
            email
          }
        )}
      </ModalBody>
      <ButtonWrapper className="modal-buttons">
        <Button
          type="button"
          className="primary"
          aria-label={intl.formatMessage({ id: "invitation.revoke" })}
          onClick={revokeInvitation}
        >
          {intl.formatMessage({ id: "invitation.revoke" })}
        </Button>
        <Button
          type="button"
          className="secondary"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={closeModal}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

InvitationRevokeModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func,
  revokeInvitation: PropTypes.func
};

export default InvitationRevokeModal;
