import React from "react";
import PropTypes from "prop-types";

import Activity from "./Activity";
import activityTemplates from "./activityTemplates";

const ActivityItem = props => {
  const { type } = props.activity;
  const activityTemplate = activityTemplates[type] || activityTemplates.unknown;

  return (
    <Activity
      icon={activityTemplate.icon}
      title={activityTemplate.title && activityTemplate.title(props)}
      state={props.activity.state}
      moment={props.activity.created_at}
      result={props.activity.result}
      commits={props.activity.commits}
      log={props.activity.log}
      {...props}
    />
  );
};

ActivityItem.propTypes = {
  activity: PropTypes.object
};

export default ActivityItem;
