import styled from "styled-components";

export const ActivityListLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 0;

  .icon-only {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

export const ActivityListScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 -32px;
`;
