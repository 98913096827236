import styled from "styled-components";

export const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;

  path {
    fill: ${props =>
      props.hasError ? props.theme.fieldError : props.theme.links};
  }
`;
export const ErrorMessage = styled.div`
  color: ${props => props.theme.fieldError};
  font-size: 13px;
  margin-bottom: 16px;
`;

export const Layout = styled.div``;

export const FileInput = styled.input`
  display: none;
`;

export const CustomFileInput = styled.button.attrs({ type: "button" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  border: 0;
  background-color: transparent;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${props =>
    props.hasError ? props.theme.fieldError : props.theme.links};

  cursor: pointer;
`;

export const AttachmentList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

export const BundleSize = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.subtitleText};
  margin-bottom: 16px;
`;
