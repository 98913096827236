import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const EditIcon = ({ color = "#4b6180" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-edit"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`edit-icon-${uid}`}
    >
      <title id={`edit-icon-${uid}`}>Delete icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.182 6.06065L17.2427 5L18.6569 6.41421L17.5963 7.47486L16.182 6.06065ZM14.7678 7.47486L6.18543 16.0573L5.00692 18.65L7.59964 17.4715L16.182 8.88908L14.7678 7.47486ZM4.3647 15.2297C4.46394 15.0113 4.60164 14.8126 4.77122 14.643L15.8285 3.58579C16.6095 2.80474 17.8759 2.80474 18.6569 3.58579L20.0711 5C20.8522 5.78105 20.8522 7.04738 20.0711 7.82843L9.01386 18.8857C8.84428 19.0553 8.64558 19.193 8.42725 19.2922L5.83452 20.4707C4.15216 21.2354 2.42148 19.5047 3.18619 17.8224L4.3647 15.2297Z"
        fill={color}
      />
    </svg>
  );
};

EditIcon.propTypes = {
  color: PropTypes.string
};

export default EditIcon;
