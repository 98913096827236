import React, { useState } from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

import InfoIcon from "Icons/InfoIcon";
import WarningIcon from "Icons/WarningIcon";
import SuccessIcon from "Icons/SuccessStateIcon";
import ErrorIcon from "Icons/ErrorIcon";

import * as S from "./styles";

const getIcon = (level, theme) => {
  switch (level) {
    case "warning":
      return <WarningIcon color={theme.failure} />;
    case "success":
      return <SuccessIcon color={theme.success} />;
    case "error":
      return <ErrorIcon color={theme.error} />;
    default:
      return <InfoIcon color={theme.primary} />;
  }
};

const Flag = ({ level, singleLine, onClose, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();

  const icon = getIcon(level, theme);

  return (
    isOpen && (
      <S.Layout singleLine={singleLine} {...props}>
        <S.IconContainer>{icon}</S.IconContainer>
        <S.Content>{children}</S.Content>
        <S.Close
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
        />
      </S.Layout>
    )
  );
};

Flag.defaultProps = {
  level: "info",
  inline: false,
  onClose: () => undefined
};

Flag.propTypes = {
  level: PropTypes.string,
  singleLine: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export const Title = S.Title;
export const Description = S.Description;

export default Flag;
