import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Back from "Icons/Back";

import * as S from "./BackLink.styles";

const BackLink = ({ from, to }) => {
  const intl = useIntl();
  const { location } = useHistory();

  const route = useSelector(
    state => state.app?.getIn(["routeHistory", "previousRoutes", 0]) || to
  );

  const fromPage = location.state?.from || from;

  return (
    <S.Wrapper>
      <Link className="back-link" to={location.state?.url || route}>
        <Back />
        {intl.formatMessage({ id: "back" })}{" "}
        {fromPage && <>{intl.formatMessage({ id: `back.${fromPage}` })}</>}
      </Link>
    </S.Wrapper>
  );
};

BackLink.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string
};

export default BackLink;
