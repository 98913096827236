import styled from "styled-components";
import { Link } from "react-router-dom";

import { semiBoldAlias, themeHelpers } from "Libs/theme";

import ButtonWrapper from "Components/ButtonWrapper";
import Button from "UI/Button";
import CheckboxField from "Components/fields/CheckboxField";
import InfoDialog from "Components/InfoDialog";
import InputField from "Components/fields/InputField";
import Icon from "Icons/WarningIcon";

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: ${props => themeHelpers(props.theme, "sectionColor")};
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
`;

export const FormWrapper = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;

  ${ButtonWrapper} {
    margin-top: 37px;
  }
`;

export const DeleteButton = styled(Button)`
  margin-left: auto !important;
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 560px;
  width: 100%;
`;

export const Input = styled(InputField)`
  width: 100%;
`;

export const InputDialog = styled(InfoDialog)`
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  margin: 0;
  width: 24px;
  height: 24px;
`;

export const Option = styled.div`
  display: flex;
`;

export const Checkbox = styled(CheckboxField)`
  width: auto;
  margin: 0 5px 0;

  strong {
    ${semiBoldAlias};
  }
`;

export const Warning = styled.p`
  display: flex;
  align-items: center;
  margin: 37px 0 0 0;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 16px;

  ${WarningIcon} {
  }
`;

export const WarningIcon = styled(Icon)`
  margin-right: 5px;
  svg path {
    fill: ${({ theme }) => theme.warning};
  }
`;

export const Inherited = styled.div`
  display: flex;
  margin: 0 0 18px 0;
`;

export const Inactive = styled.div`
  display: flex;
  margin: 0 0 18px 0;
  font-size: 12px;
  line-height: 20px;
  ${semiBoldAlias};
  color: ${props => props.theme.subtitleText};
`;

const Badge = styled(Link)`
  display: flex;
  padding: 0 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
`;

export const OverriddenFrom = styled(Badge)`
  background: ${props => props.theme.badgeWarningBg};
  color: ${props => props.theme.badgeWarningText};
`;

export const InheritedFrom = styled(Badge)`
  background: ${props => props.theme.background};
  color: ${props => props.theme.sectionTextLight};
`;

export const Toggle = styled.div`
  display: flex;
  margin-left: auto;
  label {
    margin-right: 16px;
    ${semiBoldAlias};
    font-size: 13px;
  }
  input[type='checkbox'] {
    appearance: none;
    position: relative;
    display: inline-block;
    height: 18px;
    width: 32px;
    margin: 0;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    transition: background .3s;
    background: ${props => props.theme.tableBorder};
    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 2px;
      top: 2px;
      width: 14px;
      height: 14px;
      transition: transform .3s ease, opacity .2s;
      border-radius: 50%;
      background: ${props => props.theme.sectionBg};
      transform: translateX(0);
      box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
    }
    &:checked {
      background: ${props => props.theme.primary};
      &:after {
        transform: translateX(14px);
      }
    }
`;
