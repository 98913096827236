import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import momentjs from "moment";
import { useParams } from "react-router-dom";

import client, { request } from "Libs/platform";
import logger from "Libs/logger";
import { hasHtml } from "Libs/utils";

import { MOMENTJS_OPTIONS } from "Constants/constants";

import PlatformAvatar from "Images/platform-avatar.svg";
import IconMore from "Icons/IconMore";
import BackupRestoreModal from "./Modals/BackupRestoreModal";
import ActivityDate from "./ActivityDate";
import CommitList from "./CommitList";
import StatusIcon from "./StatusIcon";

import * as S from "./styles";

// Hardcoded color granite, couldn't figure out how to make this take the color
// from the theme in a simple way, (this gets passed as a prop to the ActionDropdown)
const DropdownIcon = <IconMore color="#98a0ab" />;

const Activity = ({
  className = "",
  icon,
  title,
  link,
  openModal,
  activity,
  canEditProject
}) => {
  const [showCommits, setShowCommits] = useState(false);
  const [avatarPicture, setAvatarPicture] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { organizationId, projectId } = useParams();

  momentjs.updateLocale("en", MOMENTJS_OPTIONS);

  // fetchUserAvatar handles fetching avatars for the user associated to
  // the activity. It deals with special cases like the user not having
  // permissions to get other users info, or special users like the Bots
  const fetchUserAvatar = () => {
    const user = activity?.payload?.user;
    if (!user) return;

    // If the user does not have admin privileges, she will get errors when
    // trying to get other users info, this multiplied by a number of activities
    // are a lot of errors, so we return here, and a generated avatar will
    // be provided by the <Avatar /> component
    if (!canEditProject) return;

    const isIntegrationUser = user.id.startsWith("integration:");
    const isAdmin = user.id === "admin";
    const isRegularUser = !isAdmin && !isIntegrationUser;

    // Admin is an special kind of user, we are not getting an avatar
    // for it, but we want to show the logo instead of the default avatar
    // so we set the picture here
    if (isAdmin) {
      setAvatarPicture(PlatformAvatar);
    }

    // We fetch avatars for regular users here
    if (isRegularUser) {
      client
        .getAccount(user.id)
        .then(account => {
          setAvatarPicture(account.picture);
        })
        .catch(error => {
          // ignores 404 errors from the api, usually happens when the requester doesn't
          // have permission to fetch the user profile. Or the profie does not exist.

          if (![404, 403].includes(error.code) && !hasHtml(error)) {
            logger(error, {
              action: "avatar_fetch"
            });
          }
        });
    }
  };

  useEffect(fetchUserAvatar, [activity?.payload]);

  if (!activity) return false;

  const {
    created_at,
    payload,
    result,
    state,
    completed_at,
    started_at
  } = activity;

  // Shows or hides the commit panel
  const handleToggleCommit = (e, show) => {
    if (e) {
      e.preventDefault();
    }
    setShowCommits(show ? show : !showCommits);
    document.activeElement.blur();
  };

  // Makes a request to cancel an activity
  const handleStopRun = () => {
    request(activity.getLink("#cancel"), "POST").catch(logger);
  };

  const handleRestore = () => {
    setShowConfirmModal(true);
  };

  // Conditions
  const isCron = activity.type === "environment.cron";
  const isBackup = activity.type === "environment.backup";

  // Used to see if an activity should show the "stop run" link
  const isCancellable =
    state === "pending" || (isCron && state === "in_progress");

  const shouldRenderCommitsCount = payload?.commits && !showCommits;
  const shouldRenderDropdown = activity.hasLink;
  const shouldRenderStopRun = activity.hasLink && isCancellable;
  const shouldRenderRestore = isBackup && activity.hasLink;

  const lastCommit = payload?.commits?.slice(-1).pop();
  const duration = completed_at
    ? momentjs
        .duration(momentjs(completed_at).diff(momentjs(started_at)))
        .humanize({ s: 60, m: 60, h: 24, d: 28, M: 11 })
    : "";

  return (
    <S.ActivityListItemLayout withShadow={showCommits} className={className}>
      <S.Wrapper>
        <S.Content>
          <S.Icon>{icon}</S.Icon>
          <S.Avatar
            name={payload?.user?.display_name}
            picture={avatarPicture}
          />
          <S.Event>
            {title}
            {shouldRenderCommitsCount && (
              <S.Link tabIndex="0" onClick={e => handleToggleCommit(e, true)}>
                + {payload.commits && payload.commits.length}
              </S.Link>
            )}
            <S.DetailsWrapper>
              {payload?.environment?.title && (
                <S.Environment
                  data-testid="environment"
                  to={`/${organizationId}/${projectId}/${encodeURIComponent(
                    payload.environment.id
                  )}`}
                >
                  {payload.environment.title}
                </S.Environment>
              )}
              {lastCommit && (
                <>
                  <S.Sha>{lastCommit.sha?.substring(0, 7)}</S.Sha>
                  <S.Message>{lastCommit?.message}</S.Message>
                </>
              )}
            </S.DetailsWrapper>
          </S.Event>

          <S.Duration>{duration}</S.Duration>

          <S.ActivityDate>
            <ActivityDate date={created_at} activityId={activity?.id} />
          </S.ActivityDate>

          {/* not sure what this link part is doing */}
          {link && result === "success" ? <span>{link}</span> : ""}

          <StatusIcon viewLogs={openModal} state={state} result={result} />

          {shouldRenderDropdown && (
            <S.ActionButton
              icon={DropdownIcon}
              className="more"
              withArrow={false}
              id={activity?.id}
            >
              <S.LogLink onClick={() => openModal()}>
                <S.LogLinkText>
                  <FormattedMessage
                    id="activity.log_button"
                    defaultMessage="View log"
                  />
                </S.LogLinkText>
              </S.LogLink>
              {shouldRenderStopRun && (
                <S.LogLink onClick={() => handleStopRun()}>
                  <S.LogLinkText>
                    <FormattedMessage
                      id="activity.cancel_button"
                      defaultMessage="Stop run"
                    />
                  </S.LogLinkText>
                </S.LogLink>
              )}
              {shouldRenderRestore && (
                <S.LogLink onClick={() => handleRestore()}>
                  <S.LogLinkText>
                    <FormattedMessage
                      id="activity.restore_button"
                      defaultMessage="Restore"
                    />
                  </S.LogLinkText>
                </S.LogLink>
              )}
            </S.ActionButton>
          )}
        </S.Content>

        {showCommits && (
          <CommitList
            commits={payload?.commits}
            isOpen={showCommits}
            close={() => setShowCommits(false)}
          />
        )}
      </S.Wrapper>
      {shouldRenderRestore && (
        <BackupRestoreModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          activity={activity}
        />
      )}
    </S.ActivityListItemLayout>
  );
};

Activity.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.node,
  state: PropTypes.string,
  link: PropTypes.node,
  linkCommits: PropTypes.bool,
  openModal: PropTypes.func,
  id: PropTypes.string,
  activity: PropTypes.object.isRequired,
  activityContext: PropTypes.string,
  canEditProject: PropTypes.bool
};

export default Activity;
