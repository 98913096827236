import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import WarningIcon from "Icons/WarningIcon";

import * as S from "./styles";

const InvitationAlert = ({ onClose, environment, project, user }) => {
  const intl = useIntl();

  const name = `${project}${environment ? `/${environment}` : ""}`;

  return (
    <S.Wrapper>
      <p>
        <WarningIcon />{" "}
        {intl.formatMessage(
          { id: "access.already_exists" },
          {
            user: user.display_name || user.email,
            project: name,
            b: txt => <strong>{txt}</strong> // eslint-disable-line react/display-name
          }
        )}
      </p>
      <S.Close onClick={onClose} />
    </S.Wrapper>
  );
};

InvitationAlert.propTypes = {
  environment: PropTypes.string,
  project: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default InvitationAlert;
