import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import Heading2 from "Components/styleguide/Heading2";

import Editor from "../Editor";

import * as S from "./styles";

const ModalIDE = ({ closeModal, onSave, value, title }) => {
  const [newValue, setNewValue] = useState(value);
  const intl = useIntl();

  const handleChange = val => {
    setNewValue(val);
  };

  const handleSave = () => {
    onSave(newValue);
    closeModal();
  };

  return (
    <ModalWrapper isOpen={true} closeModal={closeModal}>
      {title && <Heading2>{title}</Heading2>}
      <S.Ide>
        <Editor value={newValue} onChange={handleChange} />
      </S.Ide>

      <S.Footer>
        <Button
          type="submit"
          aria-label={intl.formatMessage({ id: "modal.ide.update" })}
          onClick={handleSave}
        >
          {intl.formatMessage({ id: "modal.ide.update" })}
        </Button>
        <Button
          type="button"
          className="secondary"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={closeModal}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
      </S.Footer>
    </ModalWrapper>
  );
};

ModalIDE.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string
};

export default ModalIDE;
