/**
 * @typedef {filename: "string", data: "string"} Attachment
 */

/**
 * Transforms a File instance into a valid attachment.
 *
 * @param {File} file file object to be parsed
 * @returns {Promise<Attachment>}
 */
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        data: reader.result.split(",").pop(),
        filename: file.name
      });
    };
    reader.onerror = error => reject(error);
  });

/**
 * Transforms a list of Files into a list of attachments valid for the tickets
 * endpoint
 *
 * Attachments are formed by a `filename` and a data string, which is a base64
 * encoded string version of the file without the mime and base64 flag data.
 *
 *
 * @param {Array<File>|FileList} files Files to be trasnformed
 * @returns {Promise<Array<Attachment>>} Endpoint valid attachment list
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FileList
 * @see https://developer.mozilla.org/en-US/docs/Web/API/File
 */
const transform = async files =>
  Promise.all(Array.from(files || []).map(toBase64));

export default transform;
