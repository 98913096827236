import styled, { css } from "styled-components";

import { semiBoldAlias, shadows } from "Libs/theme";

import CloseBtn from "Components/CloseBtn";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color: ${props => props.theme.sectionBg};
  position: relative;
  border-radius: 2px;
  ${shadows.lg};
  max-width: 400px;

  ${props =>
    props.singleLine &&
    css`
      align-items: center;
    `};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: ${props => (props.singleLine ? "center" : "flex-start")};
`;

export const Content = styled.div`
  padding-left: 16px;
  flex: 1;
  padding-right: 24px;
`;

export const Title = styled.div`
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.sectionHeading};
  ${props =>
    props.singleLine &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.sectionText};
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const Close = styled(CloseBtn)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
