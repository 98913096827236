import styled, { css } from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  &.add-form {
    background: ${props => themeHelpers(props.theme, "sectionColor")};
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
  .description {
    font-size: 15px;
    color: ${props => props.theme.descriptionText};
    margin-bottom: 24px;
  }
  .warning {
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: ${props => props.theme.sectionText};
    ${semiBoldAlias};
    svg {
      margin-right: 24px;
    }
  }
  hr {
    background: ${props => props.theme.sectionBorder};
  }
`;

export const Form = styled.form`
  padding: 32px;
  width: 100%;
  box-sizing: border-box;
  .field:last-child .field {
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EnvironmentPermissions = styled.div`
  margin-bottom: 24px;
  hr {
    margin: 0;
  }
`;

export const Btn = styled.div`
  opacity: 0;
  font-size: 14px;
  line-height: 56px;
  color: ${props => props.theme.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PermissionWrapper = styled.div`
  &:hover {
    ${Btn} {
      opacity: 1;
    }
  }
  &.environment-permission {
    background: transparent;
    padding: 0;
    margin-bottom: 40px;
    label {
      margin-bottom: 8px;
      &:first-of-type {
        width: 315px;
      }
    }
    .dropdown > div {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &.line-permission {
    padding: 0;
    line-height: 56px;
    width: 100%;
    box-sizing: border-box;
    .field {
      margin-bottom: 0;
      line-height: 56px;
    }
    label {
      width: 350px;
      margin: 0;
      font-size: 15px;
      font-weight: normal;
      height: 56px;
      line-height: 56px;
    }
    .dropdown {
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .select-box {
          flex: 1;
          width: auto;
          height: auto;
          margin-right: 30px;
        }
        .select-box__control {
          background: transparent;
          width: 164px;
          border-color: transparent;
          margin-left: -16px;
        }
        .select-box__menu {
          margin-left: -16px;
        }
        .select-box__indicators {
          align-items: normal;
          .select-box__dropdown-indicator {
            margin: 9px 0 0 0;
          }
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  background: ${props => props.theme.settingBarActive};
  padding: 0 16px;
  line-height: 42px;
  margin-bottom: 32px;
`;

export const EnvironmentHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label:first-child {
    width: 350px;
  }
  label + label {
    flex: 1;
  }
`;

export const More = styled.div`
  display: flex;
  margin: 16px 0;
  text-align: center;
`;
export const BtnMore = styled.a`
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
