import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Gluescript = ({ size = "32px" }) => {
  const theme = useContext(ThemeContext);
  const color = theme?.textLight;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
    >
      <path
        d="M24 12.5996H8L14.2 19.3996V21.5996H18.1V19.1996L24 12.5996Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1001 9C14.2047 9 15.1001 8.10457 15.1001 7C15.1001 5.89543 14.2047 5 13.1001 5C11.9955 5 11.1001 5.89543 11.1001 7C11.1001 8.10457 11.9955 9 13.1001 9Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M13.6001 24.7002L16.1001 27.2002L18.6001 24.7002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1001 26.6996V21.5996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1001 6.7998C18.4001 6.7998 17.1001 8.0998 17.1001 9.7998H23.1001C23.1001 8.1998 21.8001 6.7998 20.1001 6.7998Z"
        fill={color}
      />
    </svg>
  );
};

Gluescript.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Gluescript;
