import React from "react";
import PropTypes from "prop-types";

import { EmptyServices } from "Components/illustrations";

import * as S from "./EmptyTree.styles";

const EmptyTree = ({ children, maxMessageWidth, ...props }) => (
  <S.EmptyTree id="empty-tree" {...props}>
    <S.Image>
      <EmptyServices id="empty-services" />
    </S.Image>
    <S.Message maxWidth={maxMessageWidth}>{children}</S.Message>
  </S.EmptyTree>
);

EmptyTree.propTypes = {
  children: PropTypes.node,
  maxMessageWidth: PropTypes.string
};

export default EmptyTree;
