import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/keymap/sublime";
import "codemirror/mode/javascript/javascript";
import "./theme.css";

const defaultOptions = {
  tabSize: 2,
  autoCloseBrackets: true,
  matchBrackets: true,
  showCursorWhenSelecting: true,
  lineNumbers: true,
  fullScreen: true,
  theme: "platformsh"
};

const Editor = ({
  height = 600,
  language = "javascript",
  onChange,
  options = {},
  value = "",
  width = "100%"
}) => {
  const [editor, setEditor] = useState();

  const textareaRef = useRef();
  const config = {
    ...defaultOptions,
    ...options,
    mode: language
  };

  useEffect(() => {
    if (!editor) setupIDE();
    return () => {
      if (editor) {
        editor.toTextArea();
        setEditor(undefined);
      }
    };
  }, []);

  useEffect(
    () => {
      if (editor && value !== editor.getValue()) editor.setValue(value);
    },
    [value]
  );

  const setupIDE = () => {
    const instance = CodeMirror.fromTextArea(textareaRef.current, config);
    instance.setSize(width, height);
    instance.on("change", e => onChange(e.getValue()));
    setEditor(instance);
  };

  return <textarea ref={textareaRef} value={value} />;
};

Editor.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Editor;
