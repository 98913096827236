import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-right: -9px;
  & > div + div {
    margin-left: 8px;
  }
  .info-button {
    > button {
      width: 50px;
      padding: 0 !important;
      justify-content: center;
    }
    span.chevron {
      display: none;
    }
    .tooltip {
      z-index: 3;
    }
  }

  .tooltip-content {
    pre {
      line-height: 21px;
      font-size: 12px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: normal;
      margin-right: 0;
    }
    .copyable-area + .copyable-area {
      margin-top: 16px;
    }
  }
  .disabled button {
    opacity: 0.5;
    cursor: not-allowed;
  }
  a {
    padding: 0 8px;
    line-height: 24px;
    margin: 0 -8px;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      text-decoration: none;
      margin: -1px -9px -1px;
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
      outline: none;
      border-radius: 4px;
    }
    &:active {
      box-shadow: none;
      border: none;
      margin: 0 -8px;
    }
  }

  @media (min-width: 768px) {
    margin-right: 0;
    .info-button {
      > button {
        width: 80px;
        padding: 0 4px 0 16px !important;
        justify-content: space-between;
      }
      span.chevron {
        display: flex;
      }
    }
  }
`;

export const Layout = styled.div`
  padding-bottom: 16px;
  margin-left: 156px;
  min-width: 215px;

  .tooltip > section {
    padding: 16px;
  }

  > section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;

    @media (min-width: 960px) {
      flex-direction: row;
      > div {
        max-width: 50%;
      }
    }
  }
  #environment-urls {
    section {
      padding: 0;
      a:hover {
        span.path {
          position: relative;
          &:after {
            content: "";
            width: 100%;
            position: absolute;
            height: 1px;
            background: ${props => props.theme.links};
            bottom: 7px;
            left: 0;
            display: inline-block;
          }
        }
      }
    }
  }
  .rcs-custom-scroll {
    .rcs-inner-container {
      padding: 16px;
      box-sizing: border-box;
    }
    .rcs-inner-handle {
      background: ${props => themeHelpers(props.theme, "darkTint")};
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .no-scroll {
    padding: 16px;
  }
  @media (min-width: 768px) {
    position: absolute;
    top: -53px;
    right: 0;
    padding-bottom: 0;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 15px;
  a {
    color: ${props => props.theme.links};
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      margin-right: 8px;
    }
    .download-icon {
      fill: ${props => props.theme.links};
    }
  }
`;
