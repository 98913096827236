import styled from "styled-components";

import Link from "../Link";

export const Layout = styled(Link)`
  color: ${props => props.theme.label};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 18px;

  svg {
    margin-right: 12px;
    path {
      fill: ${props => props.theme.label};
    }
  }

  &:hover:not(:focus):after {
    content: none;
  }

  &:hover {
    color: ${props => props.theme.links};
    svg path {
      fill: ${props => props.theme.links};
    }
  }
`;
