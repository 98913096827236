import styled, { css } from "styled-components";

import { breakpoints } from "Libs/theme";

import CopyButton from "Components/CopyButton";

export const Header = styled.header`
  margin-bottom: 24px;
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

export const Org = styled.h4`
  margin: 8px 0 0 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #98a0ab;
`;

export const DotDotDot = styled.div`
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 20px;
  &:after {
    display: inline-block;
    content: "";
    animation: loader 2s infinite linear;
    width: 10px;
    margin-left: 2px;
    text-align: left;
  }

  @keyframes loader {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
  }
`;

export const Empty = styled.div`
  margin: 16px 0 0 12px;
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 20px;
`;

export const LogPre = styled.pre`
  display: flex;
  flex: 1;
  margin: 0 0 32px 0;
  padding: 0;
  box-sizing: border-box;
  background: ${props => props.theme.logModal.background};
  code {
    color: ${props => props.theme.logModal.color};
    background: ${props => props.theme.logModal.codeBackground};
  }

  > ${DotDotDot} {
    padding: 16px 0 0 12px;
  }

  @media (max-width: ${breakpoints[1]}) {
    margin: 16px -16px;
  }
`;

export const Container = styled.div`
  flex: 1;
  > div {
    padding: 0;
    box-sizing: border-box;
  }
`;

export const CopyBtn = styled(CopyButton)`
  margin-left: 8px;
`;

export const LineNumber = styled.div`
  display: inline-block;
  width: 24px;
  padding: 0 4px;
  cursor: pointer;
  background: ${props => props.theme.logModal.lineBackground};
  color: ${props => props.theme.logModal.lineColor};
  line-height: 21px;
  text-align: right;
  user-select: none;

  &:hover {
    color: ${props => props.theme.logModal.color};
  }
`;

export const Line = styled.div`
  &::selection,
  *::selection {
    background: ${props => props.theme.logModal.lineSelectedBackground};
  }
  code {
    padding: 0;
    margin: 0 16px;
    line-height: 21px;
  }

  &:first-child {
    code,
    ${LineNumber} {
      padding-top: 16px;
    }
  }

  ${props =>
    props.selected &&
    css`
      code {
        background: none;
      }
      &,
      ${LineNumber} {
        background: ${props => props.theme.logModal.lineSelectedBackground};
        color: ${props => props.theme.logModal.color};
      }
    `};
`;
