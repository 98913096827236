import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";

import CommitListItem from "./CommitListItem";

import * as S from "./styles";

const MAX_COMMIT_DISPLAY = 0;

class CommitList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.open = this.open.bind(this);
  }

  open(e) {
    e.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  }

  filterCommits(maxCommitNumber, commits, { open }) {
    if (open) {
      return commits;
    }

    return commits.slice(0, maxCommitNumber || MAX_COMMIT_DISPLAY);
  }

  render() {
    const { commits } = this.props;
    if (!commits) {
      return false;
    }

    return (
      <S.CommitListLayout>
        {this.props.isOpen &&
          this.props.commits.map(commit => (
            <CommitListItem
              key={commit.sha}
              sha={commit.sha}
              message={commit.message}
              name={commit.author && commit.author.name}
              date={commit.author && commit.author.date}
            />
          ))}
        {this.props.isOpen && (
          <S.HideLayout>
            <S.ToggleCommits
              className="text-link"
              onClick={() => {
                this.props.close();
              }}
            >
              <FormattedMessage id="hide_commits" />
            </S.ToggleCommits>
          </S.HideLayout>
        )}
      </S.CommitListLayout>
    );
  }
}

CommitList.propTypes = {
  commits: PropTypes.array,
  maxCommitNumber: PropTypes.number,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(CommitList);
