import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import useUniqueId from "Libs/useUniqueId";
import AccessibleTooltip from "Components/AccessibleTooltip";
import InProgressStateIcon from "Icons/InProgressStateIcon";
import PendingStateIcon from "Icons/PendingStateIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import CancelledStateIcon from "Icons/CancelledStateIcon";
import WarningIcon from "Icons/WarningIcon";

import * as S from "./styles";

const StatusIcon = ({ state, result, viewLogs }) => {
  const theme = useContext(ThemeContext);
  let icon = null;

  if (state === "in_progress") {
    icon = <InProgressStateIcon />;
  }

  if (state === "pending") {
    icon = <PendingStateIcon color={theme?.warning} />;
  }

  if (state === "complete" && result === "success") {
    icon = <SuccessStateIcon color={theme?.success} />;
  }

  if (state === "complete" && result === "cancelled") {
    icon = <CancelledStateIcon color={theme?.cancelled} />;
  }

  if (state === "complete" && result === "failure") {
    icon = <WarningIcon color={theme?.failure} />;
  }
  return (
    <AccessibleTooltip
      as="span"
      tooltipProps={{
        id: `activity-status-${useUniqueId()}`,
        children: state === "complete" ? result : state.replace("_", " ")
      }}
    >
      <S.Button onClick={viewLogs}>{icon}</S.Button>
    </AccessibleTooltip>
  );
};

StatusIcon.propTypes = {
  state: PropTypes.oneOf(["complete", "pending", "in_progress"]),
  result: PropTypes.oneOf(["success", "failure", "cancelled"]),
  viewLogs: PropTypes.func
};

export default StatusIcon;
