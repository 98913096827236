import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyIntegrationsSvg = ({
  uid,
  maincolor,
  bgcolor1,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="117"
      height="114"
      viewBox="0 0 117 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-integrations-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-integrations-illustration-${uid}`}>
        Empty integrations
      </title>
      <path d="M38.5 0L73.5 48L123.5 41V12L38.5 0Z" fill={bgcolor1} />
      <path
        d="M31.5 111.5C48.897 111.5 63 97.397 63 80C63 62.603 48.897 48.5 31.5 48.5C14.103 48.5 0 62.603 0 80C0 97.397 14.103 111.5 31.5 111.5Z"
        fill={bgcolor1}
      />
      <path
        d="M40.6992 26.8998C32.0992 26.8998 23.4992 26.8998 14.9992 26.8998C14.6992 26.8998 14.0992 26.9998 13.8992 26.6998C13.6992 26.4998 13.7992 25.7998 13.7992 25.4998C13.7992 24.9998 13.5992 24.1998 13.7992 23.6998C14.0992 22.8998 16.0992 23.1998 16.7992 23.1998C22.1992 23.1998 27.5992 23.1998 32.9992 23.1998C35.2992 23.1998 37.5992 23.1998 39.8992 23.1998C40.3992 23.1998 40.8992 23.0998 41.0992 23.5998C41.2992 24.0998 41.0992 24.8998 41.0992 25.3998C41.0992 25.8998 41.2992 26.8998 40.6992 26.8998C38.7992 27.0998 38.7992 30.0998 40.6992 29.8998C42.9992 29.6998 44.0992 28.0998 44.0992 25.8998C44.0992 24.1998 44.2992 22.3998 42.9992 21.1998C41.6992 19.9998 39.3992 20.2998 37.7992 20.2998C31.6992 20.2998 25.4992 20.2998 19.3992 20.2998C17.6992 20.2998 15.9992 20.1998 14.2992 20.2998C12.3992 20.3998 10.8992 21.6998 10.7992 23.6998C10.6992 24.8998 10.5992 26.2998 10.8992 27.3998C11.5992 30.0998 14.1992 29.8998 16.2992 29.8998C18.9992 29.8998 21.7992 29.8998 24.4992 29.8998C29.8992 29.8998 35.1992 29.8998 40.5992 29.8998C42.6992 29.8998 42.6992 26.8998 40.6992 26.8998Z"
        fill={maincolor}
      />
      <path
        d="M40.6992 74.3002C32.0992 74.3002 23.4992 74.3002 14.9992 74.3002C14.6992 74.3002 14.0992 74.4002 13.8992 74.1002C13.6992 73.9002 13.7992 73.2002 13.7992 72.9002C13.7992 72.4002 13.5992 71.6002 13.7992 71.1002C14.0992 70.3002 16.0992 70.6002 16.7992 70.6002C22.1992 70.6002 27.5992 70.6002 32.9992 70.6002C35.2992 70.6002 37.5992 70.6002 39.8992 70.6002C40.3992 70.6002 40.8992 70.5002 41.0992 71.0002C41.2992 71.5002 41.0992 72.3002 41.0992 72.8002C41.0992 73.2002 41.2992 74.2002 40.6992 74.3002C38.7992 74.5002 38.7992 77.5002 40.6992 77.3002C42.9992 77.1002 44.0992 75.5002 44.0992 73.3002C44.0992 71.6002 44.2992 69.8002 42.9992 68.6002C41.6992 67.4002 39.3992 67.7002 37.7992 67.7002C31.6992 67.7002 25.4992 67.7002 19.3992 67.7002C17.6992 67.7002 15.9992 67.6002 14.2992 67.7002C12.3992 67.8002 10.8992 69.1002 10.7992 71.1002C10.6992 72.3002 10.5992 73.7002 10.8992 74.8002C11.5992 77.5002 14.1992 77.3002 16.2992 77.3002C18.9992 77.3002 21.7992 77.3002 24.4992 77.3002C29.8992 77.3002 35.1992 77.3002 40.5992 77.3002C42.6992 77.3002 42.6992 74.3002 40.6992 74.3002Z"
        fill={maincolor}
      />
      <path
        d="M83.9999 58.0998C73.6999 58.0998 63.3999 58.0998 53.1999 58.0998C40.3999 58.0998 27.5999 58.0998 14.7999 58.0998C13.9999 58.0998 12.8999 58.3998 12.8999 57.2998C12.7999 54.1998 12.8999 51.0998 12.8999 47.9998C12.8999 46.1998 12.8999 44.2998 12.8999 42.4998C12.8999 41.4998 12.7999 40.3998 12.8999 39.2998C12.9999 38.3998 14.1999 38.5998 14.9999 38.5998C27.5999 38.5998 40.1999 38.5998 52.6999 38.5998C64.2999 38.5998 75.8999 38.5998 87.4999 38.5998C87.8999 38.5998 88.0999 38.5998 88.4999 38.6998C90.2999 39.2998 91.0999 36.3998 89.2999 35.7998C88.1999 35.3998 86.8999 35.5998 85.7999 35.5998C83.5999 35.5998 81.3999 35.5998 79.0999 35.5998C71.9999 35.5998 64.8999 35.5998 57.7999 35.5998C43.9999 35.5998 30.1999 35.5998 16.3999 35.5998C15.3999 35.5998 14.3999 35.5998 13.3999 35.5998C11.3999 35.6998 9.99988 37.1998 9.89988 39.1998C9.89988 40.2998 9.89988 41.2998 9.89988 42.3998C9.89988 46.1998 9.89988 49.9998 9.89988 53.7998C9.89988 56.5998 9.39988 60.7998 13.3999 60.9998C16.7999 61.1998 20.1999 60.9998 23.4999 60.9998C30.9999 60.9998 38.4999 60.9998 45.9999 60.9998C58.4999 60.9998 70.8999 60.9998 83.3999 60.9998C83.5999 60.9998 83.7999 60.9998 84.0999 60.9998C85.8999 61.0998 85.8999 58.0998 83.9999 58.0998Z"
        fill={maincolor}
      />
      <path
        d="M40.6992 86.6C32.0992 86.6 23.4992 86.6 14.9992 86.6C14.6992 86.6 14.0992 86.7 13.8992 86.4C13.6992 86.2 13.7992 85.5 13.7992 85.2C13.7992 84.7 13.5992 83.9 13.7992 83.4C14.0992 82.6 16.0992 82.9 16.7992 82.9C22.1992 82.9 27.5992 82.9 32.9992 82.9C35.2992 82.9 37.5992 82.9 39.8992 82.9C40.3992 82.9 40.8992 82.8 41.0992 83.3C41.2992 83.8 41.0992 84.6 41.0992 85.1C41.0992 85.5 41.2992 86.5 40.6992 86.6C38.7992 86.8 38.7992 89.8 40.6992 89.6C42.9992 89.4 44.0992 87.8 44.0992 85.6C44.0992 83.9 44.2992 82.1 42.9992 80.9C41.6992 79.7 39.3992 80 37.7992 80C31.6992 80 25.4992 80 19.3992 80C17.6992 80 15.9992 79.9 14.2992 80C12.3992 80.1 10.8992 81.4 10.7992 83.4C10.6992 84.6 10.5992 86 10.8992 87.1C11.5992 89.8 14.1992 89.6 16.2992 89.6C18.9992 89.6 21.7992 89.6 24.4992 89.6C29.8992 89.6 35.1992 89.6 40.5992 89.6C42.6992 89.6 42.6992 86.6 40.6992 86.6Z"
        fill={maincolor}
      />
      <path
        d="M81.6994 74.3002C78.4994 74.3002 75.2994 74.3002 72.0994 74.3002C68.2994 74.3002 64.3994 74.5002 60.5994 74.3002C60.1994 74.3002 59.9994 74.3002 59.8994 73.8002C59.7994 73.4002 59.8994 72.8002 59.8994 72.4002C59.8994 72.0002 59.7994 71.5002 59.8994 71.1002C60.0994 70.3002 61.8994 70.7002 62.5994 70.7002C67.0994 70.7002 71.5994 70.7002 76.0994 70.7002C77.8994 70.7002 79.6994 70.6002 81.3994 70.7002C81.9994 70.7002 82.0994 70.8002 82.1994 71.5002C82.0994 71.9002 82.4994 74.2002 81.6994 74.3002C79.7994 74.5002 79.7994 77.5002 81.6994 77.3002C83.6994 77.1002 84.9994 75.8002 85.0994 73.8002C85.0994 72.7002 85.2994 71.4002 84.9994 70.4002C84.3994 67.9002 82.1994 67.7002 80.1994 67.7002C75.0994 67.7002 69.9994 67.7002 64.8994 67.7002C63.3994 67.7002 61.7994 67.6002 60.2994 67.7002C58.6994 67.8002 57.2994 68.7002 56.8994 70.4002C56.6994 71.4002 56.7994 72.7002 56.7994 73.8002C56.8994 75.7002 58.0994 77.2002 59.9994 77.4002C60.3994 77.4002 60.7994 77.4002 61.1994 77.4002C63.2994 77.4002 65.4994 77.4002 67.5994 77.4002C72.2994 77.4002 76.9994 77.4002 81.6994 77.4002C83.6994 77.3002 83.6994 74.3002 81.6994 74.3002Z"
        fill={maincolor}
      />
      <path
        d="M81.6994 86.6C78.4994 86.6 75.2994 86.6 72.0994 86.6C68.2994 86.6 64.3994 86.8 60.5994 86.6C60.1994 86.6 59.9994 86.6 59.8994 86.1C59.7994 85.7 59.8994 85.1 59.8994 84.7C59.8994 84.3 59.7994 83.8 59.8994 83.4C60.0994 82.6 61.8994 83 62.5994 83C67.0994 83 71.5994 83 76.0994 83C77.8994 83 79.6994 82.9 81.3994 83C81.9994 83 82.0994 83.1 82.1994 83.8C82.0994 84.2 82.4994 86.5 81.6994 86.6C79.7994 86.8 79.7994 89.8 81.6994 89.6C83.6994 89.4 84.9994 88.1 85.0994 86.1C85.0994 85 85.2994 83.7 84.9994 82.7C84.3994 80.2 82.1994 80 80.1994 80C75.0994 80 69.9994 80 64.8994 80C63.3994 80 61.7994 79.9 60.2994 80C58.6994 80.1 57.2994 81 56.8994 82.7C56.6994 83.7 56.7994 85 56.7994 86.1C56.8994 88 58.0994 89.5 59.9994 89.7C60.3994 89.7 60.7994 89.7 61.1994 89.7C63.2994 89.7 65.4994 89.7 67.5994 89.7C72.2994 89.7 76.9994 89.7 81.6994 89.7C83.6994 89.6 83.6994 86.6 81.6994 86.6Z"
        fill={maincolor}
      />
      <path
        d="M85.2 26.8998C83.4 26.8998 81.6 26.8998 79.8 26.8998C79.1 26.8998 78.3 26.8998 77.6 26.8998C77.5 26.8998 77.5 26.8998 77.4 26.8998C77.4 26.8998 77.6 26.8998 77.4 26.8998C77.2 26.7998 77.6 26.9998 77.4 26.8998C77.2 26.7998 77.5 26.9998 77.4 26.8998C77.4 26.8998 77.2 26.6998 77.3 26.7998C77.4 26.8998 77.2 26.5998 77.3 26.7998C77.4 26.8998 77.2 26.5998 77.3 26.7998C77.2 26.3998 77.3 25.9998 77.3 25.5998C77.3 25.0998 77.3 24.4998 77.3 23.9998C77.3 23.8998 77.3 23.5998 77.3 23.6998C77.3 23.6998 77.4 23.5998 77.3 23.5998C77.3 23.5998 77.4 23.3998 77.5 23.3998C77.4 23.3998 77.3 23.3998 77.6 23.3998C77.5 23.3998 77.3 23.3998 77.7 23.3998C77.8 23.3998 77.9 23.3998 78 23.3998C78.4 23.3998 78.8 23.3998 79.2 23.3998C81.2 23.3998 83.2 23.3998 85.2 23.3998C85.3 23.3998 85.6 23.3998 85.4 23.3998C85.4 23.3998 85.6 23.4998 85.5 23.3998C85.4 23.2998 85.7 23.4998 85.5 23.3998C85.6 23.4998 85.7 23.4998 85.7 23.5998C85.5 23.3998 85.9 23.7998 85.7 23.5998C85.7 23.5998 85.8 23.8998 85.7 23.5998C85.7 23.6998 85.7 23.6998 85.7 23.7998C85.7 23.8998 85.7 23.8998 85.7 23.9998C85.7 24.2998 85.7 24.4998 85.7 24.7998C85.7 25.3998 85.7 25.9998 85.7 26.4998V26.5998C85.7 26.5998 85.7 26.8998 85.7 26.6998V26.7998C85.7 26.6998 85.7 26.6998 85.7 26.7998C85.6 26.9998 85.8 26.6998 85.7 26.7998C85.7 26.7998 85.4 27.0998 85.6 26.8998C85.6 26.8998 85.4 27.0998 85.6 26.8998C85.4 26.8998 85.3 26.8998 85.2 26.8998C85.4 26.8998 85.4 26.8998 85.2 26.8998C84.4 26.8998 83.7 27.5998 83.7 28.3998C83.7 29.1998 84.4 29.8998 85.2 29.8998C86.5 29.7998 87.7 29.1998 88.2 27.9998C88.5 27.2998 88.6 26.5998 88.6 25.8998C88.6 24.3998 88.8 22.7998 87.8 21.4998C86.9 20.3998 85.6 20.2998 84.3 20.2998C83 20.2998 81.8 20.2998 80.5 20.2998C79.6 20.2998 78.6 20.2998 77.7 20.2998C75.6 20.3998 74.3 21.8998 74.3 23.8998C74.3 25.0998 74.1 26.4998 74.5 27.5998C75 28.8998 76.1 29.6998 77.5 29.7998C77.8 29.7998 78 29.7998 78.3 29.7998C80.6 29.7998 83 29.7998 85.3 29.7998C87.1 29.8998 87.1 26.8998 85.2 26.8998Z"
        fill={maincolor}
      />
      <path
        d="M88.5995 95.1C82.8995 95.1 77.2995 95.1 71.5995 95.1C60.1995 95.1 48.6995 95.1 37.2995 95.1C27.9995 95.1 18.6995 95.1 9.39954 95.1C7.79954 95.1 4.79954 95.6 3.69954 94.3C2.99954 93.4 2.99954 92.5 2.99954 91.6C2.99954 90.4 2.99954 89.3 2.99954 88.1C2.99954 78.2 2.99954 68.4 2.99954 58.5C2.99954 47.7 2.99954 36.9 2.99954 26C2.99954 23.3 2.99954 20.7 2.99954 18C2.99954 16.4 3.69954 15.1 5.49954 15C5.99954 15 6.49954 15 6.99954 15C15.6995 15 24.3995 15 32.9995 15C44.1995 15 55.2995 15 66.4995 15C72.0995 15 77.5995 15 83.1995 15C85.2995 15 87.3995 15 89.5995 15C91.4995 15 91.4995 12 89.5995 12C83.6995 12 77.8995 12 71.9995 12C61.4995 12 50.9995 12 40.4995 12C30.2995 12 20.0995 12 9.89954 12C7.69954 12 4.69954 11.6 2.69954 12.8C-0.300456 14.6 -0.000455732 17.8 -0.000455732 20.8C-0.000455732 30.5 -0.000455732 40.1 -0.000455732 49.8C-0.000455732 61 -0.000455732 72.1 -0.000455732 83.3C-0.000455732 86.2 -0.000455732 89 -0.000455732 91.9C-0.000455732 93.4 0.299544 94.9 1.29954 96.1C2.49954 97.5 4.19954 98.1 5.99954 98.1C13.2995 98.1 20.4995 98.1 27.7995 98.1C39.5995 98.1 51.4995 98.1 63.2995 98.1C71.2995 98.1 79.3995 98.1 87.3995 98.1C87.7995 98.1 88.1995 98.1 88.5995 98.1C90.4995 98.1 90.4995 95.1 88.5995 95.1Z"
        fill={maincolor}
      />
      <path
        d="M91.0998 13.6002C90.9998 8.7002 94.0998 4.3002 98.8998 3.2002C103.3 2.3002 107.8 4.4002 109.9 8.4002C112.1 12.5002 111 17.8002 107.4 20.7002C105.7 22.1002 103.6 22.8002 101.4 23.0002C99.3998 23.1002 97.9998 24.1002 97.9998 26.2002C97.8998 29.0002 97.9998 31.8002 97.9998 34.5002C97.9998 36.4002 101 36.4002 101 34.5002C101 33.3002 101 32.1002 101 30.8002C101 29.9002 101 29.0002 101 28.0002C101 27.5002 100.8 26.6002 101 26.1002C101 26.0002 101 25.9002 101 25.8002C101.1 25.7002 102 25.8002 102.1 25.8002C103.5 25.7002 104.8 25.4002 106.1 24.8002C108.5 23.8002 110.5 22.1002 111.9 20.0002C114.8 15.6002 114.7 9.7002 111.7 5.4002C108.6 1.1002 103.3 -0.899797 98.0998 0.200203C91.9998 1.5002 87.8998 7.3002 88.0998 13.5002C88.0998 15.5002 91.0998 15.5002 91.0998 13.6002Z"
        fill={maincolor}
      />
      <path
        d="M87.0996 95.0002C87.1996 100.5 90.5996 105.4 95.7996 107.3C101.1 109.2 107.1 107.2 110.5 102.8C114 98.2002 113.9 91.7002 110.4 87.1002C109.5 86.0002 108.5 85.0002 107.4 84.2002C106.2 83.4002 104.8 83.0002 103.7 82.2002C102.1 81.0002 101.5 79.2002 101.4 77.3002C101.3 74.0002 101.2 70.7002 101.1 67.5002C101 65.6002 97.9996 65.6002 98.0996 67.5002C98.1996 70.2002 98.2996 72.9002 98.3996 75.5002C98.3996 76.5002 98.3996 77.6002 98.4996 78.6002C98.7996 81.2002 100.2 83.6002 102.5 85.0002C103.5 85.6002 104.6 86.0002 105.6 86.6002C106.6 87.3002 107.6 88.1002 108.3 89.1002C109.8 91.2002 110.5 93.8002 110.1 96.4002C109.4 101.2 105.1 104.9 100.3 105C94.6996 105 90.2996 100.5 90.1996 95.0002C90.0996 93.0002 87.0996 93.0002 87.0996 95.0002Z"
        fill={maincolor}
      />
      <path
        d="M113.1 50.9999C113 57.1999 109.2 62.8999 103.4 65.0999C97.4 67.2999 90.6 65.4999 86.6 60.5999C82.6 55.7999 82.1 48.6999 85.3 43.3999C88.5 38.0999 94.9 35.1999 101 36.3999C108 37.5999 113 43.7999 113.1 50.9999C113.1 52.8999 116.1 52.8999 116.1 50.9999C116 43.4999 111.4 36.6999 104.3 34.0999C97.3 31.4999 89 33.6999 84.3 39.4999C79.5 45.2999 78.8 53.6999 82.7 60.1999C86.6 66.6999 94.2 69.9999 101.6 68.5999C110 66.9999 116 59.3999 116.1 50.9999C116.1 48.9999 113.1 48.9999 113.1 50.9999Z"
        fill={maincolor}
      />
      <path
        d="M88.1003 13.6002C88.1003 14.4002 88.3003 15.2002 88.4003 16.0002C88.6003 16.8002 88.9003 17.5002 89.2003 18.3002C89.3003 18.6002 89.8003 18.9002 90.1003 19.0002C90.5003 19.1002 90.9003 19.1002 91.3003 18.8002C91.6003 18.6002 91.9003 18.3002 92.0003 17.9002C92.0003 17.8002 92.0003 17.6002 92.1003 17.5002C92.1003 17.2002 92.0003 17.0002 91.9003 16.7002C91.9003 16.7002 91.9003 16.7002 91.9003 16.6002C92.0003 16.7002 92.0003 16.8002 92.1003 17.0002C91.7003 16.1002 91.4003 15.1002 91.3003 14.1002C91.3003 14.2002 91.3003 14.4002 91.4003 14.5002C91.4003 14.2002 91.3003 13.9002 91.3003 13.6002C91.3003 13.2002 91.1003 12.8002 90.9003 12.5002C90.6003 12.2002 90.2003 12.0002 89.8003 12.1002C88.8003 12.1002 88.0003 12.7002 88.1003 13.6002Z"
        fill={maincolor}
      />
      <path
        d="M90.0996 94.9998C90.0996 93.0998 90.5996 91.3998 91.5996 89.7998C91.6996 89.5998 91.8996 89.3998 91.9996 89.1998C92.0996 89.0998 92.0996 88.9998 92.1996 88.8998C92.0996 89.0998 92.1996 88.8998 92.2996 88.7998C92.5996 88.3998 92.8996 88.0998 93.2996 87.6998C93.9996 86.9998 94.4996 86.6998 95.4996 86.0998C96.1996 85.6998 96.3996 84.6998 95.9996 83.9998C95.5996 83.2998 94.6996 83.0998 93.8996 83.4998C89.6996 85.7998 87.0996 90.1998 87.0996 94.8998C87.0996 95.6998 87.7996 96.3998 88.5996 96.3998C89.3996 96.4998 90.0996 95.7998 90.0996 94.9998Z"
        fill={maincolor}
      />
      <path
        d="M103.5 8.40017C101.5 10.4002 99.3998 12.5002 97.3998 14.5002C97.0998 14.8002 96.7998 15.1002 96.4998 15.4002C95.8998 16.0002 95.8998 17.0002 96.4998 17.5002C97.0998 18.0002 97.9998 18.1002 98.5998 17.5002C100.6 15.5002 102.7 13.4002 104.7 11.4002C105 11.1002 105.3 10.8002 105.6 10.5002C106.2 9.90017 106.2 8.90017 105.6 8.40017C105 7.90017 104.1 7.80017 103.5 8.40017Z"
        fill={maincolor}
      />
      <path
        d="M96.5003 10.5002C98.5003 12.5002 100.6 14.6002 102.6 16.6002C102.9 16.9002 103.2 17.2002 103.5 17.5002C104.1 18.1002 105.1 18.1002 105.6 17.5002C106.1 16.9002 106.2 16.0002 105.6 15.4002C103.6 13.4002 101.5 11.3002 99.5003 9.3002C99.2003 9.0002 98.9003 8.7002 98.6003 8.4002C98.0003 7.8002 97.0003 7.8002 96.5003 8.4002C96.0003 9.0002 95.9003 9.9002 96.5003 10.5002Z"
        fill={maincolor}
      />
      <path
        d="M102.5 90.4002C100.5 92.4002 98.3998 94.5002 96.3998 96.5002C96.0998 96.8002 95.7998 97.1002 95.4998 97.4002C94.8998 98.0002 94.8998 99.0002 95.4998 99.5002C96.0998 100 96.9998 100.1 97.5998 99.5002C99.5998 97.5002 101.7 95.4002 103.7 93.4002C104 93.1002 104.3 92.8002 104.6 92.5002C105.2 91.9002 105.2 90.9002 104.6 90.4002C104 89.9002 103.1 89.8002 102.5 90.4002Z"
        fill={maincolor}
      />
      <path
        d="M95.5003 92.5002C97.5003 94.5002 99.6003 96.6002 101.6 98.6002C101.9 98.9002 102.2 99.2002 102.5 99.5002C103.1 100.1 104.1 100.1 104.6 99.5002C105.1 98.9002 105.2 98.0002 104.6 97.4002C102.6 95.4002 100.5 93.3002 98.5003 91.3002C98.2003 91.0002 97.9003 90.7002 97.6003 90.4002C97.0003 89.8002 96.0003 89.8002 95.5003 90.4002C95.0003 91.0002 94.9003 91.9002 95.5003 92.5002Z"
        fill={maincolor}
      />
      <path
        d="M102.7 44.2001C99.3997 47.5001 96.0997 50.8001 92.7997 54.1001C92.2997 54.6001 91.8997 55.0001 91.3997 55.5001C89.9997 56.9001 92.1997 59.0001 93.4997 57.6001C96.7997 54.3001 100.1 51.0001 103.4 47.7001C103.9 47.2001 104.3 46.8001 104.8 46.3001C106.2 45.0001 104.1 42.9001 102.7 44.2001Z"
        fill={maincolor}
      />
      <path
        d="M91.4002 46.4001C94.7002 49.7001 98.0002 53.0001 101.3 56.3001C101.8 56.8001 102.2 57.2001 102.7 57.7001C104.1 59.1001 106.2 56.9001 104.8 55.6001C101.5 52.3001 98.2002 49.0001 94.9002 45.7001C94.4002 45.2001 94.0002 44.8001 93.5002 44.3001C92.1002 42.9001 90.0002 45.0001 91.4002 46.4001Z"
        fill={maincolor}
      />
    </svg>
  );
};

EmptyIntegrationsSvg.propTypes = {
  bgcolor1: PropTypes.string,
  maincolor: PropTypes.string,
  uid: PropTypes.number,
  highlightClass: PropTypes.string
};

const EmptyIntegrations = props => (
  <Illustration {...props}>
    <EmptyIntegrationsSvg />
  </Illustration>
);

export default EmptyIntegrations;
