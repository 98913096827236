import styled from "styled-components";

const AttachmentList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export default AttachmentList;
