import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import FullPageError from "Components/FullPageError";
import Button from "UI/Button";
import useDecodedParams from "Hooks/useDecodedParams";

import {
  organizationLoadingSelector,
  organizationLoadingErrorSelector
} from "Reducers/organization";
import { subscriptionsLoadingErrorsSelector } from "Reducers/organization/subscription";

const OrganizationError = ({ children }) => {
  const intl = useIntl();
  const { organizationId } = useDecodedParams();
  const error = useSelector(state => {
    let error = organizationLoadingErrorSelector(state);

    if (!error) {
      error = subscriptionsLoadingErrorsSelector(state, { organizationId });
    }

    return error?.toJS ? error.toJS() : error;
  });
  const isLoading = useSelector(organizationLoadingSelector);

  if (!isLoading && error) {
    let errorText = error;
    if (typeof error === "string") {
      try {
        errorText = JSON.parse(error);
      } catch (_) {
        errorText = error;
      }
    }
    let errorTitle = intl.formatMessage({ id: "error_loading_organization" });
    let errorMessage = intl.formatMessage({
      id: "error_loading_organization_description"
    });

    if (errorText && (errorText.status || errorText.code)) {
      if (errorText.status === 403 || errorText.code === 403) {
        errorTitle = intl.formatMessage({ id: "error_403_title" });
        errorMessage = intl.formatMessage({ id: "error_organization_403" });
      }
      if (errorText.status === 404 || errorText.code === 404) {
        errorTitle = intl.formatMessage({ id: "error_loading_organization" });
        errorMessage = intl.formatMessage({ id: "error_organization_404" });
      }

      if (errorText.status === 502 || errorText.code === 502) {
        errorTitle = intl.formatMessage({ id: "error_loading_organization" });
        errorMessage = intl.formatMessage({ id: "error_organization_502" });
      }
    } else if (
      errorText &&
      errorText.toString &&
      errorText.toString().indexOf("502 Bad Gateway") !== -1
    ) {
      errorTitle = intl.formatMessage({ id: "error_loading_organization" });
      errorMessage = intl.formatMessage({ id: "error_organization_502" });
    }

    return (
      <FullPageError title={errorTitle} message={errorMessage}>
        <div className="actions">
          <Button
            variant="primary"
            as={Link}
            to="/"
            aria-label="Return to projects"
          >
            Return to projects
          </Button>
        </div>
      </FullPageError>
    );
  }

  return children;
};

OrganizationError.propTypes = {
  children: PropTypes.node
};

export default OrganizationError;
