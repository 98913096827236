import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const IconFilter = () => {
  const uid = useUniqueId();

  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`filter-icon-${uid}`}
    >
      <title id={`filter-icon-${uid}`}>Filter icon</title>
      <g fill="currentColor">
        <rect width="16" height="2" rx="1" />
        <rect x="2" y="5" width="12" height="2" rx="1" />
        <rect x="5" y="10" width="6" height="2" rx="1" />
      </g>
    </svg>
  );
};

IconFilter.propTypes = {
  color: PropTypes.string
};

export default IconFilter;
