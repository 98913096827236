import React from "react";
import PropTypes from "prop-types";

import Tabs from "../SubNavBar/Tabs";
import * as S from "./styles";

const SideNavBar = ({ currentPathName, tabs }) => {
  return (
    <S.Wrapper>
      {tabs.length > 0 && (
        <S.TabsWrapper>
          <Tabs currentPathName={currentPathName} tabs={tabs} />
        </S.TabsWrapper>
      )}
    </S.Wrapper>
  );
};

SideNavBar.propTypes = {
  currentPathName: PropTypes.string.isRequired,
  tabs: PropTypes.array
};

export default SideNavBar;
