import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import Error from "../Error";
import Label from "../fields/Label";
import Chevron from "../icons/Chevron";
import RequiredTag from "../fields/RequiredTag";

import { DropdownLayout, InputLayout } from "./styles";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.arrowRenderer = this.arrowRenderer.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.isRequired = this.isRequired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setValue = this.setValue.bind(this);
    this.state = {
      isRequire: false
    };
    this.ref = React.createRef();
  }

  isRequired() {
    const { defaultValue, required, value } = this.props;
    this.setState({
      isRequired:
        required && !(value || defaultValue || this.state.selectedValue)
    });
  }

  getIndex(array, value) {
    return array.findIndex(i => (i.value = value));
  }

  arrowRenderer() {
    return <Chevron />;
  }

  handleChange(e) {
    this.setState({ selectedValue: e });
    this.props.onChange(e);
  }

  setValue(option) {
    this.ref.current?.select?.selectOption(option);
  }

  render() {
    return (
      <DropdownLayout
        className={`dropdown${
          this.props.className ? " " + this.props.className : ""
        }${this.props.disabled ? " is-disabled" : ""}${
          this.state.isRequired &&
          !this.props.isChanged &&
          !this.props.defaultValue
            ? " field-error"
            : ""
        }`}
      >
        <InputLayout
          id={this.props.label && this.props.label.replace(" ", "-")}
          className="input-wrapper"
        >
          {this.props.htmlLabel}
          {!this.props.htmlLabel &&
            this.props.label && (
              <Label
                htmlFor={`${this.props.label &&
                  this.props.label.replace(" ", "-")}-options`}
              >
                {this.props.label}
                {this.props.required ? <RequiredTag /> : ""}
              </Label>
            )}
          <Select
            {...this.props}
            inputProps={{
              id: `${this.props.label &&
                this.props.label.replace(" ", "-")}-options`
            }}
            tabIndex="0"
            aria-label={this.props.label}
            arrowRenderer={this.arrowRenderer}
            className="select-box"
            classNamePrefix="select-box"
            defaultValue={this.props.defaultValue}
            placeholder={
              this.props && this.props.defaultText
                ? this.props.defaultText
                : this.props && this.props.value
                  ? this.props.options[
                      this.getIndex(this.props.options, this.props.value)
                    ].label
                  : "Select..."
            }
            isSearchable={this.props.searchable}
            onChange={this.handleChange}
            controlShouldRenderValue={true}
            required={this.props.required}
            isDisabled={this.props.disabled}
            onBlur={this.isRequired}
            ref={this.ref}
          />
          {this.state.isRequired && (
            <div className="required-message">
              {this.props.label} is required.
            </div>
          )}
        </InputLayout>
        {this.props.error && <Error>{this.props.error}</Error>}
      </DropdownLayout>
    );
  }
}

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  error: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  defaultText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isChanged: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  htmlLabel: PropTypes.node,
  searchable: PropTypes.bool
};

export default Dropdown;
