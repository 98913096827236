import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const Illustration = ({
  theme,
  children,
  highlightClassname,
  ...otherProps
}) => {
  const uid = useUniqueId();

  const colors = {
    almostBlack: "#1E1E21",
    darkGray: "#5F5E70",
    gray: "#2F2E49",
    lightGray: "#EAE5E7",
    pink: "#FFC9C6",
    yellow: "#FFC107",
    white: "#FFFFFF"
  };

  let maincolor = colors.almostBlack;
  let bgcolor1 = colors.lightGray;
  let bgcolor2 = colors.lightGray;
  let bgcolor3 = colors.yellow;
  let bgcolor4 = colors.white;
  let bgcolor5 = colors.pink;

  let highlightClass = highlightClassname || "highlight";

  if (theme?.name === "dark") {
    maincolor = colors.white;
    bgcolor1 = colors.darkGray;
    bgcolor2 = colors.gray;
    bgcolor4 = colors.darkGray;
  }

  // Disable background shapes and do not pass a highlightClass
  // the highlightClass is used to control background shapes
  // from an ancestor using CSS (i.e. showing them when a card is hovered)
  if (process.env.ENABLE_UNBRANDED_ILLUSTRATIONS) {
    bgcolor1 = "transparent";
    bgcolor2 = "transparent";
    bgcolor3 = "transparent";
    bgcolor4 = "transparent";
    bgcolor5 = "transparent";
    highlightClass = "";
  }

  return (
    <React.Fragment>
      {React.cloneElement(children, {
        uid,
        maincolor,
        bgcolor1,
        bgcolor2,
        bgcolor3,
        bgcolor4,
        bgcolor5,
        highlightClass,
        ...otherProps
      })}
    </React.Fragment>
  );
};

Illustration.propTypes = {
  children: PropTypes.node,
  highlightClassname: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(Illustration);
