import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyServicesSvg = ({
  maincolor,
  bgcolor1,
  bgcolor2,
  uid,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="140"
      height="108"
      viewBox="0 0 140 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-services-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-services-illustration-${uid}`}>Empty services</title>
      <path d="M70 28L30 52L20 23L45 28L61 4L70 28Z" fill={bgcolor1} />
      <path
        d="M99 72L87 48L100 16L119 28L110 36L126 80L90 99L99 72Z"
        fill={bgcolor1}
      />
      <path
        d="M57.5 95C68.2696 95 77 86.2696 77 75.5C77 64.7304 68.2696 56 57.5 56C46.7304 56 38 64.7304 38 75.5C38 86.2696 46.7304 95 57.5 95Z"
        fill={bgcolor2}
      />
      <path
        d="M75.17 90.33C74.82 96.95 64.83 97.01 64.83 90.33C64.83 83.65 74.83 83.7 75.17 90.33C75.28 92.25 78.28 92.26 78.17 90.33C78.1724 88.729 77.7043 87.1625 76.8238 85.8253C75.9434 84.488 74.6895 83.4389 73.2178 82.8083C71.7462 82.1777 70.1217 81.9934 68.5462 82.2783C66.9707 82.5632 65.5136 83.3048 64.356 84.4108C63.1984 85.5168 62.3913 86.9386 62.035 88.4995C61.6786 90.0604 61.7887 91.6916 62.3516 93.1905C62.9146 94.6893 63.9055 95.9897 65.2012 96.9301C66.497 97.8705 68.0405 98.4095 69.64 98.48C70.7363 98.5092 71.8276 98.3212 72.8509 97.9267C73.8742 97.5321 74.8092 96.939 75.6022 96.1813C76.3951 95.4237 77.0303 94.5166 77.471 93.5124C77.9117 92.5081 78.1493 91.4265 78.17 90.33C78.28 88.4 75.28 88.4 75.17 90.33Z"
        fill={maincolor}
      />
      <path
        d="M35.8 90.33C35.44 96.95 25.45 97.01 25.45 90.33C25.45 83.65 35.45 83.7 35.8 90.33C35.9 92.25 38.9 92.26 38.8 90.33C38.8044 88.7266 38.3373 87.1572 37.457 85.817C36.5767 84.4768 35.3219 83.4249 33.8486 82.792C32.3753 82.1592 30.7486 81.9732 29.1705 82.2574C27.5924 82.5416 26.1326 83.2832 24.9726 84.3903C23.8126 85.4973 23.0036 86.9208 22.646 88.4839C22.2885 90.047 22.3982 91.6807 22.9616 93.1819C23.5249 94.6831 24.5171 95.9857 25.8147 96.9277C27.1123 97.8696 28.6581 98.4094 30.26 98.48C35.02 98.69 38.55 94.91 38.8 90.33C38.9 88.4 35.9 88.4 35.8 90.33Z"
        fill={maincolor}
      />
      <path
        d="M114.55 90.3299C114.2 96.9499 104.2 97.0099 104.2 90.3299C104.2 83.6499 114.2 83.6999 114.55 90.3299C114.66 92.2499 117.66 92.2599 117.55 90.3299C117.31 85.8899 114 82.1499 109.38 82.1499C107.765 82.1499 106.185 82.6288 104.842 83.5261C103.499 84.4234 102.451 85.6988 101.833 87.1911C101.214 88.6834 101.052 90.3256 101.366 91.9101C101.681 93.4947 102.458 94.9504 103.599 96.0934C104.741 97.2364 106.196 98.0153 107.78 98.3317C109.364 98.6481 111.007 98.4877 112.5 97.8709C113.993 97.254 115.269 96.2084 116.168 94.8662C117.067 93.5239 117.548 91.9453 117.55 90.3299C117.66 88.3999 114.66 88.3999 114.55 90.3299Z"
        fill={maincolor}
      />
      <path
        d="M71.5 77.07V59.29C71.5 55.2 73.19 46.58 68.96 43.92C66.74 42.53 63.96 42.48 61.69 40.92C59.798 39.6146 58.2426 37.8791 57.1517 35.8558C56.0608 33.8326 55.4655 31.5794 55.4147 29.2813C55.3639 26.9833 55.859 24.706 56.8594 22.6365C57.8598 20.567 59.3369 18.7643 61.1693 17.3766C63.0018 15.989 65.1374 15.0558 67.4006 14.6539C69.6638 14.252 71.99 14.3928 74.1882 15.0646C76.3865 15.7365 78.3939 16.9203 80.0457 18.5188C81.6975 20.1174 82.9464 22.0849 83.69 24.26C86.26 31.69 82.34 39.18 75.49 42.45C73.75 43.28 75.27 45.87 77.01 45.04C86.01 40.72 90.24 29.86 85.43 20.78C84.328 18.6523 82.8011 16.7734 80.9437 15.2596C79.0863 13.7459 76.938 12.6294 74.6318 11.9794C72.3255 11.3294 69.9104 11.1596 67.5359 11.4806C65.1614 11.8016 62.878 12.6065 60.8271 13.8455C58.7762 15.0846 57.0015 16.7313 55.6127 18.6839C54.2239 20.6365 53.2506 22.8534 52.7531 25.1973C52.2557 27.5412 52.2445 29.9622 52.7204 32.3106C53.1964 34.659 54.1492 36.8847 55.52 38.85C56.7658 40.6825 58.3428 42.2663 60.17 43.52C62.41 45.04 65.92 45.37 67.55 47.34C69.18 49.31 68.55 52.57 68.55 55.13V77.07C68.55 77.4678 68.708 77.8493 68.9893 78.1306C69.2706 78.4119 69.6522 78.57 70.05 78.57C70.4478 78.57 70.8293 78.4119 71.1106 78.1306C71.3919 77.8493 71.55 77.4678 71.55 77.07H71.5Z"
        fill={maincolor}
      />
      <path
        d="M32.12 77.0699C32.12 73.7799 31.46 69.9099 35.52 69.2299C37.82 68.8399 40.52 69.2299 42.84 69.2299H97.16C99.48 69.2299 102.16 68.8399 104.48 69.2299C108.54 69.9099 107.88 73.7799 107.88 77.0699C107.88 77.4677 108.038 77.8493 108.319 78.1306C108.601 78.4119 108.982 78.5699 109.38 78.5699C109.778 78.5699 110.159 78.4119 110.441 78.1306C110.722 77.8493 110.88 77.4677 110.88 77.0699C110.88 71.6099 111.1 66.4299 104.34 66.2299C98.22 66.0499 92.07 66.2299 85.96 66.2299H37.07C35.32 66.2299 33.52 66.0499 31.92 66.8899C28.23 68.8899 29.12 73.6099 29.12 77.0699C29.12 77.4677 29.278 77.8493 29.5593 78.1306C29.8406 78.4119 30.2222 78.5699 30.62 78.5699C31.0178 78.5699 31.3994 78.4119 31.6807 78.1306C31.962 77.8493 32.12 77.4677 32.12 77.0699Z"
        fill={maincolor}
      />
    </svg>
  );
};

EmptyServicesSvg.propTypes = {
  bgcolor1: PropTypes.string,
  bgcolor2: PropTypes.string,
  maincolor: PropTypes.string,
  uid: PropTypes.number,
  highlightClass: PropTypes.string
};

const EmptyServices = props => (
  <Illustration {...props}>
    <EmptyServicesSvg />
  </Illustration>
);

export default EmptyServices;
