import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const title = process.env.WINDOW_TITLE || "Platform.sh";

const PageMeta = props => (
  <Helmet>
    <title>
      {props.title ? props.title + " | " : ""}
      {title}
    </title>
  </Helmet>
);

PageMeta.propTypes = {
  title: PropTypes.string
};

export default PageMeta;
