import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { STATUSES } from "../../config";

import { Layout } from "./styles";

const Status = ({ status, ...props }) => (
  <Layout status={status} {...props}>
    {STATUSES[status] ? (
      <FormattedMessage id={`tickets.status.${STATUSES[status].id}.label`} />
    ) : (
      status
    )}
  </Layout>
);

Status.propTypes = {
  status: PropTypes.string,
  plain: PropTypes.bool
};

export default Status;
