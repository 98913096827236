import styled from "styled-components";

import * as SNavBar from "Components/SubNavBar/styles";

export const Wrapper = styled.div`
  > div {
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      > h1 {
        padding: 20px 0 30px;
        color: #fff;
      }
    }
  }
  .title-wrapper {
    min-height: 74px;
  }
  h1 {
    color: #fff;
  }
  .logo {
    height: 40px;
    width: auto;
    margin-right: 20px;
  }

  @media (min-width: 768px) {
    ${SNavBar.SubNavBarTabWrapper} {
      padding-left: 25px;
    }
  }
`;
