import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LabeledInfo from "Components/LabeledInfo";
import CloseBtn from "Components/CloseBtn";
import { AppInfos, RouteInfos, ServiceInfos } from "../../../../components/";

import * as S from "./styles";

const DetailDialog = ({
  kind,
  metadata = {},
  onClick,
  onClose,
  transform,
  workers
}) => {
  const intl = useIntl();

  let InfoComponent;

  switch (kind) {
    case "app":
      InfoComponent = AppInfos;
      break;
    case "service":
      InfoComponent = ServiceInfos;
      break;
    default:
      InfoComponent = RouteInfos;
  }

  const version = metadata.type && metadata.type.split(":")[1];

  return (
    <S.Wrapper transform={transform}>
      <S.TitleBar>
        <LabeledInfo
          value={metadata.name || "router"}
          label={version ? `Version ${version}` : ""}
        />
        <CloseBtn onClick={onClose} />
      </S.TitleBar>
      <S.Grid>
        <InfoComponent metadata={metadata} workers={workers} />
      </S.Grid>
      <S.Link tabIndex="0" onClick={() => onClick(metadata)}>
        {intl.formatMessage({ id: "view_details" })}
      </S.Link>
    </S.Wrapper>
  );
};

DetailDialog.propTypes = {
  kind: PropTypes.string,
  metadata: PropTypes.object,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  transform: PropTypes.string,
  workers: PropTypes.number
};

export default DetailDialog;
