import styled from "styled-components";

import Header from "../Header";

import { breakpoints } from "Libs/theme";

export const TableFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TableHeading = styled(Header)`
  margin-bottom: 0;
  font-size: 18px;

  @media (min-width: ${breakpoints[1]}) {
    font-size: 20px;
    flex-grow: 1;
  }
`;
