import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyProjectsSvg = ({
  uid,
  maincolor,
  bgcolor1,
  bgcolor2,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="140"
      height="108"
      viewBox="0 0 140 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-projects-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-projects-illustration-${uid}`}>Empty projects</title>
      <path
        d="M45.64 77.47L59.07 77.5L79 95.7L50 101L45.64 77.47Z"
        fill={bgcolor1}
      />
      <path
        d="M19 27L11 48L32 63L43 64L54 53L68 24L61 2L48 6L47 33L19 27Z"
        fill={bgcolor1}
      />
      <path
        d="M104.25 93.5C118.747 93.5 130.5 81.7475 130.5 67.25C130.5 52.7525 118.747 41 104.25 41C89.7525 41 78 52.7525 78 67.25C78 81.7475 89.7525 93.5 104.25 93.5Z"
        fill={bgcolor2}
      />
      <path
        d="M115.05 76.98C116.425 76.98 117.54 75.8652 117.54 74.49C117.54 73.1148 116.425 72 115.05 72C113.675 72 112.56 73.1148 112.56 74.49C112.56 75.8652 113.675 76.98 115.05 76.98Z"
        fill={maincolor}
      />
      <path
        d="M126.05 76.98C127.425 76.98 128.54 75.8652 128.54 74.49C128.54 73.1148 127.425 72 126.05 72C124.675 72 123.56 73.1148 123.56 74.49C123.56 75.8652 124.675 76.98 126.05 76.98Z"
        fill={maincolor}
      />
      <path
        d="M103.31 86.8401C99.01 87.2701 94.55 86.9601 90.24 86.9601H38.78C33.39 86.9601 27.9 87.3201 27.5 80.1401C26.63 64.4901 27.5 48.5601 27.5 32.8801C27.5 30.1701 27.14 27.4401 29.23 25.3401C31.86 22.7101 37.07 23.6101 40.51 23.6101C44.51 23.6101 48.7 23.2601 52.7 23.6101C57.79 24.0501 59.33 27.4701 62.06 31.1601C66.78 37.5301 74.68 35.8301 81.76 35.8301H105.5C105.898 35.8301 106.279 35.6721 106.561 35.3908C106.842 35.1095 107 34.7279 107 34.3301C107 33.9323 106.842 33.5508 106.561 33.2695C106.279 32.9882 105.898 32.8301 105.5 32.8301H83.2C79.2 32.8301 75.2 33.0401 71.2 32.8301C66.38 32.5801 64.55 29.4901 62 25.9201C59.72 22.7301 57.43 20.7601 53.32 20.6101C49.21 20.4601 45.09 20.6101 40.99 20.6101C33.99 20.6101 25.08 19.8201 24.51 29.3101C23.51 45.3101 24.51 61.6501 24.51 77.6801C24.51 83.7901 25.86 89.4601 33.22 89.9601C39.17 90.3601 45.28 89.9601 51.22 89.9601H84.57C90.75 89.9601 97.15 90.4601 103.31 89.8401C105.21 89.6501 105.23 86.6501 103.31 86.8401Z"
        fill={maincolor}
      />
      <path
        d="M65.36 25.87H86.22C93.32 25.87 100.93 25.07 107.99 25.87C114.14 26.57 113.55 32.14 113.55 36.7V50.49C113.55 54.18 112.77 58.23 115.93 60.91C118.68 63.24 121.93 62.6 125.1 63.72C127.281 64.5089 129.239 65.8142 130.807 67.5242C132.374 69.2343 133.504 71.2983 134.1 73.54C136.89 84.19 126.5 93.89 116.1 90.66C104.28 87 103.15 71.52 112.85 65C114.45 63.93 112.95 61.33 111.34 62.41C97.59 71.66 102.77 94.21 120.18 94.32C136.36 94.42 143.52 73.32 130.73 63.45C127.33 60.83 123.83 61.03 120.08 59.54C116.01 57.93 116.55 53.36 116.55 49.71V34.39C116.55 29.18 115.37 23.98 109.32 22.98C106.08 22.44 102.5 22.87 99.25 22.87H65.36C64.9622 22.87 64.5806 23.0281 64.2993 23.3094C64.018 23.5907 63.86 23.9722 63.86 24.37C63.86 24.7678 64.018 25.1494 64.2993 25.4307C64.5806 25.712 64.9622 25.87 65.36 25.87Z"
        fill={maincolor}
      />
      <path
        d="M88.86 67.7499H58.5C56.84 67.7499 53.82 68.3299 52.23 67.7499C49.31 66.6899 49.51 58.4299 50.23 55.9899C51.23 52.5299 53.87 53.2899 56.7 53.2899H81.35C83.99 53.2899 87.94 52.3899 89.84 54.3399C91.74 56.2899 92.17 67.1999 88.46 67.7999C86.56 68.1099 87.36 70.9999 89.26 70.6999C94.58 69.8399 93.85 64.6299 93.85 60.5199C93.85 56.4099 94.59 51.1999 89.26 50.3399C86.46 49.8999 83.33 50.2899 80.51 50.2899H63.21C59.65 50.2899 55.88 49.9299 52.33 50.2899C47.92 50.7399 47.2 53.9299 47.2 57.7099C47.2 61.1999 45.92 67.4999 49.37 69.8699C51.62 71.4199 55.58 70.7499 58.11 70.7499H88.86C89.2578 70.7499 89.6394 70.5918 89.9207 70.3105C90.202 70.0292 90.36 69.6477 90.36 69.2499C90.36 68.8521 90.202 68.4705 89.9207 68.1892C89.6394 67.9079 89.2578 67.7499 88.86 67.7499Z"
        fill={maincolor}
      />
      <path
        d="M118.43 84.1498C118.646 83.6033 119.021 83.1343 119.507 82.8038C119.993 82.4733 120.567 82.2965 121.155 82.2965C121.743 82.2965 122.317 82.4733 122.803 82.8038C123.289 83.1343 123.664 83.6033 123.88 84.1498C124.72 85.8798 127.31 84.3598 126.47 82.6298C125.985 81.6328 125.23 80.7922 124.29 80.2041C123.35 79.6161 122.264 79.3042 121.155 79.3042C120.046 79.3042 118.96 79.6161 118.02 80.2041C117.08 80.7922 116.325 81.6328 115.84 82.6298C115.01 84.3598 117.59 85.8798 118.43 84.1398V84.1498Z"
        fill={maincolor}
      />
    </svg>
  );
};

EmptyProjectsSvg.propTypes = {
  bgcolor1: PropTypes.string,
  bgcolor2: PropTypes.string,
  maincolor: PropTypes.string,
  highlightClass: PropTypes.string,
  uid: PropTypes.number
};

const EmptyProjects = props => (
  <Illustration {...props}>
    <EmptyProjectsSvg />
  </Illustration>
);

export default EmptyProjects;
