import React from "react";

import { Skeleton, Square, Card } from "Components/Skeleton";

import * as S from "./ProjectsSkeleton.styles";

const ProjectsSkeleton = () => (
  <div>
    <S.Filters>
      <Skeleton synchronize>
        <Square height="24px" width="100px" fixedWidth />
      </Skeleton>
      <Skeleton synchronize>
        <Square height="32px" width="200px" fixedWidth />
      </Skeleton>
    </S.Filters>
    <S.Grid synchronize>
      {Array.from(new Array(6)).map((value, index) => (
        <Card key={index}>
          <Square height="180px" width="100%" margin="0 0 15px 0" />
          <S.Column>
            <Square height="19px" width="120px" />
            <Square height="24px" width="200px" />
            <Square height="19px" width="110px" />
          </S.Column>
        </Card>
      ))}
    </S.Grid>
  </div>
);

export default ProjectsSkeleton;
