import React from "react";

import useUniqueId from "Libs/useUniqueId";

const IconCalendar = props => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`calendar-icon-${uid}`}
      {...props}
    >
      <title id={`calendar-icon-${uid}`}>Calendar Icon</title>
      <path
        d="M19 5H17V4C17 3.4 16.6 3 16 3C15.4 3 15 3.4 15 4V5H9V4C9 3.4 8.6 3 8 3C7.4 3 7 3.4 7 4V5H5C3.9 5 3 5.9 3 7V10V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V10V7C21 5.9 20.1 5 19 5ZM5 7H7C7 7.6 7.4 8 8 8C8.6 8 9 7.6 9 7H15C15 7.6 15.4 8 16 8C16.6 8 17 7.6 17 7H19V9H5V7ZM5 19V11H19V19H5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCalendar;
