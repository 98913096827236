import styled, { keyframes } from "styled-components";

import { semiBoldAlias, breakpoints } from "Libs/theme";

export const expandOut = keyframes`
  0% {
    width: 68px;
  }
  100% {
    width: 176px;
  }
`;

export const expandIn = keyframes`
  0% {
    width: 176px;
  }
  100% {
    width: 68px;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  order: +1;

  @media (min-width: ${breakpoints[1]}) {
    width: auto;
    order: 0;
    margin-top: 0;
  }

  .search-wrapper {
    height: inherit;
    margin-right: 8px;
    display: inline-block;
    input.search {
      width: 86px;
      animation: ${expandIn} 0.18s linear 1;
      padding: 0 8px 0 32px;
      &.active {
        width: 176px;
        animation: ${expandOut} 0.18s linear 1;
      }
    }
  }

  .environments-filter-window {
    width: 300px;
    right: 50%;
    margin-right: -143px;
    h6 {
      font-size: 14px;
      color: ${props => props.theme.sectionText};
      ${semiBoldAlias};
      &.title {
        font-weight: bold;
        line-height: 24px;
      }
    }
  }

  .info-button {
    margin-left: 24px;
    height: 32px;

    @media (min-width: ${breakpoints[1]}) {
      margin-right: 24px;
    }

    > button {
      padding: 0 4px !important;
      border: none;
      width: auto;
      @media (min-width: ${breakpoints[3]}) {
        width: 70px;
      }
      &:hover,
      &.open {
        svg {
          transform: none;
          rect {
            fill: ${props => props.theme.primary};
          }
        }
      }
    }
    .text {
      margin-right: 8px;
      @media (min-width: ${breakpoints[3]}) {
        display: inline-block;
      }
    }
  }
  @media (min-width: ${breakpoints[0]}) {
    .search-wrapper {
      display: inline-block;
    }
  }
`;
