import styled from "styled-components";

export const EmptyTree = styled.div`
  text-align: center;
  padding-bottom: 62px;
`;

export const Image = styled.div`
  margin-bottom: 32px;
`;

export const Message = styled.div`
  color: ${props => props.theme.sectionText};
  margin-bottom: 8px;
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  max-width: ${({ maxWidth = "315px" }) => maxWidth};
  margin: 0 auto;
`;
