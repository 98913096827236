import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const MinusIcon = ({ color = "#4A495E" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`minus-icon-${uid}`}
    >
      <title id={`minus-icon-${uid}`}>Minus icon</title>
      <path
        d="M6 11H9H11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9H9H6C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11Z"
        fill={color}
      />
    </svg>
  );
};

MinusIcon.propTypes = {
  color: PropTypes.string
};

export default MinusIcon;
