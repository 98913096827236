import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const Card = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      role="img"
      aria-labelledby={`card-icon-${uid}`}
    >
      <title id={`card-icon-${uid}`}>Card icon</title>
      <path
        d="M22 3.6H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h20c.6 0 1-.4 1-1v-14c0-.6-.4-1-1-1zm-1 2v2.3H3V5.6h18zm-18 12V9.9h18v7.7H3z"
        fill={color}
      />
    </svg>
  );
};

Card.propTypes = {
  color: PropTypes.string
};

export default Card;
