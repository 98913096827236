import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import momentTZ from "moment-timezone";

import AvatarPic from "Components/Avatar";
import AccessibleTooltip from "Components/AccessibleTooltip";
import * as S from "./CommitListItem.styles";

const Commit = ({ date, message, name, userAvatar, sha }) => (
  <S.CommitLayout key={sha}>
    <Avatar name={name} sha={sha} userAvatar={userAvatar} />
    <CommitSha sha={sha} />
    <S.CommitMessage>{message}</S.CommitMessage>
    {date && <CommitDate date={date} sha={sha} />}
  </S.CommitLayout>
);

// Subcomponents
const Avatar = ({ name, userAvatar, sha }) => (
  <S.AvatarWithTooltip
    tooltipProps={{
      id: `${sha}-name`,
      children: name
    }}
  >
    <AvatarPic name={name} picture={userAvatar} />
  </S.AvatarWithTooltip>
);

const CommitSha = ({ sha }) => <S.CommitSha>{sha.substring(0, 7)}</S.CommitSha>;

const CommitDate = ({ date, sha }) => {
  const momentDate = momentjs(date * 1000);

  return (
    <S.CommitDateLayout>
      <AccessibleTooltip
        as="span"
        tooltipProps={{
          id: `${sha}-date`,
          wrapper: "span",
          children: `UTC${momentTZ(momentDate).format("Z")}`
        }}
      >
        {momentDate.format("H:mm, MMM D, YYYY")}
      </AccessibleTooltip>
    </S.CommitDateLayout>
  );
};

// Types

CommitSha.propTypes = {
  sha: PropTypes.string
};

Avatar.propTypes = {
  ...CommitSha.propTypes,
  name: PropTypes.string,
  userAvatar: PropTypes.string
};

CommitDate.propTypes = {
  ...CommitSha.propTypes,
  date: PropTypes.number.isRequired
};

Commit.propTypes = {
  ...Avatar.propTypes,
  ...CommitSha.propTypes,
  date: PropTypes.number,
  message: PropTypes.string
};

export default Commit;
