import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Loading from "Components/Loading";

import Link from "../Link";

import { Layout } from "./styles";

const LoadMore = ({ hasMore, isLoading, onClick }) =>
  hasMore ? (
    <Layout>
      {isLoading ? (
        <Loading iconOnly />
      ) : (
        <Link as="button" onClick={onClick}>
          <FormattedMessage id="tickets.more" />
        </Link>
      )}
    </Layout>
  ) : null;

LoadMore.propTypes = {
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func
};

export default LoadMore;
