import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import moment from "moment";

import {
  loadMoreProjectActivities,
  loadProjectActivities,
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities,
  loadIntegrationActivities,
  loadMoreIntegrationActivities,
  getSelectors
} from "Reducers/activity";
import useDecodedParams from "Hooks/useDecodedParams";

import ActivityList from "../../components/ActivityList";
import LogModal from "../../components/ActivityList/LogModal";
import Filter from "../../components/ActivityList/Filter";
import SectionHeader from "./SectionHeader";
import ActivitySectionSkeleton from "./ActivitySectionSkeleton";

// getOptions returns the options for the filter menu as well as the
// labels we use in the select menu, be careful when changing the value
// here since these values are passed to the action and so used to make
// the call to the API.
// Every time we filter we call the APi for activities, instead of
// filtering the ones we already have in the store
const getOptions = intl => [
  {
    value: "all_type",
    label: intl.formatMessage({ id: "all_type" })
  },
  {
    value: "backup",
    label: intl.formatMessage({ id: "backups" })
  },
  {
    value: "branch",
    label: intl.formatMessage({ id: "branch" })
  },
  {
    value: "certificate",
    label: intl.formatMessage({ id: "certificate" })
  },
  {
    value: "cron",
    label: intl.formatMessage({ id: "crons" })
  },
  {
    value: "git",
    label: intl.formatMessage({ id: "git" })
  },
  {
    value: "merge",
    label: intl.formatMessage({ id: "merge" })
  },
  {
    value: "sync",
    label: intl.formatMessage({ id: "sync" })
  },
  {
    value: "system",
    label: intl.formatMessage({ id: "system" })
  }
];

const TabName = {
  Recent: "recent",
  All: "all"
};

const ellapsedTimeIsLessThan = (date, durationInSeconds) => {
  const duration = moment.duration(moment().diff(moment(date)));
  return durationInSeconds > duration.asSeconds();
};

/**
 * Filter activities not older than ellapsedTime and limit by limitActivityCount
 * @param {Activities} activities Activities to filter
 * @param {number} ellapsedSeconds Time in seconds by which this activity must not be older than
 * @param {number} limitActivityCount Total activities to be considered in created date ASC
 * @returns Activities filtered by ellapsed time and limit
 */
const filterRecentActivities = (
  activities,
  ellapsedSeconds = Infinity,
  limitActivityCount = 10
) => {
  const limitCount =
    activities.size < limitActivityCount ? activities.size : limitActivityCount;
  return activities
    .sort(
      (item1, item2) =>
        new Date(item2.created_at).getTime() -
        new Date(item1.created_at).getTime()
    )
    .slice(0, limitCount)
    .filter(({ created_at }) =>
      ellapsedTimeIsLessThan(created_at, ellapsedSeconds)
    );
};

const ActivitySection = ({ context }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const options = getOptions(intl);
  // temporary hack since backups from deleted enviroments give an error
  // so we remove backups from the filter options if this is loaded in the
  // context of a project
  if (context === "project") {
    options.splice(1, 1);
  }

  const optionsValues = options.map(o => o.value);
  const [showing, setShowing] = useState(TabName.Recent);
  const [filters, setFilters] = useState([]);

  const {
    projectId,
    organizationId,
    environmentId,
    integrationId,
    activityId
  } = useDecodedParams();
  const {
    all,
    completed,
    inProgress,
    pending,
    hasMore,
    isLoading,
    isLoadingMore
  } = getSelectors(context, {
    projectId,
    organizationId,
    environmentId,
    integrationId
  });

  const allActivities = useSelector(all);
  const recentPendingActivities = filterRecentActivities(useSelector(pending));
  const recentCompletedActivities = filterRecentActivities(
    useSelector(completed)
  );
  const recentInprogressActivities = filterRecentActivities(
    useSelector(inProgress)
  );

  const project = useSelector(state =>
    state.project.getIn(["data", organizationId, projectId])
  );
  const hasMoreActivities = useSelector(hasMore);
  const isLoadingActivities = useSelector(isLoading);
  const isLoadingMoreActivities = useSelector(isLoadingMore);

  // We show just one section if the all tab is selected but also
  // if activities are empty
  const shouldShowFilter = showing === TabName.All;

  const shouldShowRecentSection = showing === TabName.Recent;

  const shouldShowMoreButton =
    showing !== TabName.Recent &&
    !(isLoadingActivities || isLoadingMoreActivities) &&
    hasMoreActivities;

  // If all is not already selected select everything, else select
  // every checkbox
  const handleAllPresed = () => {
    if (filters.indexOf("all_type") === -1) {
      setFilters(optionsValues);
    } else {
      setFilters([]);
    }
  };

  const handleFilterChange = e => {
    const { name } = e.target;
    if (name === "all_type") {
      return handleAllPresed(e);
    }

    toggleFilter(name);
  };

  const toggleFilter = filterName => {
    filters.indexOf(filterName) === -1
      ? setFilters([...filters, filterName])
      : setFilters(
          filters.filter(el => el !== filterName && el !== "all_type")
        );
  };

  // loadActivity dispatches a different action depending if we are
  // in the environment or project context, this will make the action
  // fetch the activities from the corresponding endpont
  const loadActivity = () => {
    switch (true) {
      case context === "environment":
        dispatch(
          loadEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filters
          )
        );
        break;
      case context.startsWith("integration"):
        if (!integrationId) break;
        dispatch(
          loadIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            // filters
            // Integration does not currently support filters.
            // Any filter causes api to return empty collection
            []
          )
        );
        break;
      default:
        dispatch(loadProjectActivities(projectId, organizationId, filters));
    }
  };

  // loadMoreActivity dispatches a different action depending if we are
  // in the environment or project context, this will make the action
  // fetch the activities from the corresponding endpont
  const loadMoreActivity = () => {
    switch (true) {
      case context === "environment":
        dispatch(
          loadMoreEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filters
          )
        );
        break;
      case context.startsWith("integration"):
        dispatch(
          loadMoreIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            filters
          )
        );
        break;
      default:
        dispatch(loadMoreProjectActivities(projectId, organizationId, filters));
    }
  };

  // handleTabChange handles switching between the all and recent tabs
  const handleTabChange = tabName => {
    setShowing(tabName);

    // We just want to reset the filters when switching tabs for now
    // but in the recent tab we want to hide the crons, so we pass all
    // of the filters except crons in that case

    const [contextName] = context.split(".");
    switch (tabName) {
      case TabName.Recent:
        {
          switch (contextName) {
            case "integration":
              // Filter options under Recent tab is hidden for
              // Integrations context, so reset it
              setFilters(filters);
              break;
            default:
              setFilters(filters);
          }
        }
        break;
      case TabName.All: {
        switch (contextName) {
          case "integration":
            // Reset Filter in Integrations(github|email|slack|etc)
            // As there are no options to uniquely filter integrations
            // Only here to allow future implementation of these filters
            setFilters(filters);
            break;
          default:
            setFilters(filters);
        }
      }
    }
  };

  useEffect(loadActivity, [environmentId, projectId, integrationId, filters]);

  const getOriginUrl = () => {
    let url = `/${organizationId}/${projectId}`;
    if (environmentId) {
      return `${url}/${encodeURIComponent(environmentId)}`;
    }
    if (integrationId) {
      return `${url}/-/integrations/${integrationId}`;
    }
    return url;
  };

  const closeLog = () => {
    history.push(getOriginUrl());
  };

  return (
    <Container aria-labelledby="project-activity-heading">
      <SectionHeader showing={showing} onTabChange={handleTabChange}>
        {shouldShowFilter && (
          <Filter
            options={options}
            activeOptions={filters}
            onChange={e => handleFilterChange(e)}
          />
        )}
      </SectionHeader>
      {shouldShowRecentSection ? (
        <>
          <SubsectionHeadingFirst>
            {intl.formatMessage({ id: "activities.headers.running" })}
            <ActivityCount> ({recentInprogressActivities.size})</ActivityCount>
          </SubsectionHeadingFirst>
          {isLoadingActivities ? (
            <ActivitySectionSkeleton />
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentInprogressActivities}
                activityType="running"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.pending" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({recentPendingActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentPendingActivities}
                activityType="pending"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.recent" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({recentCompletedActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={recentCompletedActivities}
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
        </>
      ) : isLoadingActivities ? (
        <>
          <ActivitySectionSkeleton />
          <ActivitySectionSkeleton />
        </>
      ) : (
        // All activities
        <BoxLayout>
          <ActivityList
            organizationId={organizationId}
            projectId={projectId}
            loadMore={loadMoreActivity}
            loadActivitiesOfType={loadActivity}
            activities={allActivities}
            activityIsLoading={isLoadingMoreActivities}
            hasMore={hasMoreActivities}
            canEditProject={
              project &&
              typeof project.hasPermission === "function" &&
              project.hasPermission("#edit")
            }
            activityContext={context}
          />
        </BoxLayout>
      )}
      {shouldShowMoreButton && (
        <SectionFooter>
          <LinkButton onClick={loadMoreActivity}>Show more</LinkButton>
        </SectionFooter>
      )}

      {activityId && <LogModal closeModal={closeLog} />}
    </Container>
  );
};

ActivitySection.propTypes = {
  context: PropTypes.string
};

const Container = styled.section`
  width: 100%;
  min-width: 65%;
  box-sizing: border-box;
  padding: 8px 0 !important;
`;

const BoxLayout = styled.div`
  padding: 0 32px;
`;

const SubsectionHeading = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.theme.sectionText};
`;

const SubsectionHeadingFirst = styled(SubsectionHeading)`
  margin-top: -10px;
`;

const ActivityCount = styled.span`
  font-size: 13px;
  color: ${props => props.theme.subtitleText};
`;

const SectionFooter = styled.footer`
  padding: 10px;
  text-align: center;
`;

const LinkButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 6px 8px;
  height: auto;
  border: none;
  color: ${props => props.theme.links};
  font-size: 14px;

  &:hover,
  &:focus {
    background: transparent;
    text-decoration: underline;
  }
`;

export default ActivitySection;
