import React from "react";
import PropTypes from "prop-types";

import ChevronIcon from "Icons/ChevronIcon";
import CloseIcon from "Icons/CloseIcon";
import Button from "../Button";

import * as S from "./SettingLine.styles";

const SettingLine = ({
  addNewTitle,
  children,
  className = "",
  closedStyle,
  customAction,
  id,
  info,
  isNew,
  isOpen,
  onClick,
  openText,
  noToggle,
  settingLineInfoWidth,
  variant
}) => {
  const handleClick = e => {
    e?.preventDefault();
    onClick();
    if (!isNew) document.activeElement.blur();
  };

  return (
    <S.Wrapper
      variant={variant}
      className={`settings-item${
        isOpen || (!closedStyle && noToggle) ? " open" : ""
      } ${className}`}
    >
      <S.Layout variant={variant} className="setting-line">
        {isNew ? (
          <div id={id} className="setting-content">
            <strong>{addNewTitle ? addNewTitle : "Add"}</strong>
          </div>
        ) : (
          <S.Info
            className="setting-content"
            width={settingLineInfoWidth}
            id={id}
          >
            {info}
          </S.Info>
        )}

        {!noToggle && (
          <div className="action">
            {isNew ? (
              <Button
                id={`${id}-edit`}
                onClick={handleClick}
                aria-label="close"
              >
                <CloseIcon />
              </Button>
            ) : (
              <Button
                id={`${id}-edit`}
                onClick={handleClick}
                aria-label={openText}
              >
                <span className="open-text">{openText}</span> <ChevronIcon />
              </Button>
            )}
          </div>
        )}
        {customAction}
      </S.Layout>
      {children}
    </S.Wrapper>
  );
};

SettingLine.propTypes = {
  addNewTitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closedStyle: PropTypes.bool,
  customAction: PropTypes.node,
  id: PropTypes.string,
  info: PropTypes.node,
  isNew: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  openText: PropTypes.string,
  noToggle: PropTypes.bool,
  settingLineInfoWidth: PropTypes.string,
  variant: PropTypes.string
};

export default SettingLine;
