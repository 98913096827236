import styled from "styled-components";
import { Skeleton } from "Components/Skeleton";

export const Grid = styled(Skeleton)`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 32px;

  @media (min-width: 834px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(432px, 1fr));
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(427px, 1fr));
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 20px 28px 32px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
  box-sizing: border-box;
`;
