import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Ansi from "ansi-to-react";
import { List } from "immutable";
import stripAnsi from "strip-ansi";
import VirtualList from "react-tiny-virtual-list";

import client from "Libs/platform";
import useDecodedParams from "Hooks/useDecodedParams";
import { loadLogFromActivity } from "Reducers/log";

import ActivityHeader from "../../activities/Activity/ActivityHeader";
import ButtonWrapper from "Components/ButtonWrapper";
import ModalWrapper from "Components/Modal";
import ShareIcon from "Icons/ShareIcon";

import * as S from "./LogModal.styles";

const LINE_HEIGHT = 21;

const logs2Text = logs => {
  return logs.reduce((logText, jsLog) => {
    if (!jsLog || !jsLog.data) {
      return logText;
    }
    return `${logText} ${stripAnsi(jsLog.data.message)}`;
  }, "");
};

const LogModal = ({ closeModal }) => {
  const intl = useIntl();
  const { push } = useHistory();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const logWrapper = useRef();
  const { activityId, projectId } = useDecodedParams();

  const [activity, setActivity] = useState();
  const [linesSelected, setLinesSelected] = useState({
    start: null,
    end: null,
    scrollTo: false
  });
  const [selection, setSelection] = useState();

  const jsLog = useSelector(({ log }) =>
    log?.getIn(["data", activity?.id, "log"], List())
  );
  const loading = useSelector(({ log }) =>
    log?.getIn(["data", activity?.id, "loading"], false)
  );

  const organization = useSelector(({ project }) =>
    project?.getIn(["orgByProjectId", activity?.project])
  );
  const project = useSelector(({ project }) =>
    project?.getIn(["data", organization, activity?.project])
  );

  useEffect(() => {
    const regex = /^#L(\d*)(-L(\d*))?/;
    const match = hash.match(regex);
    if (match) {
      setLinesSelected({
        start: Number(match[1] - 1),
        end: match[3] ? Number(match[3] - 1) : null,
        scrollTo: true
      });
    }
  }, []);

  useEffect(
    () => {
      const loadActivity = async () => {
        const result = await client.getProjectActivity(projectId, activityId);
        setActivity(result);
      };
      if (activityId) loadActivity();
    },
    [activityId]
  );

  useEffect(
    () => {
      if (activity?.id) dispatch(loadLogFromActivity({ activity }));
    },
    [activity]
  );

  useEffect(
    () => {
      const { start, end } = linesSelected;
      let newHash = "#";
      if (start) newHash += `L${start + 1}`;
      if (end) newHash += `-L${end + 1}`;
      if (hash !== newHash) push({ hash: newHash });
    },
    [linesSelected]
  );

  const fullLogs = useMemo(
    () => {
      if (!jsLog.length) return "";
      return logs2Text(jsLog);
    },
    [jsLog]
  );

  const copyText = useMemo(
    () => {
      const { start, end } = linesSelected;

      // If user selects lines
      if (selection) return selection;

      // When only one line is selected
      if (typeof start === "number" && !end && jsLog[start]) {
        return stripAnsi(jsLog[start].data.message);
      }

      // When multilines are selected
      if (typeof start === "number" && end) {
        return logs2Text(jsLog.slice(start, end + 1));
      }

      return fullLogs;
    },
    [linesSelected, fullLogs, selection]
  );

  const itemCount = useMemo(
    () => {
      if (!jsLog.length) return 0;
      const length = jsLog.length;
      return loading ? length + 1 : length;
    },
    [jsLog.length, loading]
  );

  const height = useMemo(
    () => {
      if (!logWrapper.current) return 50;
      return logWrapper.current.offsetHeight;
    },
    [logWrapper.current, loading]
  );

  const selectLine = (e, line) => {
    const { start, end } = linesSelected;
    if (e.shiftKey) {
      if (line < start) {
        setLinesSelected({ start: line, end: start, scrollTo: false });
      } else {
        setLinesSelected({ ...linesSelected, end: line, scrollTo: false });
      }
    } else {
      if (start === line) {
        setLinesSelected({ start: end, end: null, scrollTo: false });
      } else {
        setLinesSelected({ start: line, end: null, scrollTo: false });
      }
    }
  };

  const handleSelect = e => {
    e.preventDefault();
    setSelection(window.getSelection().toString());
  };

  const isSelected = index => {
    const { start, end } = linesSelected;
    if (!end) {
      return start === index;
    }
    return start <= index && index <= end;
  };

  return (
    <ModalWrapper
      isOpen
      closeModal={closeModal}
      modalClass="modal-build-log modal-fullpage"
    >
      <S.Header>
        <S.Title>
          {[project?.name, activity?.environments[0]]
            .filter(item => item)
            .join(" / ")}
        </S.Title>
        <S.Org>
          {organization}
          <span> </span>
        </S.Org>
      </S.Header>

      {activity && <ActivityHeader activity={activity} />}

      <S.LogPre>
        {jsLog.size < 1 &&
          loading && (
            <S.DotDotDot>{intl.formatMessage({ id: "loading" })}</S.DotDotDot>
          )}

        {project && (
          <>
            {!loading &&
              jsLog.length === 0 && (
                <S.Empty>{intl.formatMessage({ id: "log_empty" })}</S.Empty>
              )}

            <S.Container ref={logWrapper}>
              <VirtualList
                height={height}
                overscanCount={
                  linesSelected.scrollTo
                    ? linesSelected.end || linesSelected.start
                    : 50
                }
                itemCount={itemCount}
                itemSize={index =>
                  index === 0 ? LINE_HEIGHT + 16 : LINE_HEIGHT
                }
                scrollToIndex={
                  linesSelected.scrollTo && jsLog[linesSelected.start]
                    ? linesSelected.start
                    : null
                }
                scrollToAlignment="center"
                onMouseUp={handleSelect}
                renderItem={({ style, index }) => (
                  <S.Line
                    style={style}
                    key={jsLog[index]?.data.timestamp}
                    selected={isSelected(index)}
                  >
                    {loading && index === jsLog.length ? (
                      <S.DotDotDot>
                        {intl.formatMessage({ id: "loading" })}
                      </S.DotDotDot>
                    ) : (
                      <>
                        <S.LineNumber onClick={e => selectLine(e, index)}>
                          {index + 1}
                        </S.LineNumber>
                        <Ansi>{jsLog[index].data.message}</Ansi>
                      </>
                    )}
                  </S.Line>
                )}
              />
            </S.Container>
          </>
        )}
      </S.LogPre>

      <ButtonWrapper buttonPosition="right">
        {copyText.length > 0 && (
          <S.CopyBtn
            text={copyText}
            title={intl.formatMessage({ id: "icons.copy_log" })}
            variant="secondary"
          />
        )}

        <S.CopyBtn
          text={window.location.href}
          title={intl.formatMessage({ id: "icons.share_link" })}
          icon={<ShareIcon aria-hidden="true" color="#FFF" />}
        />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

LogModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default LogModal;
