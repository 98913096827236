import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Button from "Components/Button";
import FullPageError from "Components/FullPageError";
import { environmentSelector } from "Reducers/environment";

const EnvironmentError = ({
  organizationId,
  projectId,
  environmentId,
  children
}) => {
  const intl = useIntl();

  const environmentLoadingError = useSelector(({ environment }) => {
    const error = environment.get("environmentLoadingError");
    return error?.toJS ? error.toJS() : error;
  });

  const isLoading = useSelector(({ environment }) =>
    environment.get("loading")
  );

  const environment = useSelector(state =>
    environmentSelector(state, { organizationId, projectId, environmentId })
  );

  let errorTitle = intl.formatMessage({ id: "error_loading_environment" });
  let errorMessage = intl.formatMessage({
    id: "error_loading_environment_description"
  });

  if (!isLoading && environmentLoadingError) {
    if (environmentLoadingError.code === 403) {
      errorTitle = intl.formatMessage({ id: "error_403_title" });
      errorMessage = intl.formatMessage({ id: "error_environment_403" });
    }
  }

  const shouldShowError =
    !isLoading && (environmentLoadingError || !environment);

  return shouldShowError ? (
    <FullPageError title={errorTitle} message={errorMessage}>
      <div className="actions">
        <Button
          className="primary"
          as={Link}
          to={`/${organizationId}/${projectId}`}
          aria-label="Return to project"
        >
          Return to project
        </Button>
      </div>
    </FullPageError>
  ) : (
    children
  );
};

EnvironmentError.propTypes = {
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  children: PropTypes.node,
  environmentId: PropTypes.string
};

export default EnvironmentError;
