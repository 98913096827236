import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const Safe = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      role="img"
      aria-labelledby={`safe-icon-${uid}`}
    >
      <title id={`safe-icon-${uid}`}>Safe icon</title>
      <rect
        width="3"
        height="5"
        x="16"
        y="19"
        stroke={color}
        strokeWidth="2"
        rx="1.5"
      />
      <rect
        width="3"
        height="5"
        x="5"
        y="19"
        stroke={color}
        strokeWidth="2"
        rx="1.5"
      />
      <rect
        width="22"
        height="20"
        x="1"
        y="1"
        fill="#fff"
        stroke={color}
        strokeWidth="2"
        rx="4"
      />
      <rect
        width="2.475"
        height="17.324"
        x="5"
        y="5.75"
        fill={color}
        rx="1.237"
        transform="rotate(-45 5 5.75)"
      />
      <rect
        width="2.475"
        height="17.324"
        x="17.25"
        y="4"
        fill={color}
        rx="1.237"
        transform="rotate(45 17.25 4)"
      />
      <circle
        cx="12"
        cy="11"
        r="5"
        fill="#fff"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="11"
        r="2"
        fill="#fff"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

Safe.propTypes = {
  color: PropTypes.string
};

export default Safe;
