import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const CronIcon = ({ color = "currentColor" }) => {
  const uid = useUniqueId();

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      role="img"
      aria-labelledby={`cron-icon-${uid}`}
    >
      <title id={`cron-icon-${uid}`}>Cron icon</title>
      <g fill={color}>
        <path
          d="M18,9c-1.3,0-2.4,0.9-2.8,2c-0.1,0-0.1,0-0.2,0h-3V9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.6,0.4,1,1,1h4
		c0.1,0,0.1,0,0.2,0c0.4,1.2,1.5,2,2.8,2c1.7,0,3-1.3,3-3S19.7,9,18,9z M18,13c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1s1,0.4,1,1
		C19,12.6,18.6,13,18,13z"
        />
        <path
          d="M15.2,16c-1.1,1.3-2.7,2-4.4,2C7.6,18,5,15.3,5,12s2.6-6,5.9-6c1.7,0,3.3,0.7,4.4,2c0.4,0.4,1,0.5,1.4,0.1
		c0.4-0.4,0.5-1,0.1-1.4C15.3,5,13.1,4,10.9,4C6.5,4,3,7.6,3,12s3.5,8,7.9,8c2.2,0,4.4-1,5.9-2.7c0.4-0.4,0.3-1-0.1-1.4
		C16.2,15.6,15.6,15.6,15.2,16z"
        />
      </g>
    </svg>
  );
};

CronIcon.propTypes = {
  color: PropTypes.string
};

export default CronIcon;
