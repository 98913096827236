import React from "react";
import PropTypes from "prop-types";

import CloseIcon from "Icons/CloseIcon";
import * as S from "./styles";

const Tag = ({ children, onClose }) => (
  <S.Tag>
    {children}
    {onClose ? (
      <S.CloseTagButton onClick={onClose}>
        <CloseIcon />
      </S.CloseTagButton>
    ) : null}
  </S.Tag>
);

Tag.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.string])
};

export default Tag;
