import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div`
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: ${props => props.theme.label};
    line-height: 18px;
    text-decoration: none;
    ${semiBoldAlias};
    cursor: pointer;
    svg {
      margin-right: 12px;
      path,
      rect {
        fill: ${props => props.theme.label};
      }
    }
    &:hover {
      color: ${props => props.theme.links};
      svg {
        rect,
        path {
          fill: ${props => props.theme.links};
        }
      }
    }
  }
  margin-bottom: 16px;
`;
