import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { PRIORITIES } from "../../config";

import { Layout } from "./styles";

const Priority = ({ priority, ...props }) => (
  <Layout priority={priority} {...props}>
    {PRIORITIES[priority] ? (
      <FormattedMessage
        id={`tickets.priority.${PRIORITIES[priority] &&
          PRIORITIES[priority].id}.label`}
      />
    ) : (
      priority
    )}
  </Layout>
);

Priority.propTypes = {
  priority: PropTypes.string
};

export default Priority;
