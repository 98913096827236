import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  max-width: 100%;
  min-width: 264px;
  margin-bottom: 32px;
  .input-required {
    border: 1px solid ${props => props.theme.error};
  }
  .required-message {
    display: inline-block;
    color: ${props => props.theme.fieldError};
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .CodeMirror {
    border-radius: 2px;
    background-color: ${props => props.theme.ideBg} !important;
  }

  .CodeMirror-lines {
    padding-left: 26px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  padding: 0;
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  border: none;
  background: none;
  svg path {
    fill: ${props => props.theme.sectionTextLight};
  }
  &:hover svg path {
    fill: ${props => props.theme.sectionText};
  }
`;
