import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const StateIconLayout = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  padding: 2px;
  svg {
    width: 100%;
  }
`;

const CancelledStateIcon = ({ color = "currentColor" }) => {
  const uid = useUniqueId();

  return (
    <StateIconLayout>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style={{ enableBackground: "new 0 0 20 20" }}
        xmlSpace="preserve"
        role="img"
        aria-labelledby={`success-state-icon-${uid}`}
      >
        <title id={`success-state-icon-${uid}`}>Success state icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 11.4853L6.87868 14.6066C6.48816 14.9971 5.85499 14.9971 5.46447 14.6066C5.07394 14.2161 5.07394 13.5829 5.46447 13.1924L8.58579 10.0711L5.46447 6.94975C5.07394 6.55922 5.07394 5.92606 5.46447 5.53553C5.85499 5.14501 6.48816 5.14501 6.87868 5.53553L10 8.65685L13.1213 5.53553C13.5118 5.14501 14.145 5.14501 14.5355 5.53553C14.9261 5.92606 14.9261 6.55922 14.5355 6.94975L11.4142 10.0711L14.5355 13.1924C14.9261 13.5829 14.9261 14.2161 14.5355 14.6066C14.145 14.9971 13.5118 14.9971 13.1213 14.6066L10 11.4853Z"
          fill="white"
        />
      </svg>
    </StateIconLayout>
  );
};

CancelledStateIcon.propTypes = {
  color: PropTypes.string
};

export default CancelledStateIcon;
