import styled from "styled-components";
import ActionDropdown from "Components/ActionDropdown";
import { themeHelpers } from "Libs/theme";

export const ToggleButton = styled.button.attrs({
  className: "toggle-button"
})`
  &.toggle-button {
    margin-right: 8px;
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
  :hover svg [fill] {
    fill: ${({ theme }) => theme.primary};
  }
`;

export const ProjectsSort = styled(ActionDropdown)`
  &.info-button > button {
    &.open,
    :hover {
      background-color: ${({ theme }) =>
        theme.name === "contrast"
          ? themeHelpers(theme, "buttonHover")
          : theme.name === "dark"
            ? themeHelpers(theme, "darkTint")
            : "#fff"};
    }
    outline: none;
    box-shadow: none;
    height: 32px;
    margin: 1px;
    padding: 0 !important;
    border: none;
    width: auto;
    margin-right: 4px;
    .text {
      padding-left: 8px;
      padding-right: 8px;
      .active {
        display: none;
      }
    }
    span.icon {
      height: 24px;
      width: 24px;
    }
  }

  input[type="radio"]:checked + label {
    color: ${({ theme }) => theme.primary};
  }

  .radio-field:last-child label,
  .radio-field:last-child {
    margin-bottom: 0;
  }
`;
