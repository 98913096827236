export default {
  hljs: {
    display: "block",
    overflowX: "auto",
    margin: 0,
    padding: "10px 0",
    color: "#000",
    background: "#1A192B"
  },
  linenumber: {
    marginRight: "32px",
    paddingRight: "12px",
    minWidth: "16px",
    borderRight: "1px solid #302F45"
  },
  "hljs-comment": {
    color: "#98A0AB",
    fontStyle: "italic"
  },
  "hljs-quote": {
    color: "#408080",
    fontStyle: "italic"
  },
  "hljs-keyword": {
    color: "#954121"
  },
  "hljs-selector-tag": {
    color: "#954121"
  },
  "hljs-literal": {
    color: "#FF9F9F"
  },
  "hljs-subst": {
    color: "#954121"
  },
  "hljs-number": {
    color: "#71DB82"
  },
  "hljs-string": {
    color: "#23B7B7"
  },
  "hljs-doctag": {
    color: "#219161"
  },
  "hljs-selector-id": {
    color: "#19469d"
  },
  "hljs-selector-class": {
    color: "#19469d"
  },
  "hljs-section": {
    color: "#19469d"
  },
  "hljs-type": {
    color: "#19469d"
  },
  "hljs-params": {
    color: "#00f"
  },
  "hljs-title": {
    color: "#458",
    fontWeight: "bold"
  },
  "hljs-tag": {
    color: "#000080",
    fontWeight: "normal"
  },
  "hljs-name": {
    color: "#000080",
    fontWeight: "normal"
  },
  "hljs-attribute": {
    color: "#000080",
    fontWeight: "normal"
  },
  "hljs-variable": {
    color: "#008080"
  },
  "hljs-template-variable": {
    color: "#008080"
  },
  "hljs-regexp": {
    color: "#b68"
  },
  "hljs-link": {
    color: "#b68"
  },
  "hljs-symbol": {
    color: "#990073"
  },
  "hljs-bullet": {
    color: "#990073"
  },
  "hljs-built_in": {
    color: "#0086b3"
  },
  "hljs-builtin-name": {
    color: "#0086b3"
  },
  "hljs-meta": {
    color: "#999",
    fontWeight: "bold"
  },
  "hljs-deletion": {
    background: "#fdd"
  },
  "hljs-addition": {
    background: "#dfd"
  },
  "hljs-emphasis": {
    fontStyle: "italic"
  },
  "hljs-strong": {
    fontWeight: "bold"
  }
};
