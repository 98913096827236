import React from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { CardElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import client from "Libs/platform";
import logger from "Libs/logger";
import Loading from "Components/Loading";
import Button from "Components/Button";
import Label from "Components/fields/Label";
import InputField from "Components/fields/InputField";
import RequiredTag from "Components/fields/RequiredTag";
import Error from "Components/Error";

const Layout = styled.div`
  max-width: 100%;
  .StripeElement {
    background: ${props => props.theme.inputBg};
    color: ${props => props.theme.inputText};
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .setting-line {
    background: ${props => props.theme.inputBg};
    border: 1px solid ${props => props.theme.inputBg};
    color: ${props => props.theme.inputText};
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
`;

class CardSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditcard: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let error, secret;

    try {
      secret = await client.createPaymentSourceIntent();
    } catch (err) {
      error = err;
    }

    this.setState({
      isLoading: false,
      secret: secret.client_secret,
      error
    });
  }

  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const { secret } = this.state;
    if (this.props.edit) {
      this.setState({
        isLoading: true
      });

      try {
        const response = await this.props.stripe.handleCardSetup(secret, {
          payment_method_data: {
            billing_details: { name: this.props.name, email: this.props.email }
          }
        });

        if (response.error) {
          throw response.error;
        }

        const cardTokenId = response.setupIntent?.payment_method;

        const creditcard = await client.addPaymentSource(
          "credit-card",
          cardTokenId
        );

        this.setState({
          isLoading: false,
          creditcard,
          error: null
        });

        this.props.onSuccess && this.props.onSuccess(creditcard);
      } catch (err) {
        logger(err);
        this.setState({
          isLoading: false,
          error: err
        });
      }
    } else {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  render() {
    const { isLoading = true, error } = this.state;
    const {
      intl,
      number,
      edit,
      onEdit,
      onCancel,
      hideButtons,
      theme
    } = this.props;

    return (
      <Layout>
        <form aria-labelledby="edit-card" id="stripe-payment-form">
          {error && (
            <Error>
              {error.message ||
                error.description ||
                JSON.stringify(error, null, 4)}
            </Error>
          )}
          {isLoading && <Loading />}
          {!edit && number ? (
            <React.Fragment>
              <LiveMessage
                message="click update credit card to edit"
                aria-live="polite"
              />
              <InputField
                label="credit card"
                className="card-number"
                isDisabled={true}
                value={number.replace(/X/g, "•").replace(/-/g, " ")}
              />
              <div className="">
                <Button
                  onClick={onEdit}
                  type="button"
                  aria-label={intl.formatMessage({ id: "update_credit_card" })}
                >
                  {intl.formatMessage({ id: "update_credit_card" })}
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className="new-card">
              <LiveMessage
                message="enter credit card information"
                aria-live="polite"
              />
              <Label>
                {intl.formatMessage({ id: "credit_card_number" })}{" "}
                <RequiredTag />
              </Label>
              <CardElement
                className="stripe-card"
                style={{
                  base: {
                    fontSize: "14px",
                    iconColor: theme.inputText,
                    color: theme.inputText,
                    backgroundColor: theme.inputBg,
                    "::placeholder": {
                      fontSize: "14px",
                      color: theme.inputPlaceholder
                    }
                  }
                }}
                iconStyle="solid"
              />
              {!hideButtons && (
                <React.Fragment>
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_submit"
                    className="primary"
                    onClick={this.handleSubmit}
                  >
                    {intl.formatMessage({ id: "save_changes" })}
                  </Button>
                  {number && (
                    <Button
                      type="button"
                      aria-label={intl.formatMessage({ id: "save_changes" })}
                      id="stripe_cardsection_cancel"
                      className="secondary"
                      onClick={onCancel}
                    >
                      {"cancel"}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </form>
      </Layout>
    );
  }
}

CardSection.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  theme: PropTypes.object,
  onCancel: PropTypes.func
};

export default injectStripe(injectIntl(withTheme(CardSection)));
