import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color: ${props => props.theme.sectionText};
`;

const ButtonWrapperRight = styled(ButtonWrapper)`
  justify-content: flex-end;
`;

const NotificationModal = props => {
  const { message, title, onConfirm, isOpen, className } = props;

  return (
    <ModalWrapper
      modalClass="modal-small"
      isOpen={isOpen}
      closeModal={onConfirm}
      title={title}
      className={className}
    >
      <ModalBody className="modal-buttons">{message}</ModalBody>
      <ButtonWrapperRight>
        <Button onClick={onConfirm}>
          <FormattedMessage id="okay" />
        </Button>
      </ButtonWrapperRight>
    </ModalWrapper>
  );
};

NotificationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string
};

export default NotificationModal;
