import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Heading6 from "Components/styleguide/Heading6";
import Label from "Components/fields/Label";
import CheckboxField from "Components/fields/CheckboxField";
import InlineBanner from "Components/InlineBanner";
import Loading from "Components/Loading";
import InvitationConfirmationModal from "../AccessForm/InvitationConfirmationModal";

import * as S from "./styles";

import { statusSelector } from "Reducers/invitation";

const InvitationDetails = ({ invitation, onRevoke, onResendInvitation }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const intl = useIntl();

  const status = useSelector(statusSelector);

  useEffect(
    () => {
      if ((status === "resent") & modalIsOpen) setModalIsOpen(false);
    },
    [status]
  );
  const { environments = [] } = invitation;

  return (
    <S.Layout>
      <InlineBanner level="info" padding="10px">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {intl.formatMessage(
            { id: "invitation.info" },
            {
              name: invitation?.owner?.display_name,
              date: momentjs(invitation.createdAt).format("MMMM D, YYYY")
            }
          )}

          <InvitationConfirmationModal
            isOpen={modalIsOpen}
            email={invitation.email}
            closeModal={() => setModalIsOpen(false)}
          />

          {status === "resent" ? (
            <span className="link-style">
              {intl.formatMessage({ id: "invitation.resend_success" })}
            </span>
          ) : (
            <S.ResendButton className="link-style" onClick={onResendInvitation}>
              {intl.formatMessage({ id: "invitation.resend" })}
            </S.ResendButton>
          )}

          {status === "pending" && <Loading />}
        </div>
      </InlineBanner>

      <S.InputLayout className="field">
        <CheckboxField
          label={`${intl.formatMessage({ id: "project_admin" })}`}
          type="checkbox"
          value={invitation.role === "admin"}
          disabled
          aria-disabled
          forId="super-user"
        />
      </S.InputLayout>

      <S.EnvironmentPermissions className="environment-permissions">
        {!!environments.length && (
          <>
            <Heading6>
              {intl.formatMessage({ id: "invitation.environment.permission" })}
            </Heading6>
            <S.EnvironmentHeaders className="environment-headers">
              <Label>
                {intl.formatMessage({ id: "invitation.environment" })}
              </Label>
              <Label>
                {intl.formatMessage({ id: "invitation.environment.type" })}
              </Label>
            </S.EnvironmentHeaders>
            <S.TableLine />

            {environments.map(env => (
              <S.PermissionWrapper key={env.id}>
                <Label>{env.title}</Label>
                <Label>{env.role}</Label>
              </S.PermissionWrapper>
            ))}
          </>
        )}
      </S.EnvironmentPermissions>

      <S.RevokeButton
        id={`invitation-revoke-${invitation.id}`}
        className="outline"
        type="button"
        aria-label={intl.formatMessage({ id: "invitation.revoke" })}
        onClick={onRevoke}
      >
        {intl.formatMessage({ id: "invitation.revoke" })}
      </S.RevokeButton>
    </S.Layout>
  );
};

InvitationDetails.propTypes = {
  invitation: PropTypes.object,
  onRevoke: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onCancel: PropTypes.func
};

export default InvitationDetails;
