import React from "react";
import PropTypes from "prop-types";

const GitHub = ({ size = "32px" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9633 4C9.3578 4 4 9.3578 4 15.9633C4 21.2477 7.44954 25.7248 12.1468 27.3394C12.7339 27.4128 12.9541 27.0459 12.9541 26.7523C12.9541 26.4587 12.9541 25.7248 12.9541 24.6972C9.65138 25.4312 8.91743 23.0826 8.91743 23.0826C8.40367 21.6881 7.59633 21.3211 7.59633 21.3211C6.49541 20.5872 7.66972 20.5872 7.66972 20.5872C8.84404 20.6605 9.50459 21.8349 9.50459 21.8349C10.6055 23.6697 12.2936 23.156 12.9541 22.8624C13.0275 22.055 13.3945 21.5413 13.6881 21.2477C11.0459 20.9541 8.25688 19.9266 8.25688 15.3028C8.25688 13.9817 8.69725 12.9541 9.50459 12.0734C9.43119 11.8532 8.99083 10.6055 9.65138 8.99083C9.65138 8.99083 10.6789 8.69725 12.9541 10.2385C13.9083 9.94495 14.9358 9.87156 15.9633 9.87156C16.9908 9.87156 18.0183 10.0183 18.9725 10.2385C21.2477 8.69725 22.2752 8.99083 22.2752 8.99083C22.9358 10.6055 22.4954 11.8532 22.422 12.1468C23.156 12.9541 23.6697 14.055 23.6697 15.3761C23.6697 20 20.8807 20.9541 18.2385 21.2477C18.6789 21.6147 19.0459 22.3486 19.0459 23.4495C19.0459 25.0642 19.0459 26.3119 19.0459 26.7523C19.0459 27.0459 19.2661 27.4128 19.8532 27.3394C24.6239 25.7248 28 21.2477 28 15.9633C27.9266 9.3578 22.5688 4 15.9633 4Z"
      fill="#191717"
    />
  </svg>
);

GitHub.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GitHub;
