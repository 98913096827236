import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const ShieldIcon = ({ color = "currentColor" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-shield"
      width="15px"
      height="16px"
      viewBox="0 0 15 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`shield-icon-${uid}`}
    >
      <title id={`shield-icon-${uid}`}>Shield icon</title>
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-626.000000, -425.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g transform="translate(606.000000, 317.000000)">
            <g transform="translate(20.000000, 107.000000)">
              <g transform="translate(0.000000, 1.000000)">
                <path d="M14.9679993,3.99146647 C14.9679993,10.8917765 10.7291242,14.7386459 8.05967512,15.850737 C7.69123276,16.0042543 7.27673532,16.0042543 6.90829296,15.850737 C3.57033558,14.4601163 0,10.1809525 0,3.99146647 C-1.04912256e-06,3.3871995 0.36333672,2.842199 0.921124438,2.60979541 L6.90832415,0.115128861 C7.27676651,-0.0383884139 7.69126395,-0.0383884139 8.05970631,0.115128861 L14.046906,2.60979541 C14.6046815,2.84220868 14.9680056,3.38720707 14.9679993,3.99146647 Z M7.48399963,13.9175264 L7.48605773,13.9185866 C10.4090273,12.462668 12.8650889,9.04438237 12.9686176,4.32257109 L7.48399963,2.03730062 L7.48399963,13.9175264 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ShieldIcon.propTypes = {
  color: PropTypes.string
};

export default ShieldIcon;
