import styled from "styled-components";

const EmptyActivitiesLayout = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  background-color: ${props => props.theme.rowBackground};
  color: ${props => props.theme.sectionText};
  border-radius: 2px;
`;

export const AllEmpty = styled(EmptyActivitiesLayout)`
  font-size: 15px;
  padding: 68px 0 88px;
`;

export const CategoryEmpty = styled(EmptyActivitiesLayout)`
  padding: 26px 0;
  font-size: 13px;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;
