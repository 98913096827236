import React from "react";
import PropTypes from "prop-types";

import { Layout, HeaderSort, Chevron, Header } from "./styles";

const HeaderCell = ({
  className,
  direction,
  onClick,
  isActive,
  title,
  id,
  width,
  sortable = false
}) => (
  <Layout id={id} className={className} style={{ width: width || 30 }}>
    {sortable ? (
      <HeaderSort
        sortable={sortable}
        onClick={() => onClick({ type: "SORT", value: id })}
      >
        {title}
        <Chevron
          width={20}
          height={20}
          animate
          isActive={isActive}
          isOpen={isActive && direction === "ASC"}
        />
      </HeaderSort>
    ) : (
      <Header>{title}</Header>
    )}
  </Layout>
);

HeaderCell.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  sortable: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default HeaderCell;
