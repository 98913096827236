import styled, { css } from "styled-components";

export const TreeLayout = styled.div`
  overflow: visible;
  > div {
    overflow: visible;

    > svg {
      overflow: visible;
    }
  }

  svg,
  .node,
  rect:focus {
    outline: none;
  }

  .node rect {
    cursor: pointer;
    fill: ${props => props.theme.serviceTreeNode};
  }

  .icon {
    cursor: pointer;
  }

  .edgePath {
    stroke: ${props => props.strokeColor || props.theme.serviceTreePaths};
    fill: none;
    stroke-width: 2.5px;
    stroke-linecap: round;
  }
  .edgePath.highlight {
    stroke: #4a495e;
  }

  .node:hover .tooltip {
    opacity: 1;
  }

  ${props =>
    props.theme.name === "contrast" &&
    css`
      .badge {
        circle {
          fill: #5f5e70 !important;
        }
        path {
          fill: #fff !important;
        }
      }
    `};
`;
