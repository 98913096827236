import styled from "styled-components";

import { Card } from "Components/Skeleton";

export const Layout = styled(Card)`
  padding: 32px;
`;

export const GraphLayout = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 47px;
  width: 189px;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;
