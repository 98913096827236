import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyVouchersSvg = ({
  uid,
  maincolor,
  bgcolor1,
  bgcolor2,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="118"
      height="101"
      viewBox="0 0 118 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-vouchers-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-vouchers-illustration-${uid}`}>
        Empty vouchers icon
      </title>
      <path
        fill={bgcolor2}
        d="M92.94 87.65c13.53 0 24.499-10.969 24.499-24.5 0-13.53-10.969-24.5-24.5-24.5-13.53 0-24.5 10.97-24.5 24.5 0 13.531 10.97 24.5 24.5 24.5z"
      />
      <path
        fill={bgcolor1}
        d="M23.44 89.65a7 7 0 100-14 7 7 0 000 14zM54.738 21.933l-40.116-4.436-7.04 21.058 9.644-2L29.16 68.611l25.577-46.678z"
      />
      <path
        fill={maincolor}
        d="M80.997 28.05l-5.1 5.1c-.6.6-1.4 1.3-1.9 2-1 1.6-.5 3.3.7 4.6 1.8 1.9 3.8 3.8 5.6 5.6.8.8.8 1.1.8 2.1v46.3c0 1 .1 2.2 0 3.2-.1.8-.9.6-1.6.6h-46.1c-.8 0-1.9.2-2.7 0-.8-.2-.5-1.2-.5-1.9v-31.7-14.7c0-.9-.2-2 0-2.8.1-.5.7-.9 1-1.3l3-3c.8-.8 1.7-1.6 2.4-2.4 1.4-1.5 1.5-3.5.1-5.1-1.6-1.7-3.3-3.3-4.9-4.9-.5-.5-1.5-1.2-1.7-1.9-.2-.9 0-2.2 0-3.2s-.3-2.4 0-3.4c.2-.7.7-.5 1.3-.5h15.4c.6 0 .9.1 1.3.5l3 3c.7.7 1.4 1.5 2.1 2.1 1.5 1.4 3.6 1.3 5-.1 1.8-1.7 3.3-3.7 5.2-5.2.4-.4.7-.3 1.3-.3H80.097c.6 0 1 0 1.1.7.1 1.2 0 2.5 0 3.7v1.9c0 .2 0 .7-.2 1-1.2 1.5.9 3.6 2.1 2.1 1.1-1.3 1-2.5 1-4.1v-4.4c0-1.6-.5-3.2-2.1-3.9-.8-.4-1.6-.3-2.5-.3-5.1 0-10.3-.2-15.4 0-2.5.1-4.3 2.7-6 4.4-.6.6-1.4 1.7-2.1 2.1-.8.4-1.8-1.1-2.3-1.6l-3.2-3.2c-.9-.9-1.8-1.7-3.3-1.8-2.1-.1-4.2 0-6.2 0h-10.6c-2 .1-3.3 1.7-3.3 3.7v4c0 1.2-.2 2.5.2 3.7.4 1.1 1.3 1.8 2.1 2.6l3.3 3.3c.6.6 1.5 1.2 1.9 1.9.4.7-.9 1.6-1.4 2.1-1.7 1.7-3.5 3.3-5.1 5.1-1.1 1.2-1 2.6-1 4.1v47.3c0 1.3-.1 2.6.6 3.7 1 1.601 2.7 1.601 4.3 1.601h47.1c1.3 0 2.7.1 3.8-.8 1.3-1.1 1.3-2.6 1.3-4.1v-5.5-35.9-5.8c0-1.1.2-2.4-.4-3.5-.5-.9-1.4-1.7-2.1-2.4l-3.3-3.3c-.5-.5-1.6-1.2-1.8-1.9-.3-.7 1.1-1.6 1.5-2l5.1-5.1c1.3-1.3-.8-3.4-2.2-2.1z"
      />
      <path
        fill={maincolor}
        d="M39.398 75.55h28c1.9 0 1.9-3 0-3h-28c-1.9 0-1.9 3 0 3zM39.398 82.55h21c1.9 0 1.9-3 0-3h-21c-1.9 0-1.9 3 0 3zM39.398 89.55h32c1.9 0 1.9-3 0-3h-32c-1.9 0-1.9 3 0 3zM41.797 39.15h2.9c.4 0 .8-.2 1.1-.4.3-.3.5-.7.4-1.1 0-.4-.1-.8-.4-1.1-.3-.3-.7-.4-1.1-.4h-2.9c-.4 0-.8.2-1.1.4-.3.3-.5.7-.4 1.1 0 .4.1.8.4 1.1.3.3.7.4 1.1.4zM49.897 39.15h2.9c.4 0 .8-.2 1.1-.4.3-.3.5-.7.4-1.1 0-.4-.1-.8-.4-1.1-.3-.3-.7-.4-1.1-.4h-2.9c-.4 0-.8.2-1.1.4-.3.3-.5.7-.4 1.1 0 .4.1.8.4 1.1.3.3.7.4 1.1.4zM57.997 39.15h2.9c.4 0 .8-.2 1.1-.4.3-.3.5-.7.4-1.1 0-.4-.1-.8-.4-1.1-.3-.3-.7-.4-1.1-.4h-2.9c-.4 0-.8.2-1.1.4-.3.3-.5.7-.4 1.1 0 .4.1.8.4 1.1.4.3.7.4 1.1.4zM66.196 39.15h2.9c.4 0 .8-.2 1.1-.4.3-.3.5-.7.4-1.1 0-.4-.1-.8-.4-1.1-.3-.3-.7-.4-1.1-.4h-2.9c-.4 0-.8.2-1.1.4-.3.3-.5.7-.4 1.1 0 .4.1.8.4 1.1.3.3.7.4 1.1.4zM50.197 54.15a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM61.197 54.15a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM53.498 61.35c.1-.2.2-.3.3-.5.1-.1.1-.2.2-.3.1-.1 0 0 0 0 .1-.2.3-.3.4-.4l.2-.2c-.1.1-.1.1 0 0 .2-.1.3-.2.5-.3.3-.1 0-.1 0 0 0 0 .2-.1.3-.1.1 0 .6-.2.6-.1 0 0-.3 0 0 0h.7c.1 0 .3 0 0 0 .2 0 .4.1.6.1.1 0 .2.1.3.1.2.1-.2-.1 0 0s.3.2.5.3c.1 0 .3.3.1 0 .1 0 .1.1.2.1.2.1.3.3.4.4.1.1-.2-.3 0 0 .1.1.1.2.2.2.1.2.2.4.3.5.3.7 1.4.9 2.1.5.7-.5.9-1.3.5-2.1-.9-2.1-3.1-3.4-5.3-3.4-2.2 0-4.4 1.3-5.3 3.4-.3.7-.2 1.6.5 2.1.3.6 1.4.5 1.7-.3zM61.597 22.15c-.3-2.8-.9-5.5-1.3-8.2-.2-1-.3-1.3.2-2.1.9-1.3 1.8-2.5 2.7-3.8.3-.4.5-.9.9-1.2 0 0 .2-.2.5-.1.2 0 .3.1.4.2l2.1 1.5c2.7 2 5.5 4 8.2 5.9l3 2.1c.6.5.6 2.2.7 3 .1.8 1.1 1.2 1.8 1 .9-.2 1.2-1 1-1.8-.3-1.8-.3-3.5-1.9-4.7-.7-.5-1.3-1-2-1.5-2.9-2.1-5.9-4.2-8.8-6.3-.9-.6-1.7-1.3-2.6-1.9-1.8-1.2-3.8-.6-5 1-.9 1.3-1.9 2.6-2.8 3.9-1 1.3-1.8 2.4-1.5 4.2.2 1.6.5 3.1.8 4.7.2 1 .3 2 .5 3 .1.3.1.6.2.9 0 .1.1.6 0 .2.1 1.9 3.1 1.9 2.9 0z"
      />
      <path
        fill={maincolor}
        d="M57.496 22.85c-1.1.2-2.2.4-3.4.6-.2 0-.3.1-.5.1-.3.1-.7.4-.9.7-.2.3-.3.8-.2 1.2.1.4.3.7.7.9.1.1.2.1.4.2.3.1.5.1.8 0 1.1-.2 2.2-.4 3.4-.6.2 0 .3-.1.5-.1.3-.1.7-.4.9-.7.2-.3.3-.8.2-1.2-.1-.4-.3-.7-.7-.9-.1-.1-.2-.1-.4-.2-.3-.1-.5-.1-.8 0zM82.297 27.25c1.3.2 2.6-.1 3.9-.3 1.5-.3 3.1-.5 4.6-.8.7-.1.9 0 1.4.3.9.6 1.8 1.3 2.7 1.9 2.6 1.9 5.2 3.7 7.8 5.6.8.6 1.7 1.1 2.5 1.8.6.5.1 1-.3 1.6-.9 1.2-1.7 2.4-2.6 3.6-.6.8-.7 1.1-1.6 1.3-2.7.5-5.4.9-8.2 1.3-1.7.3-3.1 1.4-3.3 3.2-.1 1 .2 2.2.4 3.2.3 1.5.5 3.1.8 4.6.1.8.5 1.8.1 2.6s-1.1 1.5-1.6 2.2c-1 1.4-2.1 2.9-3.1 4.3l-4.5 6.3c-1.1 1.6 1.5 3.1 2.6 1.5 3-4.1 6-8.2 9-12.4 1.4-2 .4-4.8.1-7-.2-1.5-.5-3-.7-4.5-.1-.8.3-.9 1.2-1.1 2.8-.4 5.6-.8 8.3-1.3 1.3-.3 2.1-1.1 2.8-2.2.9-1.3 1.9-2.6 2.8-3.9 1-1.3 1.7-2.8.8-4.5-.5-.9-1.3-1.4-2.1-1.9l-7.5-5.4c-1.3-.9-2.6-1.9-3.9-2.8-1.1-.8-2-1.5-3.4-1.4-1.4.1-2.8.4-4.1.7-.9.1-1.7.3-2.6.4-.4.1-1 .2-1.4.2-2-.3-2.8 2.6-.9 2.9zM29.396 76.15c-1.6-1.1-3.1-2.3-4.7-3.4-1.1-.8-2.3-1.6-3.4-2.5-.5-.3-1.3-.7-1.7-1.2-.5-.7 1.6-2.9 2.1-3.5 1.6-2.3 3.3-4.6 4.9-6.8 1.1-1.5 2.2-3.1 3.3-4.6 1.1-1.6-1.5-3.1-2.6-1.5-2.8 3.8-5.5 7.6-8.3 11.5-1.1 1.5-2.9 3.3-2.5 5.4.5 2.2 3.5 3.6 5.1 4.8 2 1.5 4.1 3 6.1 4.4 1.8 1.1 3.3-1.5 1.7-2.6z"
      />
    </svg>
  );
};

EmptyVouchersSvg.propTypes = {
  bgcolor1: PropTypes.string,
  bgcolor2: PropTypes.string,
  maincolor: PropTypes.string,
  highlightClass: PropTypes.string,
  uid: PropTypes.number
};

const EmptyVouchers = props => (
  <Illustration {...props}>
    <EmptyVouchersSvg />
  </Illustration>
);

export default EmptyVouchers;
