import styled from "styled-components";

import BaseHeader from "../Header";
import BaseParagraph from "../Paragraph";

export const Layout = styled.div`
  text-align: center;
  padding-top: 64px;
`;

export const Header = styled(BaseHeader)`
  margin-top: 64px;
`;

export const Paragraph = styled(BaseParagraph)`
  max-width: 660px;
  margin: 0 auto;
`;
