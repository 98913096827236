import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const TickIcon = ({ color = "#4A495E" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`tick-icon-${uid}`}
    >
      <title id={`tick-icon-${uid}`}>Tick icon</title>
      <path
        d="M9.9998 17.1004C9.6998 17.1004 9.4998 17.0004 9.29981 16.8004L5.0998 12.6004C4.6998 12.2004 4.6998 11.6004 5.0998 11.2004C5.4998 10.8004 6.0998 10.8004 6.4998 11.2004L9.9998 14.7004L17.4998 7.20039C17.8998 6.80039 18.4998 6.80039 18.8998 7.20039C19.2998 7.60039 19.2998 8.20039 18.8998 8.60039L10.6998 16.8004C10.4998 17.0004 10.2998 17.1004 9.9998 17.1004Z"
        fill={color}
      />
    </svg>
  );
};

TickIcon.propTypes = {
  color: PropTypes.string
};

export default TickIcon;
