import styled, { css } from "styled-components";

export const Layout = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  text-transform: capitalize;
  border-radius: 2px;
  white-space: nowrap;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  align-items: center;

  background-color: ${({ status }) => {
    switch (status) {
      case "deleted":
      case "closed":
      case "solved":
      case "hold":
        return "rgba(201, 208, 229, 0.16)";
      case "new":
        return "rgba(0, 200, 156, 0.16)";
      case "open":
        return "rgba(71, 134, 255, 0.16)";
      case "pending":
        return "rgba(255, 131, 89, 0.16)";
      default:
        return "transparent";
    }
  }};

  color: ${({ status, theme }) => {
    switch (status) {
      case "deleted":
      case "closed":
        return "#98A0AB";
      case "solved":
      case "hold":
        return theme?.name === "dark" ? "#C9D0E4" : "#4B6180";
      case "new":
        return "#23b7b7";
      case "open":
        return "#4786FF";
      case "pending":
        return "#FF8359";
      default:
        return theme.sectionText;
    }
  }};

  ${props =>
    props.plain &&
    css`
      background-color: transparent;
      color: #302f45;
      padding: 0;
      height: auto;
      font-weight: bold;
    `};
`;
