import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const CheckMark = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`check-mark-icon-${uid}`}
    >
      <title id={`check-mark-icon-${uid}`}>Check mark icon</title>
      <path
        d="M1 4.99976L5.24266 9.2424L13.0209 1.46422"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CheckMark.propTypes = {
  color: PropTypes.string
};

export default CheckMark;
