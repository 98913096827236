import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid ${props => props.theme.background};

  &.no-screenshot .with-image {
    background: #5f5e70;
    img {
      object-fit: contain;
    }
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  svg {
    width: 40%;
    height: auto;
    margin: 20px auto;
  }
  &.without-image {
  }
`;

export const NoImage = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.screenshotBg};
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.screenshotTxt};
`;
