import styled from "styled-components";

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 16px;
  color: ${props => props.color || "#98a0ab"};
  &:first-letter {
    text-transform: capitalize;
  }

  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Description;
