import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyBackupsSvg = ({
  uid,
  maincolor,
  bgcolor1,
  bgcolor2,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => {
  return (
    <svg
      width="140"
      height="108"
      viewBox="0 0 140 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`empty-backups-illustration-${uid}`}
      {...otherProps}
    >
      <title id={`empty-backups-illustration-${uid}`}>Empty backups</title>

      <path d="M66 23L93 64L125 54L127 21L101 30L84 0L66 23Z" fill={bgcolor1} />
      <path
        d="M46.6 32.3898C53.7908 32.6729 60.6113 35.6536 65.7036 40.7385C70.7959 45.8234 73.7865 52.6395 74.08 59.8298C74.1379 61.4265 74.0644 63.0252 73.86 64.6098C73.6519 66.1617 73.9085 67.7404 74.5973 69.1466C75.2861 70.5527 76.3763 71.7231 77.73 72.5098C79.9787 73.7874 81.8955 75.5756 83.3259 77.7303C84.7563 79.885 85.6601 82.3457 85.9646 84.9139C86.269 87.4822 85.9654 90.086 85.0784 92.5153C84.1914 94.9447 82.7457 97.1315 80.858 98.8994C78.9704 100.667 76.6936 101.967 74.2113 102.693C71.7291 103.419 69.111 103.551 66.5682 103.079C64.0254 102.607 61.6292 101.544 59.5728 99.9756C57.5164 98.4072 55.8576 96.3773 54.73 94.0498C54.0482 92.5911 52.951 91.366 51.5759 90.5282C50.2008 89.6903 48.609 89.2769 47 89.3398H46.58C39.0213 89.3398 31.7723 86.3372 26.4275 80.9924C21.0827 75.6476 18.08 68.3985 18.08 60.8398C18.08 53.2812 21.0827 46.0321 26.4275 40.6873C31.7723 35.3425 39.0213 32.3398 46.58 32.3398L46.6 32.3898Z"
        fill={bgcolor2}
      />
      <path
        d="M107 42.4999H38.21C36.88 42.4999 34.63 42.9299 33.36 42.4999C29.66 41.2599 30.5 37.4999 30.5 34.4199C30.5 30.8999 29.75 26.4999 30.5 23.0699C31.63 17.9399 37.5 19.4999 41.5 19.4999H101.19C102.71 19.4999 105.19 19.0099 106.59 19.4999C110.59 20.8499 109.45 25.7199 109.45 29.0199C109.45 31.9699 111.2 41.7999 106.55 42.5499C104.65 42.8599 105.46 45.7499 107.35 45.4499C111.47 44.7799 112.42 41.7199 112.45 38.0499C112.45 33.4599 112.45 28.8599 112.45 24.2699C112.45 20.5499 111.65 17.2099 107.35 16.5499C106.143 16.4432 104.93 16.4265 103.72 16.4999H50C44.48 16.4999 38.73 16.0299 33.22 16.4999C25.12 17.1799 27.5 28.3699 27.5 33.9499C27.5 39.8699 27.34 45.3999 34.69 45.4999C41.74 45.5999 48.79 45.4999 55.83 45.4999H107C107.398 45.4999 107.779 45.3419 108.061 45.0606C108.342 44.7793 108.5 44.3977 108.5 43.9999C108.5 43.6021 108.342 43.2205 108.061 42.9392C107.779 42.6579 107.398 42.4999 107 42.4999Z"
        fill={maincolor}
      />
      <path
        d="M38.55 28.49C39.9252 28.49 41.04 27.3752 41.04 26C41.04 24.6248 39.9252 23.51 38.55 23.51C37.1748 23.51 36.06 24.6248 36.06 26C36.06 27.3752 37.1748 28.49 38.55 28.49Z"
        fill={maincolor}
      />
      <path
        d="M38.55 38.49C39.9252 38.49 41.04 37.3752 41.04 36C41.04 34.6248 39.9252 33.51 38.55 33.51C37.1748 33.51 36.06 34.6248 36.06 36C36.06 37.3752 37.1748 38.49 38.55 38.49Z"
        fill={maincolor}
      />
      <path
        d="M91.13 78.4999C96.4 78.4999 102.2 79.2399 107.4 78.4999C114.53 77.4099 112.5 67.6898 112.5 62.5598C112.5 57.4298 113.82 50.2199 106.93 49.5599C103.36 49.2099 99.58 49.5599 95.99 49.5599H49.15C44.15 49.5599 39.2 49.3899 34.24 49.5599C29.48 49.7199 27.53 52.3998 27.5 57.0098C27.5 61.2198 27.5 65.4399 27.5 69.6599C27.5 72.4699 27.16 75.5599 29.73 77.4699C32.05 79.1799 35.81 78.5598 38.5 78.5598H48.87C49.2678 78.5598 49.6493 78.4018 49.9307 78.1205C50.212 77.8392 50.37 77.4577 50.37 77.0598C50.37 76.662 50.212 76.2805 49.9307 75.9992C49.6493 75.7179 49.2678 75.5598 48.87 75.5598H42.87C40.08 75.5598 36.36 76.2398 33.65 75.5598C28.34 74.2298 30.47 63.8499 30.47 59.7299C30.47 57.1799 29.63 53.6199 33.04 52.5599C33.8168 52.4598 34.6032 52.4598 35.38 52.5599H102.29C105.64 52.5599 108.56 51.7999 109.47 56.1299C110.07 58.9799 109.47 62.6399 109.47 65.5499C109.47 68.4599 110.73 74.2799 106.89 75.5499C104.77 76.2599 101.26 75.5499 99.04 75.5499H91.13C90.7322 75.5499 90.3506 75.7079 90.0693 75.9892C89.788 76.2705 89.63 76.652 89.63 77.0499C89.63 77.4477 89.788 77.8292 90.0693 78.1105C90.3506 78.3918 90.7322 78.5499 91.13 78.5499V78.4999Z"
        fill={maincolor}
      />
      <path
        d="M38.55 61.49C39.9252 61.49 41.04 60.3752 41.04 59C41.04 57.6248 39.9252 56.51 38.55 56.51C37.1748 56.51 36.06 57.6248 36.06 59C36.06 60.3752 37.1748 61.49 38.55 61.49Z"
        fill={maincolor}
      />
      <path
        d="M38.55 71.49C39.9252 71.49 41.04 70.3752 41.04 69C41.04 67.6248 39.9252 66.51 38.55 66.51C37.1748 66.51 36.06 67.6248 36.06 69C36.06 70.3752 37.1748 71.49 38.55 71.49Z"
        fill={maincolor}
      />
      <path
        d="M56.06 81.0602L71.06 68.7902H68.94L83.94 81.0602C85.42 82.2701 87.56 80.1602 86.06 78.9402L71.06 66.6702C70.7706 66.4052 70.3924 66.2583 70 66.2583C69.6076 66.2583 69.2295 66.4052 68.94 66.6702L53.94 78.9402C52.44 80.1602 54.58 82.2701 56.06 81.0602Z"
        fill={maincolor}
      />
      <path
        d="M107.5 94H80.2C76.65 94 72.38 94.81 71.5 90.3C70.67 86.04 71.5 80.86 71.5 76.5C71.5 76.1022 71.3419 75.7206 71.0606 75.4393C70.7793 75.158 70.3978 75 70 75C69.6021 75 69.2206 75.158 68.9393 75.4393C68.658 75.7206 68.5 76.1022 68.5 76.5C68.5 81.38 68.09 86.44 68.5 91.3C68.91 96.16 72.5 97 76.69 97H107.5C107.898 97 108.279 96.842 108.561 96.5607C108.842 96.2794 109 95.8978 109 95.5C109 95.1022 108.842 94.7206 108.561 94.4393C108.279 94.158 107.898 94 107.5 94Z"
        fill={maincolor}
      />
    </svg>
  );
};

EmptyBackupsSvg.propTypes = {
  bgcolor1: PropTypes.string,
  bgcolor2: PropTypes.string,
  maincolor: PropTypes.string,
  uid: PropTypes.number,
  highlightClass: PropTypes.string
};

const EmptyBackups = props => (
  <Illustration {...props}>
    <EmptyBackupsSvg />
  </Illustration>
);

export default EmptyBackups;
