import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  .user {
    width: 75%;
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
    }
  }
  .role {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    color: ${props => props.theme.settingBarLabel};
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
    &.account-owner {
      display: inline-flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

export const StateLabel = styled.span`
  padding: 8px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background: #f0f2f5;
  color: #4a495e;
`;

export const Role = styled.span`
  display: inline-block;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  &:first-letter {
    text-transform: uppercase;
  }
  color: #98a0ab;
`;

export const Email = styled.span`
  font-weight: normal;
  font-size: 15px;
  color: #98a0ab;
`;

export const DisplayName = Email;

export const SettingLineLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  padding-right: 40px;
`;
