import React from "react";

import useUniqueId from "Libs/useUniqueId";

const PaperclipIcon = props => {
  const uid = useUniqueId();

  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`paperclip-icon-${uid}`}
      {...props}
    >
      <title id={`paperclip-icon-${uid}`}>Paperclip Icon</title>
      <path d="M1.66495 18.2127C1.56495 18.1127 1.36495 17.9127 1.26495 17.8127C-0.63505 15.5127 -0.33505 12.1127 1.76495 9.91268L10.4649 1.21268C11.2649 0.412679 12.465 -0.0873207 13.665 0.0126793C14.865 0.112679 15.965 0.612679 16.765 1.51268C18.165 3.21268 17.965 5.71268 16.365 7.31268L9.06495 14.6127C8.56495 15.1127 7.76495 15.4127 6.96495 15.4127C6.16495 15.4127 5.46495 15.0127 4.96495 14.4127C4.06495 13.3127 4.16495 11.7127 5.26495 10.6127L11.365 4.51268C11.7649 4.11268 12.365 4.11268 12.7649 4.51268C13.1649 4.91268 13.1649 5.51268 12.7649 5.91268L6.56495 12.1127C6.26495 12.4127 6.26495 12.9127 6.56495 13.2127C6.86495 13.5127 7.36495 13.5127 7.66495 13.2127L15.0649 5.81268C15.4649 5.41268 15.7649 4.71268 15.7649 4.11268C15.7649 3.51268 15.4649 2.91268 14.9649 2.51268C14.0649 1.81268 12.7649 1.91268 11.865 2.81268L3.06495 11.5127C2.36495 12.2127 1.96495 13.3127 1.96495 14.3127C2.06495 15.4127 2.46495 16.3127 3.36495 17.0127C4.86495 18.2127 7.06495 18.1127 8.56495 16.6127L16.065 9.21268C16.465 8.81268 17.065 8.81268 17.465 9.21268C17.865 9.61268 17.865 10.2127 17.465 10.6127L9.86495 18.2127C8.66495 19.4127 7.06495 20.0127 5.46495 19.9127C4.06495 19.8127 2.76495 19.2127 1.66495 18.2127Z" />
    </svg>
  );
};

PaperclipIcon.propTypes = {};

export default PaperclipIcon;
