import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import {
  CLI_COMMAND_NAME,
  DOCS_CLI_REPO_URL
} from "Constants/documentationUrls";
import { getEnvironmentGitCommand } from "Libs/utils";

import ActionDropdown from "Components/ActionDropdown";
import CopyableArea from "Components/CopyableArea";
import EnvironmentRoutes from "Containers/EnvironmentRoutes";

import * as S from "./styles";

const EnvironmentButtons = ({ project }) => {
  const intl = useIntl();
  const { organizationId, environmentId } = useDecodedParams();

  const environment = useSelector(state =>
    state.environment?.getIn([
      "data",
      organizationId,
      project.id,
      environmentId
    ])
  );

  const sshUrls = environment ? environment.getSshUrls() : {};
  const sshCommands =
    sshUrls &&
    Object.keys(sshUrls).map(key => sshUrls[key].replace("ssh://", "ssh "));
  const cliCommand = getEnvironmentGitCommand(
    project,
    environment,
    CLI_COMMAND_NAME,
    environment ? "git" : "git-project"
  );
  const platformCliCommand = getEnvironmentGitCommand(
    project,
    environment,
    CLI_COMMAND_NAME,
    environment ? "cli" : "cli-project"
  );

  return (
    <S.Layout aria-label="Environment buttons">
      <S.Wrapper className="info-buttons">
        {environment && (
          <ActionDropdown
            id="environment-urls"
            label={intl.formatMessage({ id: "urls" })}
            className={environment.status === "inactive" ? "disabled" : ""}
            status={environment.status}
          >
            <EnvironmentRoutes
              projectId={project.id}
              organizationId={organizationId}
              environmentId={environmentId}
              hideRedirects
            />
          </ActionDropdown>
        )}
        <ActionDropdown id="environment-git" label="GIT">
          {cliCommand && (
            <CopyableArea
              id="environment-git-cmd"
              content={cliCommand}
              singleLine={true}
            >
              {cliCommand}
            </CopyableArea>
          )}
        </ActionDropdown>
        <ActionDropdown id="environment-cli" label="CLI">
          {platformCliCommand && (
            <CopyableArea
              id="environment-cli-cmd"
              content={platformCliCommand}
              singleLine={true}
            >
              {platformCliCommand}
            </CopyableArea>
          )}
          <S.DownloadWrapper>
            <a
              onClick={e => {
                e.stopPropagation();
              }}
              href={DOCS_CLI_REPO_URL}
              tabIndex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Install CLI
            </a>
          </S.DownloadWrapper>
        </ActionDropdown>
        {environment &&
          sshCommands.length > 0 && (
            <ActionDropdown
              id="environment-ssh"
              label="SSH"
              className={environment.status === "inactive" ? "disabled" : ""}
              status={environment.status}
            >
              {sshCommands.map((ssh, index) => (
                <CopyableArea
                  id={`environment-ssh-cmd-${index}`}
                  key={ssh}
                  content={ssh}
                  singleLine={true}
                >
                  {ssh}
                </CopyableArea>
              ))}
            </ActionDropdown>
          )}
      </S.Wrapper>
    </S.Layout>
  );
};

EnvironmentButtons.propTypes = {
  project: PropTypes.object.isRequired
};

export default EnvironmentButtons;
