import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const IconSort = ({ color = "#4A495E", up }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`sort-icon-${uid}`}
    >
      <title id={`sort-icon-${uid}`}>Sort icon</title>
      {!up && (
        <style>{`.icon-sort-direction-${uid}{
          transform-origin: center center;
          transform: rotate(180deg);}`}</style>
      )}
      <g className={`icon-sort-direction-${uid}`}>
        <path
          d="M11.2,9.2c0.4,0.4,1,0.4,1.4,0l1.8-1.8v7.9c0,0.6,0.4,1,1,1s1-0.4,1-1V7.4l1.8,1.8c0.4,0.4,1,0.4,1.4,0
	s0.4-1,0-1.4l-3.5-3.5C16,4.1,15.7,4,15.5,4S15,4.1,14.8,4.3l-3.5,3.5C10.8,8.2,10.8,8.9,11.2,9.2z"
          fill={color}
        />
        <path
          d="M10.8,15.8c-0.4-0.4-1-0.4-1.4,0l-0.8,0.8v-3.9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3.9l-0.8-0.8c-0.4-0.4-1-0.4-1.4,0
  c-0.4,0.4-0.4,1,0,1.4l2.5,2.5C7,19.9,7.3,20,7.5,20s0.5-0.1,0.7-0.3l2.5-2.5C11.2,16.8,11.2,16.1,10.8,15.8z"
          fill={color}
        />
      </g>
    </svg>
  );
};

IconSort.propTypes = {
  color: PropTypes.string,
  up: PropTypes.bool
};

export default IconSort;
