import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const GridIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      height="24"
      width="24"
      role="img"
      aria-labelledby={`grid-icon-${uid}`}
    >
      <title id={`grid-icon-${uid}`}>Grid icon</title>
      <defs>
        <clipPath id={`icon-grid-a-${uid}`}>
          <path
            d="M12.2,5V9.8H17V5Zm0,12H17V12.2H12.2ZM5,5H9.8V9.8H5ZM5,17V12.2H9.8V17Z"
            style={{ fill: "none", clipRule: "evenodd" }}
          />
        </clipPath>
        <clipPath id={`icon-grid-b-${uid}`}>
          <rect width="22" height="22" style={{ fill: "none" }} />
        </clipPath>
        <clipPath id={`icon-grid-c-${uid}`}>
          <rect x="5" y="5" width="12" height="12" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: `url(#icon-grid-a-${uid})` }}>
        <g style={{ isolation: "isolate" }}>
          <g style={{ clipPath: `url(#icon-grid-b-${uid})` }}>
            <g style={{ clipPath: `url(#icon-grid-c-${uid})` }}>
              <rect width="22" height="22" style={{ fill: color }} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

GridIcon.propTypes = {
  color: PropTypes.string
};

export default GridIcon;
