import React from "react";
import PropTypes from "prop-types";
import { Layout } from "./styles";

const EmptyState = props => {
  return <Layout {...props}>{props.children}</Layout>;
};

EmptyState.propTypes = {
  children: PropTypes.node
};

export default EmptyState;
