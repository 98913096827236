import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const Bug = ({ color = "#FFFFFF" }) => {
  const uid = useUniqueId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby={`bug-icon-${uid}`}
    >
      <title id={`bug-icon-${uid}`}>Bug icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M18 10.2H18.4C18.9 10.2 19.3 9.8 19.3 9.3C19.3 8.8 18.9 8.4 18.4 8.4H16.8C16.5 8 16.2 7.5 15.9 7.2C15.6 6.9 15.6 6.3 15.9 6L16 5.9C16.3 5.6 16.3 5 16 4.7C15.7 4.4 15.1 4.4 14.8 4.7L13.4 6C13 5.9 12.6 5.8 12.2 5.8C11.8 5.8 11.3 5.9 11 6L9.5 4.6C9.2 4.3 8.6 4.3 8.3 4.6C7.9 5 7.9 5.5 8.3 5.9L8.4 6C8.7 6.3 8.7 6.9 8.4 7.2C8.1 7.6 7.8 8 7.5 8.4H5.9C5.4 8.4 5 8.8 5 9.3C5 9.8 5.4 10.2 5.9 10.2H6.9C6.8 10.5 6.8 10.8 6.8 11.1V12H5.9C5.4 12 5 12.4 5 12.9C5 13.4 5.4 13.8 5.9 13.8H6.8V14.7C6.8 15 6.8 15.3 6.9 15.6H5.9C5.4 15.6 5 16 5 16.5C5 17 5.4 17.4 5.9 17.4H7.5C8.4 19 10.2 20.1 12.1 20.1C14.1 20.1 15.8 19 16.7 17.4H18.3C18.8 17.4 19.2 17 19.2 16.5C19.2 16 18.8 15.6 18.3 15.6H17.3C17.4 15.3 17.4 15 17.4 14.7V13.8H18.3C18.8 13.8 19.2 13.4 19.2 12.9C19.2 12.4 18.8 12 18.3 12H17.4V11.1C17.4 10.8 17.4 10.5 17.3 10.2H18ZM13.9 14.7C13.9 15.2 13.5 15.6 13 15.6H11.7H11.2C10.7 15.6 10.3 15.2 10.3 14.7C10.3 14.2 10.7 13.8 11.2 13.8H12.3H13C13.5 13.8 13.9 14.2 13.9 14.7ZM13.9 11.1C13.9 11.6 13.5 12 13 12H12.1H11.8H11.2C10.7 12 10.3 11.6 10.3 11.1C10.3 10.6 10.7 10.2 11.2 10.2H11.7H11.8H12.1H13C13.5 10.2 13.9 10.6 13.9 11.1Z"
      />
    </svg>
  );
};

Bug.propTypes = {
  color: PropTypes.string
};

export default Bug;
