import styled from "styled-components";

import AccessibleTooltip from "Components/AccessibleTooltip";

export const Wrapper = styled.div`
  margin: 24px 0 0 0;
  border: 1px solid ${props => props.theme.filePreviewerHeaderBg};
  pre {
    code {
      padding-left: 4px;
      background: inherit;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  margin: 0;
  padding: 0 16px;
  background: ${props => props.theme.filePreviewerHeaderBg};
  line-height: 40px;
  h1 {
    margin: 0;
    font-size: 12px;
    color: ${props => props.theme.filePreviewerHeaderTitle};
    font-family: "Overpass Mono", monospace;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-self: center;
  margin: 0 0 0 auto;
`;

export const Tooltip = styled(AccessibleTooltip)`
  border: 1px solid transparent;
  border-radius: 2px;
  &:focus {
    outline: none;
    border-color: ${props => props.theme.filePreviewerBtnIco};
  }
`;

export const Button = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: none;
  text-align: center;
  background: ${({ active, theme }) =>
    active ? theme.filePreviewerBtnBgActive : "none"};

  svg {
    max-width: 18px;
    max-height: 20px;
    g path {
      fill: ${({ active, theme }) =>
        active ? theme.filePreviewerBtnIcoHover : theme.filePreviewerBtnIco};
    }
  }

  &:hover {
    background: ${({ active, theme }) =>
      active ? theme.filePreviewerBtnBgActive : theme.filePreviewerBtnBgHover};
    svg g path {
      fill: ${props => props.theme.filePreviewerBtnIcoHover};
    }
  }
`;
