import styled from "styled-components";

export const Layout = styled.div`
  text-align: center;
  padding-bottom: 8px;

  .loading {
    padding-top: 0;
  }
`;
