import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Wrapper = styled.aside`
  display: flex;
  width: 200px;
  height: auto;
  max-height: 100%;
  min-height: auto;
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: left;
  background: transparent;
  flex-shrink: 0;
  justify-content: space-between;
  touch-callout: none;
  user-select: none;
  @media (min-width: 1280px) {
    width: 274px;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0;
  flex-shrink: 2;
  justify-content: space-between;
  .link-button {
    margin-top: -4px;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      padding: 0;
    }
  }
  .tab {
    text-align: left;
    ${semiBoldAlias};
    line-height: 48px;
    padding: 0 24px;
    color: ${props => props.theme.sideNavText};
    width: 100%;
    display: block;
    box-sizing: border-box;
    height: auto;
    font-size: 15px;
    margin-bottom: 4px;
    border-radius: 4px;
    text-transform: capitalize;
    letter-spacing: 0;
    &.active,
    &:hover,
    &:active {
      background: ${props => themeHelpers(props.theme, "sectionColor")};
      box-shadow: 0 4px 8px
        ${props =>
          props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
    }
    &:focus {
      outline: none;
      border: 1px solid #1664ff;
      border-radius: 4px;
      box-shadow: 0 0 2px #1664ff;
      margin: -1px 0 5px -2px;
    }
    &.active,
    &:active {
      color: ${props => props.theme.links} !important;
      border: none;
      margin: 0 0 4px 0;
      box-shadow: 0 4px 8px
        ${props =>
          props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
    }
    &.tab-access,
    &.tab-variables {
      padding-left: 25px;
    }
  }
`;
