import React from "react";

import useUniqueId from "Libs/useUniqueId";

const NoTicketsIcon = () => {
  const uid = useUniqueId();

  return (
    <svg
      width="140"
      height="108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`no-tickets-light-icon-${uid}`}
    >
      <title id={`no-tickets-light-icon-${uid}`}>No Tickets icon</title>
      <path
        d="M29.805 16a7 7 0 100-14 7 7 0 000 14zM77.305 66.61l12 20 22-3 6-14-18.02-.3 18.02-38.7-44-28-25.5 16.5 38 25-8.5 22.5zM40.805 89.61c13.53 0 24.5-10.969 24.5-24.5 0-13.53-10.97-24.5-24.5-24.5-13.531 0-24.5 10.97-24.5 24.5 0 13.531 10.969 24.5 24.5 24.5z"
        fill="#EAE5E7"
      />
      <path
        d="M50.105 30.16h41.44a1.5 1.5 0 000-3h-41.44a1.5 1.5 0 100 3zM50.105 42.13h17.52a1.5 1.5 0 000-3h-17.52a1.5 1.5 0 100 3z"
        fill="#1E1E21"
      />
      <path
        d="M103.805 13.11v43.75l1.5-1.5h-83c-2.23 0-2.61.53-3-1.63a9.442 9.442 0 010-3.31c.49-1.25.51-.39 1.5-1.2a4.12 4.12 0 002-3.38c.25-1.711.25-3.45 0-5.16a3.83 3.83 0 00-.65-1.73c-.68-.88-2-.7-2.55-1.3-.55-.6-.63-4.76-.23-5.43.65-1.09 2.43-.83 3.17-2.37 1.07-2.24.89-7.72-1.16-9.34-.55-.43-1.52-.27-1.9-.78-.38-.51-.18-2.32-.18-2.9.07-1.45-.18-1.7.77-2.22 1.28-.7 5 0 6.38 0h78.85a1.498 1.498 0 001.5-1.5 1.498 1.498 0 00-1.5-1.5h-77.35c-3.72 0-10.89-1.59-11.65 3.44a14.88 14.88 0 00.05 5.07c.63 2.35 2.54 1.78 3.41 3.25.72 1.2 0 4.75-.63 5.49-.47.55-1.56.47-2.15 1.24a4.07 4.07 0 00-.68 2.12 19.274 19.274 0 000 5.2 4 4 0 00.68 1.94c.65.84 2.1.73 2.55 1.29.45.56.61 5 .11 5.65-1.08 1.48-2.76.64-3.31 3.24-.63 2.93-.7 7.89 2.8 8.76 1.326.172 2.669.192 4 .06h82.17a1.52 1.52 0 001.5-1.5V13.11a1.498 1.498 0 00-1.5-1.5 1.5 1.5 0 00-1.5 1.5z"
        fill="#1E1E21"
      />
      <path
        d="M31.305 56.69V53.8a1.5 1.5 0 00-3 0v2.89a1.5 1.5 0 103 0zM31.305 48.55v-2.88a1.5 1.5 0 10-3 0v2.88a1.5 1.5 0 103 0zM31.305 40.41v-2.88a1.5 1.5 0 10-3 0v2.88a1.5 1.5 0 103 0zM31.305 32.27v-2.88a1.5 1.5 0 10-3 0v2.88a1.5 1.5 0 103 0zM31.305 24.14v-2.89a1.5 1.5 0 10-3 0v2.89a1.5 1.5 0 103 0zM31.305 16v-2.89a1.5 1.5 0 10-3 0V16a1.5 1.5 0 103 0z"
        fill="#1E1E21"
      />
      <path
        d="M104.005 24.66l16.38 36 .54-2.06-52.84 24.01-14.85 6.78c-2.87 1.31-6 3.31-9 4.12-1.13.3-.8.71-1.65.12-.57-.38-1.47-2.9-1.58-3.46-.32-1.73.2-1.11 1-2.7 1.47-2.84-1.61-8.79-4.42-9.76-1.67-.59-1.6.38-2.86-1.3-.39-.52-1.7-2.79-1.58-3.47.26-1.43 2-1.41 2-3.28a5.58 5.58 0 00-.66-2.05c-.64-1.72-1.6-4.81-3.42-5.67-1.5-.7-2.24.12-3.43-1a9.17 9.17 0 01-1.62-4c-.22-1.89-3.23-1.92-3 0a14.6 14.6 0 002.29 6.05c1.58 2.22 3.68 1.11 5.06 2.75.4.48 1.71 3 1.63 3.57-.14 1-1.6 1.63-1.84 2.93-.34 1.8 1.3 4.55 2.1 6a4.86 4.86 0 001.61 2.24c.91.53 2.17-.07 2.8.26 1.11.58 1.39 1.9 1.9 3 1 2.14.41 1.5-.62 3.4-1.43 2.62 1.52 9.67 4.76 9.88 1.82.12 4.23-1.53 5.78-2.24l13-5.89 36.6-16.64 24.39-11.09a1.537 1.537 0 00.54-2.06l-16.38-36c-.8-1.75-3.39-.23-2.6 1.52l-.03.04z"
        fill="#1E1E21"
      />
      <path
        d="M54.185 90.25l-1.2-2.64a1.51 1.51 0 00-2-.54 1.53 1.53 0 00-.54 2l1.19 2.63a1.52 1.52 0 002.06.54 1.56 1.56 0 00.54-2.06l-.05.07zM50.815 82.85l-1.2-2.63a1.51 1.51 0 00-2.05-.54 1.53 1.53 0 00-.54 2.05l1.19 2.63a1.52 1.52 0 002.06.54 1.54 1.54 0 00.54-2.05zM47.445 75.44l-1.2-2.63a1.5 1.5 0 00-2-.54 1.53 1.53 0 00-.54 2l1.2 2.63a1.5 1.5 0 002.05.54 1.54 1.54 0 00.54-2h-.05zM44.075 68.03l-1.2-2.63a1.5 1.5 0 00-2-.54 1.54 1.54 0 00-.57 2.06l1.2 2.62a1.5 1.5 0 002 .54 1.54 1.54 0 00.54-2l.03-.05zM40.705 60.61l-1.2-2.63a1.51 1.51 0 00-2.05-.53 1.53 1.53 0 00-.54 2.05l1.2 2.62a1.5 1.5 0 002 .54 1.54 1.54 0 00.54-2l.05-.05z"
        fill="#1E1E21"
      />
      <path d="M77.305 101.61a7 7 0 100-14 7 7 0 000 14z" fill="#EAE5E7" />
    </svg>
  );
};

export default NoTicketsIcon;
