import React, { useState } from "react";
import PropTypes from "prop-types";
import copyToClipboard from "copy-to-clipboard";
import { useIntl } from "react-intl";

import SyntaxHighlighter from "react-syntax-highlighter";
import theme from "./theme";

import CopyIcon from "Icons/CopyIcon";
import DownloadIcon from "Icons/DownloadIcon";

import * as S from "./styles";

const FilePreviewer = ({ content, filename }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const intl = useIntl();

  const handleCopy = () => {
    copyToClipboard(content);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleDownload = () => {
    setIsDownloaded(true);
    const blob = new Blob([content], {
      type: "text/yaml"
    });
    const blobUrl = window.URL.createObjectURL(blob);
    const fileLink = document.createElement("a");
    fileLink.href = blobUrl;
    fileLink.download = filename.split("/").pop();
    document.body.appendChild(fileLink);

    fileLink.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(fileLink);
    setTimeout(() => setIsDownloaded(false), 2000);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <h1>{filename}</h1>

        <S.Buttons>
          <S.Tooltip
            tooltipProps={{
              id: "copy-config",
              children: isCopied
                ? intl.formatMessage({ id: "icons.copied" })
                : intl.formatMessage({ id: "icons.copy" })
            }}
          >
            <S.Button
              aria-label="Copy to clipboard"
              onClick={handleCopy}
              active={isCopied}
            >
              <CopyIcon aria-hidden="true" />
            </S.Button>
          </S.Tooltip>

          <S.Tooltip
            tooltipProps={{
              id: "download-config",
              children: isDownloaded
                ? intl.formatMessage({ id: "icons.downloaded" })
                : intl.formatMessage({ id: "icons.download" })
            }}
          >
            <S.Button
              aria-label="Download file"
              onClick={handleDownload}
              active={isDownloaded}
            >
              <DownloadIcon aria-hidden="true" />
            </S.Button>
          </S.Tooltip>
        </S.Buttons>
      </S.Header>
      <SyntaxHighlighter
        language="yaml"
        style={theme}
        showLineNumbers={true}
        showInlineLineNumbers={true}
        wrapLines={true}
        lineNumberStyle={{}}
      >
        {content}
      </SyntaxHighlighter>
    </S.Wrapper>
  );
};

FilePreviewer.propTypes = {
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired
};
export default FilePreviewer;
