import React from "react";
import { Link } from "react-router-dom";
import ActivityTitle from "./Activity/ActivityTitle";
import { getRouteUrl } from "Libs/utils";
import BackupIcon from "Icons/BackupIcon";
import BranchIcon from "Icons/BranchIcon";
import CronIcon from "Icons/CronIcon";
import MergeIcon from "Icons/MergeIcon";
import PushIcon from "Icons/PushIcon";
import SyncIcon from "Icons/SyncIcon";
import SystemIcon from "Icons/SystemIcon";
import UserIcon from "Icons/UserIcon";
import RedeployIcon from "Icons/RedeployIcon";
import ShieldIcon from "Icons/ShieldIcon";
import {
  Bitbucket,
  GitHub,
  Gitlab,
  Script,
  Email,
  Pagerduty,
  Slack,
  Webhook
} from "Icons/integrations";

// When passed a description prop, <ActivityTitle /> displays that
// and ignores every other prop, we use that on some activities where
// we get the description in the payload
const descriptionTitle = _props => (
  <ActivityTitle name="" description={_props.activity.data.description} />
);

const getProjectURL = _props =>
  `/${_props.organizationId}/${_props.activity.project}`;

// eslint-disable-next-line
const getLinkTo = k => _props => {
  const projectURL = getProjectURL(_props);
  const linkURL = `${projectURL}/${encodeURIComponent(
    _props.activity.payload[k].id
  )}`;
  const linkTitle = _props.activity.payload[k].title;
  return <Link to={linkURL}>{linkTitle}</Link>;
};

const getLinkToNewProject = _props => {
  const linkURL = getProjectURL(_props);
  const linkTitle = _props.activity.payload.outcome.title;
  return <Link to={linkURL}>{linkTitle}</Link>;
};

const getLinkToParentEnv = getLinkTo("parent");
const getLinkToEnvironment = getLinkTo("environment");
const getLinkToOutcome = getLinkTo("outcome");

const getAccessDisplayName = _props =>
  _props.activity.payload.access.display_name;

const getOutcomeTitle = _props =>
  _props.activity.payload.outcome && _props.activity.payload.outcome.title;

const getRouteTitle = _props =>
  getRouteUrl(_props.activity.payload.route.route);

const getVariableName = _props => _props.activity.payload.variable.name;
const getDomainName = _props => _props.activity.payload.domain.name;
const getNewTitle = _props => _props.activity.payload.new_title;

const composedTitle = ({
  primaryText,
  secondaryText,
  getPrimaryTitle,
  getSecondaryTitle
  // eslint-disable-next-line
}) => _props => {
  const { display_name: name } = _props.activity.payload.user;

  const primaryTitle = getPrimaryTitle(_props) || null;
  const secondaryTitle = getSecondaryTitle ? getSecondaryTitle(_props) : null;

  const displayPrimaryText =
    typeof primaryText === "function" ? primaryText(_props) : primaryText;

  return (
    <ActivityTitle
      name={name}
      primaryTitle={primaryTitle}
      primaryText={displayPrimaryText}
      secondaryText={secondaryText}
      secondaryTitle={secondaryTitle}
    />
  );
};

const getBackupPrimaryText = _props => {
  return _props.activity.state !== "complete"
    ? "creating_backup"
    : _props.activity.result !== "failure"
      ? "created_backup"
      : "attempt_create_backup";
};

const getSyncPrimaryText = _props => {
  const { state, result } = _props.activity;

  if (state === "complete" && result !== "failure") {
    return "synced";
  } else if (state !== "complete" || result === "failure") {
    return "initiated_sync";
  }

  return "";
};

const getSyncPrimaryTitle = _props => {
  const projectURL = getProjectURL(_props);
  const {
    outcome,
    synchronize_code,
    synchronize_data
  } = _props.activity.payload;
  const linkURL = `${projectURL}/${outcome?.id}`;
  const linkText = `${synchronize_code ? "code" : ""} ${
    synchronize_code && synchronize_data ? "&" : ""
  } ${synchronize_data ? "data" : ""}`;

  return (
    <span>
      <Link to={linkURL}>
        <strong>{outcome?.title}</strong>
        {"'s"}
      </Link>{" "}
      {linkText}
    </span>
  );
};

const getBackupName = _props => _props.activity.payload.backup_name || "";
const getEnvironmentId = _props => _props.activity.payload.environment.id || "";
const getProfile = _props => _props.activity.payload.profile || "";

const activityTemplates = {
  "environment.access.add": {
    icon: <UserIcon />,
    title: composedTitle({
      primaryText: "added",
      secondaryText: "to",
      getPrimaryTitle: getAccessDisplayName,
      getSecondaryTitle: getLinkToEnvironment
    })
  },
  "environment.access.remove": {
    icon: <UserIcon />,
    title: composedTitle({
      primaryText: "removed",
      secondaryText: "from",
      getPrimaryTitle: getAccessDisplayName,
      getSecondaryTitle: getLinkToEnvironment
    })
  },
  "environment.backup": {
    icon: <BackupIcon />,
    title: composedTitle({
      primaryText: getBackupPrimaryText,
      secondaryText: "of",
      getPrimaryTitle: getBackupName,
      getSecondaryTitle: getLinkToEnvironment
    })
  },
  "environment.backup.delete": {
    icon: <BackupIcon />,
    title: composedTitle({
      primaryText: "deleted_backup",
      secondaryText: "of",
      getPrimaryTitle: getBackupName,
      getSecondaryTitle: getLinkToEnvironment
    })
  },
  "environment.branch": {
    icon: <BranchIcon />,
    title: composedTitle({
      primaryText: "branched_environment",
      secondaryText: "from",
      getPrimaryTitle: getLinkToOutcome,
      getSecondaryTitle: getLinkToParentEnv
    })
  },
  "environment.cron": {
    icon: <CronIcon />,
    title: descriptionTitle
  },
  "environment.deactivate": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deactivated_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.delete": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deleted_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.initialize": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "initialized_environment",
      getPrimaryTitle: getOutcomeTitle,
      secondaryText: "with_profile",
      getSecondaryTitle: getProfile
    })
  },
  "environment.merge": {
    icon: <MergeIcon />,
    title: composedTitle({
      primaryText: "merged",
      getPrimaryTitle: getLinkToOutcome,
      secondaryText: "into",
      getSecondaryTitle: getLinkToEnvironment
    })
  },
  "environment.push": {
    icon: <PushIcon />,
    title: composedTitle({
      primaryText: "pushed_to",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.redeploy": {
    icon: <RedeployIcon />,
    title: composedTitle({
      primaryText: "redeployed_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.restore": {
    icon: <BackupIcon />,
    title: composedTitle({
      primaryText: "restored",
      getPrimaryTitle: getLinkToEnvironment,
      secondaryText: "to",
      getSecondaryTitle: getBackupName
    })
  },
  "environment.route.create": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "added_route",
      getPrimaryTitle: getRouteTitle
    })
  },
  "environment.route.delete": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deleted_route",
      getPrimaryTitle: getRouteTitle
    })
  },
  "environment.route.update": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "modified_route",
      getPrimaryTitle: getRouteTitle
    })
  },
  "environment.subscription.update": {
    icon: <SystemIcon />,
    title: descriptionTitle
  },
  "environment.update.http_access": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated_HTTP_Access_settings_on_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.update.restrict_robots": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated_the_robots.txt_settings_on_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.update.smtp": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated_SMTP_settings_on_environment",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "environment.variable.create": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "added_environment_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "environment.variable.delete": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deleted_environment_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "environment.variable.update": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated_environment_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "environment.synchronize": {
    icon: <SyncIcon />,
    title: composedTitle({
      primaryText: getSyncPrimaryText,
      getPrimaryTitle: getSyncPrimaryTitle,
      secondaryText: "with",
      getSecondaryTitle: getEnvironmentId
    })
  },
  "environment.certificate.renewal": {
    icon: <ShieldIcon />,
    title: composedTitle({
      primaryText: "renewal_environment_certificate",
      getPrimaryTitle: getLinkToEnvironment
    })
  },
  "integration.bitbucket.fetch": {
    icon: <Bitbucket />,
    title: descriptionTitle
  },
  "integration.bitbucket.register_hooks": {
    icon: <Bitbucket />,
    title: descriptionTitle
  },
  "integration.bitbucket_server.fetch": {
    icon: <Bitbucket />,
    title: descriptionTitle
  },
  "integration.bitbucket_server.register_hooks": {
    icon: <Bitbucket />,
    title: descriptionTitle
  },
  "integration.github.fetch": {
    icon: <GitHub />,
    title: descriptionTitle
  },
  "integration.gitlab.fetch": {
    icon: <Gitlab />,
    title: descriptionTitle
  },
  "integration.health.email": {
    icon: <Email />,
    title: descriptionTitle
  },
  "integration.health.pagerduty": {
    icon: <Pagerduty />,
    title: descriptionTitle
  },
  "integration.health.slack": {
    icon: <Slack />,
    title: descriptionTitle
  },
  "integration.script": {
    icon: <Script />,
    title: descriptionTitle
  },
  "integration.webhook": {
    icon: <Webhook />,
    title: descriptionTitle
  },
  "project.create": {
    icon: <PushIcon />,
    title: composedTitle({
      primaryText: "created_a_new_project",
      getPrimaryTitle: getLinkToNewProject
    })
  },
  "project.domain.create": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "added_domain",
      getPrimaryTitle: getDomainName
    })
  },
  "project.domain.delete": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deleted_domain",
      getPrimaryTitle: getDomainName
    })
  },
  "project.domain.update": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated",
      getPrimaryTitle: getDomainName
    })
  },
  "project.modify.title": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "changed_project_name_to",
      getPrimaryTitle: getNewTitle
    })
  },
  "project.variable.create": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "added_project_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "project.variable.delete": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "deleted_project_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "project.variable.update": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "updated_project_variable",
      getPrimaryTitle: getVariableName
    })
  },
  "cdn.fastly.origin_restriction_setup": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "setup_fastly_origin_restriction",
      getPrimaryTitle: () => false
    })
  },
  "cdn.fastly.origin_restriction_disable": {
    icon: <SystemIcon />,
    title: composedTitle({
      primaryText: "disable_fastly_origin_restriction",
      getPrimaryTitle: () => false
    })
  },
  unknown: {
    icon: <SystemIcon />,
    title: descriptionTitle
  }
};

export default activityTemplates;
