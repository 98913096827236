import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import momentTZ from "moment-timezone";

import AccessibleTooltip from "Components/AccessibleTooltip";

const ActivityDate = ({ date, activityId }) => {
  const momentDate = momentjs(date);

  return (
    <AccessibleTooltip
      tooltipProps={{
        id: `activity-date-${activityId}`,
        type: "dark",
        children: `UTC${momentTZ(momentDate).format("Z")}`
      }}
    >
      {momentDate.format("H:mm, MMM D, YYYY")}
    </AccessibleTooltip>
  );
};

ActivityDate.propTypes = {
  date: PropTypes.string,
  activityId: PropTypes.string
};

export default ActivityDate;
