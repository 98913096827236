import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";
import Button from "UI/Button";
import PlusIcon from "UI/icons/Plus";

const ButtonAdd = ({ customText, ...props }) => {
  const intl = useIntl();
  const text = customText || intl.formatMessage({ id: "add" });

  return (
    <Button content="mixed" aria-label={text} {...props}>
      <PlusIcon />
      {customText || capitalize(text)}
    </Button>
  );
};

ButtonAdd.propTypes = {
  customText: PropTypes.string
};

export default ButtonAdd;
