import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const LabeledInfo = ({
  gridColumn = "auto / span 3",
  label,
  value,
  infoDialog = false,
  infoDialogTitle,
  infoDialogText,
  infoDialogLinkText,
  infoDialogLinkTo
}) => (
  <S.Layout gridColumn={gridColumn}>
    <S.Value>{value}</S.Value>
    <S.Label>
      {label}
      {infoDialog && (
        <S.LabelDialog
          align="center"
          title={infoDialogTitle}
          text={infoDialogText}
          linkText={infoDialogLinkText}
          to={infoDialogLinkTo}
        />
      )}
    </S.Label>
  </S.Layout>
);

LabeledInfo.propTypes = {
  gridColumn: PropTypes.string,
  label: PropTypes.string,
  infoDialog: PropTypes.bool,
  infoDialogTitle: PropTypes.string,
  infoDialogText: PropTypes.string,
  infoDialogLinkText: PropTypes.string,
  infoDialogLinkTo: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default LabeledInfo;
